import firebase from "firebase/app";
import "firebase/analytics";
import firebaseConfig from "../config/firebase";

export const initFirebase = () => {
	if (firebase.apps.length === 0) {
		firebase.initializeApp(firebaseConfig);
		firebase.analytics();
		firebase.analytics().logEvent("user init");
		console.log("initFirebase", firebase.apps.length);
	}
};

class Analytics {
	static onSignIn = async (userObject) => {
		const { id, email, role } = userObject ?? {};
		await Promise.all([
			firebase.analytics().setUserId(id),
			firebase.analytics().setUserProperties({ id, email, role }),
			this.logEvent("log_in"),
		]);
	};
	static onLogout = async (userObject) => {
		const { id, email, role } = userObject ?? {};
		await Promise.all([
			firebase.analytics().setUserId(userObject.id),
			firebase.analytics().setUserProperties({ id, email, role }),
			this.logEvent("log_out"),
		]);
	};

	static onSignUp = async (userObject) => {
		const { id, email, role } = userObject ?? {};
		await Promise.all([
			firebase.analytics().setUserId(userObject.id),
			firebase.analytics().setUserProperties({ id, email, role }),
			this.logEvent("sign_up"),
		]);
	};

	static setCurrentScreen = async (screenName) => {
		if (firebase.apps.length > 0)
			await firebase.analytics()?.setCurrentScreen(screenName);
	};

	static logEvent = async (eventName, propertyObject = {}) => {
		if (firebase.apps.length > 0)
			await firebase.analytics().logEvent(eventName, propertyObject);
	};
}

export default Analytics;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { Link } from "@material-ui/core";
import { readDatas } from "../../service/Content";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Analytics from "../../service/Analytics";

function CustomArrowPrev(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick} style={{ fontSize: 0 }}>
			<ArrowBackIos color="primary" fontSize="large" />
		</div>
	);
}
function CustomArrowNext(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<ArrowForwardIos color="primary" fontSize="large" />
		</div>
	);
}
export default function GameSlider({ game_id, dots }) {
	const settings = {
		prevArrow: <CustomArrowPrev />,
		nextArrow: <CustomArrowNext />,
		className: "center",
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToShow: 1,
		swipeToSlide: true,
		adaptiveHeight: true,
		arrows: true,
		centerMode: true,
		centerPadding: "0rem",
		dots: true,
		easing: "ease",
		vertical: dots === "slick-dots-vertical",
		dotsClass: dots,
		// pauseOnDotsHover: true,
	};
	const [banners, setBanners] = useState();
	const history = useHistory();
	const linkBanner = (banner) => {
		Analytics.logEvent("bannerClick", {
			banner: banner.title,
			game: banner.game_id,
		});
		if (banner.is_outlink) {
			let tempUrl = banner.link_url;
			if (tempUrl.substring(0, 4) !== "http") {
				tempUrl = "http://" + tempUrl;
			}
			window.open(tempUrl);
		} else {
			history.push(banner.link_url);
		}
	};
	useEffect(() => {
		const localBanners = localStorage.getItem("banners");
		if (localBanners)
			setBanners(
				JSON.parse(localBanners).filter(
					(banner) => banner.game_id.split("/")[0] === game_id
				)
			);
		let condition = {
			where: {
				is_active: true,
				startDate: { $lte: moment().format("YYYY-MM-DD") },
				endDate: { $gte: moment().subtract("1", "d").format("YYYY-MM-DD") },
			},
		};
		readDatas("banner", condition).then((bannerRes) => {
			let bannersData = bannerRes.data.data;
			setBanners(
				bannersData.filter((banner) => banner.game_id.split("/")[0] === game_id)
			);
			localStorage.setItem("banners", JSON.stringify(bannersData));
		});
		// return () => {};
	}, [game_id]);
	return (
		<>
			{banners?.length > 0 && (
				<div className="">
					<Slider {...settings}>
						{banners.map((banner, idx) => {
							if (banner.mobile_img) {
								return (
									<div key={idx}>
										<Link
											onClick={() => linkBanner(banner)}
											className="flex justify-center bg-purple-900"
										>
											<img
												className="w-full z-10 max-h-90"
												src={banner.mobile_img}
												alt={banner.title}
											/>
										</Link>
									</div>
								);
							} else {
								return <></>;
								// return <div key={idx}></div>;
							}
						})}
					</Slider>
				</div>
			)}
		</>
	);
}

import { Avatar, withStyles } from "@material-ui/core";
import React from "react";
import { Button } from "@material-ui/core";
import { useLoginContext } from "../../contexts/LoginContext";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../service/Auth";
import { motion } from "framer-motion";
import Analytics from "../../service/Analytics";

const WButton = withStyles((theme) => ({
	root: {
		backgroundColor: "#fff",
	},
}))(Button);
const UserInfo = () => {
	const { user, removeUser } = useLoginContext();
	const history = useHistory();
	const onLogout = () => {
		Analytics.onLogout(user);
		removeUser();
		logout();
	};
	return (
		<div className=" py-4">
			<motion.div
				initial={{ scale: 0.2, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 20,
					delay: 0.1,
				}}
				className="flex items-center space-x-2 mb-2"
			>
				<Avatar src={user.avatar} />
				<span className="">
					<b className="text-purple-600">{user.nickname}</b>님 안녕하세요!
				</span>
			</motion.div>
			<motion.div
				initial={{ scale: 0.2, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 20,
					delay: 0.1,
				}}
				className="w-full flex justify-end text-xs mb-1"
			>
				<Link to={{ pathname: "/member", state: { tab: 3 } }}>포인트 내역</Link>
			</motion.div>
			<motion.div
				initial={{ scale: 0.2, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 20,
					delay: 0.2,
				}}
				className="w-full flex justify-between py-2 px-3 mb-2 border border-gray-200 text-sm font-bold bg-white"
			>
				<span>
					<b className="text-purple-600">cyou</b> 캐시 포인트
				</span>
				<span className="text-purple-600">
					{new Intl.NumberFormat("ko-KR").format(user.point_cash ?? 0)}
				</span>
			</motion.div>
			<motion.div
				initial={{ scale: 0.2, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 20,
					delay: 0.4,
				}}
				className="w-full flex justify-between py-2 px-3 mb-3 border border-gray-200 text-sm font-bold bg-white"
			>
				<span>
					<b className="text-purple-600">cyou</b> 무료 포인트
				</span>
				<span className="text-purple-600">
					{new Intl.NumberFormat("ko-KR").format(user.point_free ?? 0)}
				</span>
			</motion.div>
			<div className="flex justify-between">
				<motion.div
					initial={{ scale: 0.2, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 20,
						delay: 0.3,
					}}
					className="w-full mr-1"
				>
					<WButton
						onClick={() => history.push("/member")}
						variant="contained"
						fullWidth
					>
						회원정보
					</WButton>
				</motion.div>
				<motion.div
					initial={{ scale: 0.2, opacity: 0 }}
					animate={{ scale: 1, opacity: 1 }}
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 20,
						delay: 0.5,
					}}
					className="w-full ml-1"
				>
					<WButton onClick={onLogout} variant="contained" fullWidth>
						로그아웃
					</WButton>
				</motion.div>
			</div>
		</div>
	);
};

export default UserInfo;

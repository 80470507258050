import { Divider } from "@material-ui/core";
import React from "react";

const LoginDivider = ({ isRegister }) => {
	return (
		<div className="flex items-center  justify-between">
			<Divider className="w-full" style={{ flexShrink: 1 }} />
			<span className="flex-shrink-0 text-xs text-gray-400 px-2">
				{isRegister ? "간편 가입" : "간편 로그인"}
			</span>
			<Divider className="w-full" style={{ flexShrink: 1 }} />
		</div>
	);
};

export default LoginDivider;

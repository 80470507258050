import { Button } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { cdnImg } from "../../../config/etc";
import { BtScroll } from "../FDMain";
import { positionData } from "./CharacterPosition";

const bt_close = cdnImg + "bt_close.png";
const btConfirm = cdnImg + "팝업-확인.png";

export default function CharacterPositionDetail({
	selectedChar,
	onSelect,
	setActivePage,
	setSelectedChar,
}) {
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	const isBig2 = useMediaQuery({ query: "(min-height:900px)" });
	return (
		<div
			style={{
				backgroundColor: "#000",
				backgroundImage: `url(${
					isMobile
						? positionData[selectedChar < 0 ? 0 : selectedChar].charBgMo
						: positionData[selectedChar < 0 ? 0 : selectedChar].charBg
				})`,
				borderColor: "#f4aa2f",
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundAttachment: "fixed",
				height: !isBig2 ? "100%" : window.innerHeight,
			}}
		>
			<div
				initial={{ scale: 0.8, opacity: 0 }}
				animate={{ scale: 1, opacity: 1 }}
				transition={{ duration: 0.7 }}
				className="relative h-full m-auto flex flex-col items-center justify-center text-white pt-8 sm:pt-0 overflow-hidden "
			>
				<div
					className={
						isMobile
							? "absolute top-16 right-0"
							: "absolute top-16 right-0 z-30"
					}
				>
					<Button
						onClick={() => {
							setSelectedChar(-1);
							window.scrollTo({ top: 0, behavior: "smooth" });
						}}
						className=""
					>
						{isMobile ? (
							<div
								className="flex items-center"
								style={{
									width: isMobile ? 36 : 60,
									height: isMobile ? 36 : 60,
									backgroundColor: isMobile ? "#000" : "yellow",
								}}
							>
								<img
									src={bt_close}
									alt="close"
									width={isMobile ? 36 : 60}
									className="-mr-4 "
									// style={{ filter: "invert(100%)" }}
								/>
								{/* <p>뒤로 가기</p> */}
							</div>
						) : (
							<img src={btConfirm} alt="확인" width={140} />
						)}
					</Button>
				</div>
				{selectedChar > -1 ? (
					<motion.img
						initial={{ scale: 0.8, opacity: 0 }}
						animate={{ scale: 1, opacity: 1 }}
						transition={{ duration: 0.7 }}
						src={
							isMobile
								? positionData[selectedChar].charMo
								: positionData[selectedChar].char
						}
						alt="캐릭터"
						style={{
							minWidth: isMobile ? 0 : 1400,
							maxHeight: isMobile ? "100%" : window.innerHeight,
						}}
					/>
				) : (
					<div style={{ height: window.innerHeight }}></div>
				)}
				<div
					// className="mt-8"
					className="flex flex-wrap justify-center -mt-52 sm:-mt-60"
				>
					{positionData.map((position, idx) => {
						return (
							<Button
								key={idx}
								onClick={() => onSelect(idx)}
								style={{
									padding: 0,
									margin: isMobile ? "3px -3px" : "0 -4px",
								}}
							>
								<img
									src={
										idx === selectedChar ? position.btChar : position.btCharDis
									}
									alt={position.name}
									width={isMobile ? 114 : 200}
								/>
							</Button>
						);
					})}
				</div>
				<div className="pb-16">
					<BtScroll setActivePage={setActivePage} />
				</div>
			</div>
		</div>
	);
}

import React from "react";
import { Form } from "react-final-form";
import createDecorator from "final-form-focus";
import {
	composeValidators,
	emailAvailable,
	emailValidator,
	nickAvailable,
	passwordCheckValidator,
	passwordValidator,
	requiredValidator,
	specialCharValidator,
} from "../../utils/validator";
import { Button } from "@material-ui/core";
import InputAdapter from "../components/InputAdapter";
import { Link, useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
// import { useLoginContext } from "../../contexts/LoginContext";
import { sendAuthEmail } from "../../service/Auth";
import { aniContainer, aniItem } from "../../config/animation";
import { useSnackbar } from "notistack";

const focusOnError = createDecorator();

export default function RegisterBox() {
	// const { setUser } = useLoginContext();
	const location = useLocation();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = async (values) => {
		values.email = values.email.toLowerCase();
		//보내기 전에 약관 동의 받자!
		//인증 메일 전송

		return sendAuthEmail({ email: values.email })
			.then((res) => {
				history.replace("/emailAuth", values);
			})
			.catch((err) => {
				enqueueSnackbar(
					"인증 메일 발송에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
					{ variant: "error" }
				);
			});
	};
	return (
		<div className="relative py-4 ">
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors, values }) => (
					<motion.form
						variants={aniContainer}
						initial="hidden"
						animate="visible"
						onSubmit={handleSubmit}
						className="flex flex-col space-y-2"
					>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(
									requiredValidator,
									specialCharValidator,
									nickAvailable
								)}
								spinner="true"
								name="nickname"
								type="text"
								title="닉네임"
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(
									requiredValidator,
									emailValidator,
									emailAvailable
								)}
								spinner="true"
								name="email"
								type="email"
								title="이메일"
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(
									requiredValidator,
									passwordValidator
								)}
								name="password"
								type="password"
								title="비밀번호"
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(
									requiredValidator,
									passwordValidator,
									passwordCheckValidator
								)}
								name="confirm"
								type="password"
								title="비밀번호 확인"
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={
									submitting ||
									Object.keys(errors).length !== 0 ||
									pristine ||
									errors.length > 0
								}
								fullWidth={true}
							>
								이메일로 회원가입
							</Button>
						</motion.div>
						<motion.div className="flex justify-end items-center text-xs">
							<Link
								className="ml-1.5"
								to={{
									pathname: "/login",
									state: { from: location.pathname },
								}}
							>
								로그인
							</Link>
						</motion.div>
					</motion.form>
				)}
			/>
		</div>
	);
}

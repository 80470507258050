import React from "react";

const PrivacyPolicy = ({ isComponent }) => {
	return (
		<div id="container" className="utility page p-4">
			<section id="section01" className="sec01">
				<div className={"utilityWrap max-w-2xl"}>
					<div className={isComponent && "text-xs"}>
						{!isComponent && <h2 className="title mt-4">개인정보 처리방침</h2>}
						<h3 style={isComponent && { paddingTop: 0 }}>개인정보취급방침</h3>
						<span>
							㈜창유닷컴코리아(이하 “회사”라 참)는 「정보통신망 이용촉진 및
							정보보호 등에 관한 법률」 및 「개인정보보호법」
							「통신비밀보호법」, 「전기통신사업법」 등 정보통신서비스제공자가
							준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련
							법령에 의거한 개인정보취급방침을 정하여 “회원” 권익 보호에 최선을
							다하고 있습니다.
						</span>
						<div className="utilityList">
							<h4>제 1조 수집하는 개인정보 항목 및 수집방법</h4>
							<ul>
								<li>
									1) 수집하는 개인정보의 항목
									<br />
									“회사”는 서비스가입 시 회원가입, 원활한 고객상담, 각종
									서비스의 제공을 위하여 최초 서비스가입 시 다음과 같은
									개인정보를 수집하고 있습니다. 수집되는 정보들은 서비스 이용 및
									이벤트/마케팅에 참여하는 과정에서 자동으로 또는 “회원”이
									정보를 직접 입력함으로써 수집됩니다.
									<ul>
										<li>
											① 회원가입 시 또는 게임 서비스 최초 이용 시<br />
											닉네임, 단말기 정보 (모델명, OS버전, 기기고유식별번호 등),
											통신사 정보, 스토어정보, 게임버전, 이메일, 게임 및 서비스
											이용기록, 접속기록, 쿠키, 결제기록, 유료 과금정보,
											프로모션/이벤트 참여기록 및 상품 발송 관련 정보
										</li>
										<li>
											② 카카오계정 연동 시<br />
											카카오 회원번호, 카카오 친구 목록, 카카오 닉네임, 카카오
											프로필 사진
										</li>
										<li>
											③ 서비스 이용 과정에서 아래와 같은 정보들이 생성되어
											수집될 수 있습니다.
											<br />
											“회원”의 휴대폰 단말기 정보(모델명, OS 버전, 펌웨어 버전,
											기기 고유번호 등), IP Address, 쿠키, 접속 로그, 서비스
											이용 기록, 불량 이용 기록 등
										</li>
										<li>
											④ 이벤트 프로모션에 참여하거나 선택형 서비스를 이용하는
											경우 별도 동의 하에 아래의 정보를 수집할 수 있습니다.
											<br />
											이름, 휴대폰 번호, 주소, 이메일 주소, 생년월일, 성별, 거주
											지역 등
										</li>
										<li>
											⑤ 무료 / 유료 서비스 이용 과정에서 복구나 환불 등을 위해
											불가피하게 필요한 경우 이메일 주소, 구매 내역 확인 내용,
											타인 결제 사실 확인을 위한 실명 및 가족관계 증빙 서류
										</li>
									</ul>
								</li>
								<li>
									2) 개인정보 수집 방법
									<ul>
										<li>
											① “회사”의 최초 서비스 가입 시 동의 절차 제공을 통하여
											수집
										</li>
										<li>
											② “회사”와 서비스 제공관련 제휴관계에 있는 플랫폼을 통해
											자동으로 수집
										</li>
										<li>
											③ 이벤트 진행을 위하여 “회원”의 별도 동의 절차를 통해 수집
										</li>
										<li>
											④ 1:1 상담 및 전화 상담을 통해 “회원”의 자발적 제공 혹은
											필요에 의해 동의 하에 수집
										</li>
									</ul>
								</li>
								<li>
									3) “회원”은 위 개인정보의 수집 및 이용을 거부할 수 있으나 이
									경우 서비스 일부 또는 전체의 이용이 어려울 수 있습니다.
								</li>
							</ul>
							<h4>제 2조 개인정보의 수집 및 이용목적</h4>
							<span>
								“회사”는 수집한 개인정보를 다음의 목적을 위해 활용하며, “회원”의
								사전 동의 없이 수집한 개인정보를 함부로 공개하지 않습니다. 이용
								목적 변경 시, 개인정보보호법 제 18조에 따라 공지 후 별도의
								동의를 받는 등의 필요 절차를 이행합니다.
							</span>
							<ul>
								<li>
									1) 고지 사항 전달, 불만 처리 등을 위한 원활한 의사 소통 경로의
									확보
								</li>
								<li>
									2) 유료정보 이용에 대한 문의 처리 및 계약 이행 분쟁 처리, 결제
									환불 등 고객 서비스 제공
								</li>
								<li>3) 게임 내 플레이 및 커뮤니티 서비스 지원</li>
								<li>
									4) 마케팅 및 광고 활용 목적
									<ul>
										<li>① 신규 서비스 이벤트 정보 안내</li>
										<li>② 신작 출시 및 업데이트 등의 게임 서비스 소식</li>
										<li>
											③ 회사의 자체 채널을 이용한 각종 프로모션 및 이벤트 정보
											등의 안내
										</li>
										<li>④ 서비스 품질 향상 및 통계 정보 처리</li>
										<li>⑤ 경품 등의 배송에 대한 정확한 배송지 확보</li>
									</ul>
								</li>
								<li>
									5) 기타 컨텐츠 제공 및 인증 서비스(아이디, 비밀번호 찾기 등)
								</li>
								<li>6) 법정대리인의 동의 및 본인확인</li>
							</ul>
							<h4>제 3조 개인정보의 보유 및 이용기간</h4>
							<span>
								“회사”는 “회원”의 개인정보를 개인정보의 수집 및 이용목적이
								달성되면 지체 없이 파기합니다.
								<br />
								다만, 수집 목적 또는 제공 받은 목적이 달성된 경우에도 「상법」,
								「전자상거래 등에서의 소비자보호에 관한 법률」 등 관계 법령의
								규정에 의하여 보존할 필요성이 있는 경우 “회사”는 관계 법령에서
								정한 일정한 기간 동안 회원정보를 보관하며, 그 외 다른 목적으로는
								절대 사용하지 않습니다.
							</span>
							<ul>
								<li>
									1) “회사” 내부 방침에 의한 정보보유 사유
									<ul>
										<li>
											① 부정이용기록 (보존 이유: 부정이용 방지, 보존 기간: 1년)
										</li>
										<li>
											② 탈퇴 회원에 대한 개인 정보 (보존 이유: 약관 및 내부
											정책에 따라 부정 이용의 방지, 보존 기간: 3개월)
										</li>
										<li>
											③ 고객 상담 신청 기록, 고객 상담 내용 및 서면 양식(보존
											이유: 이용자 분쟁 관련 상담 진행, 보존 기간: 3개월)
										</li>
									</ul>
								</li>
								<li>
									2) 관련법령에 의한 정보보유 사유
									<br />
									상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
									규정에 의하여 보존할 필요가 있는 경우 “회사”는 관계법령에서
									정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 “회사”는
									보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
									아래와 같습니다.
									<ul>
										<li>
											① 계약 또는 청약철회 등에 관한 기록 (보존 이유: 전자상거래
											등에서의 소비자보호에 관한 법률, 보존 기간: 5년)
										</li>
										<li>
											② 대금결제 및 재화 등의 공급에 관한 기록 (보존 이유:
											전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간:
											5년)
										</li>
										<li>
											③ 소비자의 불만 또는 분쟁처리에 관한 기록 (보존 이유:
											전자상거래 등에서의 소비자보호에 관한 법률, 보존 기간:
											3년)
										</li>
										<li>
											④ 본인확인에 관한 기록 (보존 이유: 정보통신망이용촉진 및
											정보보호 등에 관한 법률, 보존 기간: 6개월)
										</li>
										<li>
											⑤ 로그인 등 접속 및 방문에 관한 기록 (보존 이유:
											통신비밀보호법, 보존 기간: 3개월)
										</li>
										<li>
											⑥ 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류 (보존
											이유: 국세기본법에 관한 법률, 보존 기간: 5년)
										</li>
									</ul>
								</li>
							</ul>
							<h4>제 4조 개인정보 파기절차 및 방법</h4>
							<span>
								“회원”의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이
								달성되면 지체 없이 파기합니다. “회사”의 개인정보 파기절차 및
								방법은 다음과 같습니다.
							</span>
							<ul>
								<li>
									1) 파기절차
									<ul>
										<li>
											① “회원”이 서비스 가입 등을 위해 입력한 정보는 목적이
											달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에
											의한 정보보호 사유에 따라(제 3조 개인정보의 보유 및
											이용기간 참조)일정 기간 저장된 후 파기됩니다.
										</li>
										<li>
											② 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의
											다른 목적으로 이용되지 않습니다.
										</li>
									</ul>
								</li>
								<li>
									2) 파기방법
									<ul>
										<li>
											① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
											통하여 파기합니다.
										</li>
										<li>
											② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수
											없는 기술적 방법을 사용하여 삭제합니다.
										</li>
									</ul>
								</li>
							</ul>
							<h4>제 5조 개인정보의 공유 및 제공</h4>
							<ul>
								<li>
									1) “회사”는 “회원”들의 개인정보를 "제 3조 개인정보의 수집 및
									이용목적"에서 고지한 범위 내에서 사용하며, “회원”의 사전 동의
									없이는 해당 범위를 초과하여 이용하지 않으며, 원칙적으로
									“회원”의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의
									경우에는 규정한 범위를 넘어 개인정보를 이용하거나 제 3자에게
									제공할 수 있습니다.
									<ul>
										<li>① 이용자가 사전에 동의한 경우</li>
										<li>② 서비스 제공에 따른 요금 정산을 위해 필요한 경우</li>
										<li>
											③ 전기통신기본법, 전기통신사업법 등 관계 법령의 규정에
											의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
											수사기관의 요구가 있는 경우
										</li>
										<li>
											④ 통계 작성, 학술 연구 또는 시장 조사를 위하여 필요한
											경우로서 특정 개인을 식별할 수 없는 형태로 가공하여
											제공하는 경우
										</li>
										<li>
											⑤ 금융실명거래 및 비밀보장에 관률, 신용정보의 이용 및
											보호에 관한 법률, 전기통신기본법, 전기통신사업법,
											지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법률에
											특별한 규정이 있는 경우
										</li>
									</ul>
								</li>
							</ul>
							<h4>제 6조 개인정보의 취급 위탁</h4>
							<span>
								“회사”는 서비스 제공 및 이용자 편의 증진을 위하여 아래와 같이
								개인정보를 취급 위탁하고 있으며, 관계 법령에 따라 위탁계약 시
								개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고
								있습니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은
								아래와 같습니다.
							</span>
							<ul>
								<li>
									1) ㈜와이디온라인
									<ul>
										<li>
											- 위탁 목적: 게임서비스운영, 고객상담 지원, 고객상담 녹취
											시스템 운영위탁
										</li>
										<li>- 이용기간: 회원 탈퇴 또는 위탁 계약 종료 시까지</li>
									</ul>
								</li>
							</ul>
							<h4>제 7조 “회원” 및 법정대리인의 권리와 그 행사방법</h4>
							<span>
								“회사”는 “회원”의 개인정보의 열람, 제공, 정정 요청에 대해
								적극적으로 필요한 조치를 하며, 만 14세 미만 아동의 법정대리인은
								아동 개인정보의 열람, 제공, 정정을 요청할 수 있는 권리를
								가집니다.
							</span>
							<ul>
								<li>
									1) 본 행사 방법은 “회사” 혹은 서비스 제공 관련 제휴 관계에
									있는 플랫폼을 통해 요청을 받거나 회사 홈페이지를 통해 직접
									요청하는 경우 처리할 수 있습니다.
								</li>
								<li>
									2) “회원” 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은
									당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수
									있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를
									거부하거나 회원탈퇴를 요청할 수 있습니다.
								</li>
								<li>
									3) “회원” 혹은 만 14세 미만 아동의 개인정보 조회, 수정을
									위해서는 '개인정보 변경'을 선택하고, 가입 해지를 위해서는 회원
									탈퇴 신청을 선택 후 본인 확인 절차를 거치면 탈퇴가 가능합니다.
									혹은 개인정보보호 책임자에게 서면, 전화 또는 이메일로 연락하면
									지체 없이 조치합니다.
								</li>
								<li>
									4) “회원”이 개인정보 오류에 대한 정정을 요청한 경우에는 정정을
									완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
								</li>
								<li>
									5) 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리
									결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
									합니다.
								</li>
								<li>
									6) “회사”는 “회원” 혹은 법정 대리인의 요청에 의해 해지 또는
									삭제 된 개인정보는 “제 3조 개인정보 보유 및 이용기간”에 명시
									된 바에 따라 처리하며, 그 외의 용도로 열람 또는 이용할 수
									없도록 처리합니다.
								</li>
							</ul>
							<h4>
								제 8조 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
							</h4>
							<ul>
								<li>
									1) “회사”는 이용자에게 맞춤화된 서비스를 제공하기 위해
									이용자의 정보를 저장하고 불러오는 '쿠키(Cookie)'를 사용합니다.
									쿠키는 서비스를 운영하는데 이용되는 서버가 이용자의 디바이스에
									보내는 아주 작은 텍스트 파일로 이용자의 디바이스에 저장됩니다.
									“회사”는 다음 목적에 의해 쿠키를 사용합니다.
									<ul>
										<li>
											① “회원”과 비회원의 접속 빈도나 방문 시간 등을 분석,
											고객님의 취향과 관심 분야, 자취 추적, 각종 이벤트 참여
											정도 및 방문 회수 등을 파악하여 개인 맞춤 서비스를 제공
										</li>
									</ul>
								</li>
								<li>
									2) “회원”은 쿠키 설치에 대한 선택권을 가지고 있습니다.
									디바이스의 설정에서 쿠키를 허용할 것인지 여부를 설정할 수
									있으며, 삭제 역시 할 수 있습니다. 단, 쿠키의 사용을 허용하지
									않는 경우 로그인이 필요한 서비스 이용에 어려움이 있을 수
									있습니다.
								</li>
							</ul>
							<h4>제 9조 개인정보의 기술적/관리적 보호 대책</h4>
							<span>
								“회사”는 “회원”들의 개인정보를 취급함에 있어 개인정보가 분실,
								도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
								다음과 같은 기술적/관리적 대책을 강구하고 있습니다.
							</span>
							<ul>
								<li>
									1) 기술적 대책
									<ul>
										<li>
											① “회사”는 “회원”의 개인정보 중 관련 법령에서 지정한
											항목을 암호화하여 보관하고 있으며, 개인정보의 확인 및
											변경은 본인의 요청을 통하여 본인인증 후에만 가능합니다.
										</li>
										<li>
											② 개인정보가 포함되어 있는 중요한 데이터는 그 파일 및 송신
											데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 보안
											기능을 사용하여 보호하고 있습니다.
										</li>
										<li>
											③ “회사”는 해킹이나 컴퓨터 바이러스 등에 의하여 이용자의
											개인정보가 유출되거나 훼손 되는 것을 방지하기 위하여 항상
											감시하고 있습니다. 또한 만약의 사태를 대비하여 개인정보를
											주기적으로 백업하고 있으며 백신 프로그램을 정기적으로
											관리하여 개인정보가 침해되는 것을 방지하고 있습니다.
										</li>
										<li>
											④ “회사”는 개인정보를 처리할 수 있도록 체계적으로 구성된
											데이터베이스 시스템에 필요한 조치를 다하고 있습니다.
										</li>
									</ul>
								</li>
								<li>
									2) 관리적 대책
									<ul>
										<li>
											① “회사”는 “회원”의 개인정보에 대한 접근 권한을 최소한의
											인원으로 제한하고 있으며, 그 최소 인원에 해당하는 자는
											다음과 같습니다.
											<br />
											- “회원”을 직접 상대로 하여 마케팅, 이벤트, 고객지원,
											배송업무를 수행하는 자 (위탁, 협력 업체의 직원 포함)
											<br />
											- 개인정보보호 책임자를 포함한 개인정보보호업무를 담당하는
											자<br />- 기타 업무상 개인정보 처리가 불가피한 자
										</li>
										<li>
											② “회사”는 개인정보취급자와 수탁사를 대상으로 개인정보보호
											의무 등에 관해 정기적인 교육을 실시하고 있습니다.
										</li>
										<li>
											③ “회사”는 개인정보보호 업무를 전담하는 부서에서
											개인정보처리지침을 수립하여 관리하고 있습니다. 또한 내부
											규정 준수 여부를 정기적으로 확인하여 문제가 발견될 경우,
											즉시 바로잡을 수 있도록 노력하고 있습니다.
										</li>
										<li>
											④ 개인정보 관련 취급자의 업무인수인계는 보안이 유지된
											상태에서 이뤄지고 있으며 입사 및 퇴사 후 개인정보 사고에
											대한 책임을 명확하게 하고 있습니다.
										</li>
										<li>
											⑤ “회사”는 “회원” 개인의 실수 혹은 인터넷의 본질적인
											위험성으로 인하여 야기되는 개인정보유출에 대해 일체 책임을
											지지 않습니다.
										</li>
										<li>
											⑥ “회원”은 본인의 개인정보를 보호하기 위해서 자신의 플랫폼
											계정과 비밀번호, 이메일 등을 관리하고 그에 대한 책임을
											져야 합니다.
										</li>
									</ul>
								</li>
								<li>
									3) 물리적 대책
									<ul>
										<li>
											① “회사”는 개인정보를 보관하고 있는 개인정보시스템의
											물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를
											수립, 운영하고 있습니다.
										</li>
										<li>
											② 개인정보가 포함되어 있는 서류, 보조저장매체 등은 잠금
											장치가 있는 안전한 장소에 보관하고 있습니다.
										</li>
									</ul>
								</li>
							</ul>
							<h4>제 10조 개인정보에 관한 상담 및 문의</h4>
							<ul>
								<li>
									1) “회사”는 정기적인 교육과 더불어 개인정보 침해 방지를 위한
									내부 지침 및 시스템 개선의 노력을 지속하고 있으며, 개인정보를
									보호/관리하고 개인정보와 관련한 불만을 처리하기 위해 아래와
									같이 개인정보관리책임자와 담당자를 지정하고 있습니다.
									<ul>
										<li>
											① 개인정보 관리 책임자
											<br />
											- 이름: 정문법
											<br />
											- 소속: 게임사업실
											<br />- 이메일:{" "}
											<a href="mailto:jungmoonbeop@cyou-inc.com">
												jungmoonbeop@cyou-inc.com
											</a>
											<br />- 전화번호: 1566-9267
										</li>
										<li>
											② 개인정보 관리 담당자
											<br />
											- 이름: 고지원
											<br />
											- 소속: 게임사업실
											<br />- 이메일:{" "}
											<a href="mailto:kojiwon@cyou-inc.com">
												kojiwon@cyou-inc.com
											</a>
											<br />- 전화번호: 1566-9267
										</li>
									</ul>
								</li>
								<li>
									2) 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
									아래 기관에 문의하시기 바랍니다.
									<ul>
										<li>
											① 개인정보침해신고센터 (
											<a
												href="http://privacy.kisa.or.kr"
												target="_blank"
												rel="noreferrer"
											>
												http://privacy.kisa.or.kr
											</a>{" "}
											/ 국번없이 118)
										</li>
										<li>
											② 대검찰청 사이버범죄수사단 (
											<a
												href="http://www.spo.go.kr"
												target="_blank"
												rel="noreferrer"
											>
												http://www.spo.go.kr
											</a>{" "}
											/ 02-3480-3571)
										</li>
										<li>
											③ 경찰청 사이버테러대응센터 (
											<a
												href="http://www.ctrc.go.kr"
												target="_blank"
												rel="noreferrer"
											>
												http://www.ctrc.go.kr
											</a>{" "}
											/ 국번없이 182)
										</li>
										<li>
											④ 개인정보분쟁조정위원회 (
											<a
												href="http://kopico.go.kr"
												target="_blank"
												rel="noreferrer"
											>
												http://kopico.go.kr
											</a>{" "}
											/ 국번없이 1833-6972)
										</li>
									</ul>
								</li>
							</ul>
							<h4>제 11조 기타</h4>
							<ul>
								<li>
									1) “회사”가 “회원”에게 서비스 상 다른 “회사”의 웹사이트 혹은
									자료에 대한 링크를 제공할 수 있으며, 외부 사이트에서
									개인정보를 수집하는 행위에 대해서는 본 개인정보처리방침이
									적용되지 않습니다. 해당 사이트 방문 시에는 해당 사이트의
									개인정보처리 방침을 확인하시기 바랍니다.
								</li>
								<li>
									2) “회원”은 개인정보를 보호 받을 권리를 가짐과 동시에 본인의
									정보를 스스로 보호하고 타인의 정보를 침해하지 않을 책임을
									지닙니다. 자신과 타인 모두의 개인정보를 유출하거나 훼손하지
									않도록 유의해야 하며, 책임을 다 하지 않을 경우 보호받지 못할
									경우가 생길 수 있습니다. 또한 타인의 정보를 훼손할 경우 관련
									법령에 의해 처벌받을 수 있으며, 이 경우 “회사”는 책임지지
									않습니다.
								</li>
								<li>
									3) 잘못된 개인정보를 입력하여 발생하는 이용상의 불이익 또는
									손해는 전적으로 “회원” 본인에게 있습니다.
								</li>
							</ul>
							<h4>제 12조 고지의 의무</h4>
							<ul>
								<li>
									본 개인정보처리방침은 관련 법령 및 지침의 변경이나 회사의 내부
									방침 변경 등으로 인하여 수시로 변경될 수 있으며,
									개인정보처리방침의 변경이 있을 경우 최소 7일 전부터 공식
									커뮤니티, 고객센터 또는 게임 내 연결화면을 통해 공지합니다.
								</li>
							</ul>
							<h4>부칙</h4>
							<ul>
								<li>본 방침은 2018년 7월 9일부로 적용됩니다.</li>
							</ul>
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PrivacyPolicy;

import { Button, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { cdn, cdnImg } from "../../../config/etc";
import { getGallery } from "../../../service/Events";

const bg = cdnImg + "bg7.jpg";
const btImageOn = cdnImg + "pc/7/갤러리-활성.png";
const btImageOff = cdnImg + "pc/7/갤러리-비활성.png";
const btVideoOn = cdnImg + "pc/7/공식영상-활성.png";
const btVideoOff = cdnImg + "pc/7/공식영상-비활성.png";
const box = cdnImg + "pc/7/box.png";
// const rightSmall = cdnImg + "pc/7/하단-우측 화살표.png";
// const leftSmall = cdnImg + "pc/7/하단-좌측 화살표.png";
const right = cdnImg + "pc/7/pop/우측 화살표.png";
const left = cdnImg + "pc/7/pop/좌측 화살표.png";
// const boxMo = cdnImg + "mo/7/box.png";
const boxMo = cdnImg + "bgGalMo.png";
const btClose = cdnImg + "bt_close.png";

export default function FD2GameInfo() {
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	const isBig = useMediaQuery({ query: "(min-height: 1040px)" });
	const [images, setImages] = useState();
	const [videos, setVideos] = useState();
	const [selected, setSelected] = useState("img");
	const [selectedImg, setSelectedImg] = useState();
	const [matrix, setMatrix] = useState();
	const [pages, setPages] = useState([1]);
	const [selectedPage, setSelectedPage] = useState(1);

	const heightGrid = isMobile ? 500 : 624;
	const widthGrid = isMobile ? 330 : 840;
	const gridNum = 12;
	const margin = 2;

	const imageRow = 2;
	const imageCol = 4;
	const images1Page = imageRow * imageCol;

	const videoRow = isMobile ? 2 : 3;
	const videoCol = 4;
	const videos1Page = videoRow * videoCol;

	const onSelectImg = (sel) => {
		setSelectedImg(sel);
	};
	const onSelectGal = (sel) => {
		setSelected(sel);
		setSelectedPage(1);
		makePages(sel === "img" ? images : videos, sel);
	};
	const getImageUrl = (target) => {
		return (
			cdn +
			"images/" +
			target.image.split("/")[target.image.split("/").length - 1]
		);
	};
	const makeYoutubeUrl = (link) => {
		const arr = link.split("/");
		const code = arr[arr.length - 1];
		return "https://www.youtube.com/embed/" + code + "?autoplay=1";
	};
	const onPrev = () => {
		setSelectedImg((prev) => {
			return prev - 1 < 0
				? (selected === "img" ? images : videos).length - 1
				: prev - 1;
		});
	};
	const onNext = () => {
		setSelectedImg((prev) => {
			return prev + 1 > (selected === "img" ? images : videos).length - 1
				? 0
				: prev + 1;
		});
	};
	const makePages = (target, type) => {
		const pagesArr = [];
		for (
			let i = 0;
			i <
			Math.ceil(target?.length / (type === "img" ? images1Page : videos1Page));
			i++
		) {
			pagesArr.push(i + 1);
		}
		setPages(pagesArr);
	};
	const makeMatrix = (target) => {
		let tempMatrix = [];
		let tempRand;
		for (let i = 0; i < Math.floor(target.length / 2); i++) {
			let randNum = Math.floor(Math.random() * 3) + 1;
			if (randNum === tempRand) {
				randNum++;
			}
			randNum = randNum > 3 ? 1 : randNum;
			if (randNum === 1) {
				tempMatrix.push(4);
				tempMatrix.push(8);
			}
			if (randNum === 2) {
				tempMatrix.push(6);
				tempMatrix.push(6);
			}
			if (randNum === 3) {
				tempMatrix.push(8);
				tempMatrix.push(4);
			}
			tempRand = randNum;
		}
		setMatrix(tempMatrix);
	};
	useEffect(() => {
		getGallery().then((res) => {
			// console.log(res.data);
			// setVideos(res.data.videos.length < 1 ? videosSample : res.data.videos);

			setImages(res.data.images);
			setVideos(res.data.videos);
			makeMatrix(res.data.images);
			makePages(res.data.images, "img");
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div
			style={{
				backgroundImage: `url(${bg})`,
				backgroundSize: "cover",
				backgroundPosition: "top center",
				backgroundRepeat: "no-repeat",
				backgroundAttachment: "fixed",
				height: isMobile
					? window.innerHeight
					: !isBig
					? "100%"
					: window.innerHeight,
			}}
		>
			<div
				className="h-full m-auto flex flex-col items-center justify-start text-white p-0 sm:p-4 pt-14 sm:pt-14"
				style={{
					// height: isMobile ? "100%" : window.innerHeight,
					// paddingLeft: isMobile && status !== "main" ? 0 : 15,
					// paddingRight: isMobile && status !== "main" ? 0 : 15,
					color: "#402c00",
				}}
			>
				<div className=" w-full flex flex-col items-center">
					<div
						className="flex flex-col items-center "
						style={{
							backgroundImage: `url(${isMobile ? boxMo : box})`,
							backgroundSize: isMobile ? 490 : "cover",
							backgroundPosition: isMobile ? "center -80px" : "center -50px",
							backgroundRepeat: "no-repeat",
							height: isMobile ? 680 : 900,
							width: isMobile ? "100%" : 1300,
							marginBottom: isMobile ? 0 : 40,
						}}
					>
						<div className="flex mt-8 sm:mt-12">
							<Button
								onClick={() => onSelectGal("img")}
								style={{ margin: isMobile ? "0 -3px" : "0 -7px", padding: 0 }}
							>
								<img
									src={selected === "img" ? btImageOn : btImageOff}
									alt="갤러리"
									width={isMobile ? 170 : 230}
								/>
							</Button>
							<Button
								onClick={() => onSelectGal("video")}
								style={{ margin: isMobile ? "0 -3px" : "0 -7px", padding: 0 }}
							>
								<img
									src={selected === "video" ? btVideoOn : btVideoOff}
									alt="갤러리"
									width={isMobile ? 170 : 230}
								/>
							</Button>
						</div>

						<div
							style={{
								marginTop: 40,
								height: heightGrid,
								width: widthGrid,
							}}
							className="relative flex "
						>
							{selected === "img" ? (
								<div>
									{matrix &&
										images.map((img, idx) => {
											if (
												idx > images1Page * selectedPage - 1 ||
												idx < images1Page * (selectedPage - 1)
											)
												return null;
											const defaultStyle = {
												// height: (heightGrid/gridNum * (matrix[idx] ?? 1)) - 4,
												// width: widthGrid/imageRow - 4,
												width:
													(widthGrid / gridNum) * (matrix[idx] ?? gridNum) -
													margin * 2,
												height: heightGrid / imageCol - margin * 2,
												backgroundImage: `url(${getImageUrl(img)})`,
												backgroundPosition: "center",
												margin,
												border: "3px solid #f4aa2f",
											};
											if (idx % 2 === 0)
												return (
													<div
														key={idx}
														onClick={() => onSelectImg(idx)}
														className="absolute bg-cover cursor-pointer"
														// style={defaultStyle}
														style={{
															...defaultStyle,
															top:
																(heightGrid / imageCol) *
																(idx > 0 ? (idx % images1Page) / 2 : 0),
															left: 0,
														}}
													></div>
												);
											return (
												<div
													key={idx}
													onClick={() => onSelectImg(idx)}
													className="absolute bg-cover cursor-pointer"
													style={{
														...defaultStyle,
														// top: heightGrid/gridNum * matrix[idx - 1],
														// left: widthGrid / gridNum * imageRow * ((idx + 1) / 2 - 1),
														left: (widthGrid / gridNum) * matrix[idx - 1],
														top:
															(heightGrid / imageCol) *
															(((idx % images1Page) + 1) / 2 - 1),
													}}
												></div>
											);
										})}
								</div>
							) : (
								// <div className="flex flex-wrap items-start justify-start">
								<div>
									{videos?.map((video, idx) => {
										if (
											idx > videos1Page * selectedPage - 1 ||
											idx < videos1Page * (selectedPage - 1)
										)
											return null;
										const defaultStyle = {
											width: widthGrid / videoRow - margin * 2,
											height: heightGrid / videoCol - margin * 2,
											backgroundImage: `url(${getImageUrl(video)})`,
											backgroundPosition: "center",
											margin,
											border: "3px solid #f4aa2f",
											left: (widthGrid / videoRow) * (idx % videoRow),
											top:
												(heightGrid / videoCol) *
												(Math.floor(idx / videoRow) % videoCol),
										};
										return (
											<div
												key={idx}
												onClick={() => onSelectImg(idx)}
												className="absolute bg-cover cursor-pointer"
												style={defaultStyle}
											></div>
										);
									})}
								</div>
							)}
						</div>
						<div
							className="mt-10 "
							// style={{ marginTop: isMobile ? 200 : 200 }}
						>
							{pages.map((page, idx) => (
								<IconButton
									key={idx}
									onClick={() => {
										makeMatrix(images);
										setSelectedPage(page);
									}}
								>
									<span
										className="w-6 font-bold 0"
										style={{
											color: page === selectedPage ? "#f4aa2f" : "#fff",
											textShadow: "3px 3px #00000086",
										}}
									>
										{page}
									</span>
								</IconButton>
							))}
						</div>
					</div>
					{selectedImg > -1 && (
						<div
							className="absolute  w-full h-full flex justify-center items-center z-40"
							style={{ backgroundColor: "#000000dd" }}
						>
							<div className="relative" style={{ border: "4px solid #f4aa2f" }}>
								<div
									className="bg-black absolute  -top-12 -right-1"
									style={{ backgroundColor: "#f4aa2f" }}
								>
									<IconButton
										onClick={() => setSelectedImg(-1)}
										style={{ padding: 0 }}
									>
										<img src={btClose} alt="close" width={38} className="" />
									</IconButton>
								</div>
								<div
									className="absolute top-1/2 transform -translate-y-1/2 "
									style={{ right: isMobile ? -45 : -65 }}
								>
									<IconButton onClick={onNext} style={{ padding: 0 }}>
										<img
											src={right}
											alt="close"
											width={isMobile ? 40 : 60}
											className=""
										/>
									</IconButton>
								</div>
								<div
									className="absolute top-1/2 transform -translate-y-1/2 "
									style={{ left: isMobile ? -45 : -65 }}
								>
									<IconButton onClick={onPrev} style={{ padding: 0 }}>
										<img
											src={left}
											alt="close"
											width={isMobile ? 40 : 60}
											className=""
										/>
									</IconButton>
								</div>
								{selected === "img" ? (
									<img
										src={getImageUrl(images[selectedImg])}
										alt=""
										style={{
											maxWidth: window.innerWidth - (isMobile ? 90 : 150),
										}}
									/>
								) : (
									<div>
										<iframe
											width={isMobile ? 300 : 900}
											height={isMobile ? 200 : 500}
											src={makeYoutubeUrl(videos[selectedImg].link)}
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen="true"
										></iframe>
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

// const imagesSample = [
// 	{ image: "1.jpg" },
// 	{ image: "2.jpg" },
// 	{ image: "3.jpg" },
// 	{ image: "4.jpg" },
// 	{ image: "5.jpg" },
// 	{ image: "6.jpg" },
// 	{ image: "7.jpg" },
// 	{ image: "1.jpg" },
// 	{ image: "2.jpg" },
// 	{ image: "3.jpg" },
// 	{ image: "4.jpg" },
// 	{ image: "5.jpg" },
// 	{ image: "6.jpg" },
// 	{ image: "7.jpg" },
// 	{ image: "1.jpg" },
// 	{ image: "2.jpg" },
// 	{ image: "3.jpg" },
// 	{ image: "4.jpg" },
// 	{ image: "5.jpg" },
// ];
// const videosSample = [
// 	{ image: "v1.jpg", link: "dejjC5Bd2BA" },
// 	{ image: "v2.jpg", link: "IqFLxtxPk0I" },
// 	{ image: "v3.jpg", link: "o3GCVpV4dpg" },
// 	{ image: "v4.jpg", link: "GUfBBsyV6W8" },
// 	{ image: "v5.jpg", link: "PqXbrDso3jQ" },
// 	{ image: "v1.jpg", link: "dejjC5Bd2BA" },
// 	{ image: "v2.jpg", link: "IqFLxtxPk0I" },
// 	{ image: "v3.jpg", link: "o3GCVpV4dpg" },
// 	{ image: "v4.jpg", link: "GUfBBsyV6W8" },
// 	{ image: "v5.jpg", link: "PqXbrDso3jQ" },
// 	{ image: "v1.jpg", link: "dejjC5Bd2BA" },
// 	{ image: "v2.jpg", link: "IqFLxtxPk0I" },
// 	{ image: "v3.jpg", link: "o3GCVpV4dpg" },
// 	{ image: "v4.jpg", link: "GUfBBsyV6W8" },
// 	{ image: "v5.jpg", link: "PqXbrDso3jQ" },
// ];

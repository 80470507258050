import React from "react";
import { Helmet } from "react-helmet-async";

const locales = {
	en: "en_US",
	ko: "ko_KR",
};

const Meta = ({ data }) => {
	const lang = locales[data.locale] || locales["ko-KR"];
	const siteName = data.siteName ?? data.title;
	const title = data.title;
	const description = data.description;
	const image = data.image;
	const canonical = `${window.location}`;
	// const canonical = `${window.location}${data.canonical}`;
	const type = data.type === undefined ? "website" : data.type;
	const width = data.image && (data.width || 1200);
	const height = data.image && (data.height || 630);

	return (
		<Helmet>
			<html lang={lang} />
			<title>{title}</title>
			<meta name="description" content={description} data-react-helmet="true" />
			{canonical ? (
				<link rel="canonical" href={canonical} data-react-helmet="true" />
			) : null}
			{image ? (
				<link rel="image_src" href={image} data-react-helmet="true" />
			) : null}
			{image ? (
				<meta itemprop="image" content={image} data-react-helmet="true" />
			) : null}

			<meta
				property="og:site_name"
				content={siteName}
				data-react-helmet="true"
			/>
			<meta property="og:title" content={title} data-react-helmet="true" />
			{description ? (
				<meta
					property="og:description"
					content={description}
					data-react-helmet="true"
				/>
			) : null}
			{canonical ? (
				<meta property="og:url" content={canonical} data-react-helmet="true" />
			) : null}
			<meta
				property="og:locale"
				content={locales[lang]}
				data-react-helmet="true"
			/>
			<meta property="og:type" content={type} data-react-helmet="true" />
			{image ? (
				<meta property="og:image" content={image} data-react-helmet="true" />
			) : null}
			{width ? (
				<meta
					property="og:image:width"
					content={width}
					data-react-helmet="true"
				/>
			) : null}
			{height ? (
				<meta
					property="og:image:height"
					content={height}
					data-react-helmet="true"
				/>
			) : null}
			<meta property="fb:pages" content={title} data-react-helmet="true" />

			{/* change type of twitter if there is no image? */}
			<meta
				name="twitter:card"
				content="summary_large_image"
				data-react-helmet="true"
			/>
			<meta name="twitter:title" content={title} data-react-helmet="true" />
			{description ? (
				<meta
					name="twitter:description"
					content={description}
					data-react-helmet="true"
				/>
			) : null}
			{image ? (
				<meta name="twitter:image" content={image} data-react-helmet="true" />
			) : null}
			<meta name="twitter:site" content={title} data-react-helmet="true" />
			{canonical ? (
				<link
					rel="alternate"
					href={data.canonical}
					hreflang={lang}
					data-react-helmet="true"
				/>
			) : null}
		</Helmet>
	);
};

export default Meta;

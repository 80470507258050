import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getToken, jwtLogin } from '../service/Auth';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

const Context = createContext({
  setUser: (userInfo) => {},
  removeUser: () => {},
  user: {},
});
export const useLoginContext = () => useContext(Context);

let refreshInterval;

const LoginContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const setUser = (data) => {
    if (data.token) axios.defaults.headers.common['Authorization'] = data.token;
    if (data.maxAge && data.maxAge < 1000000) {
      if (refreshInterval) window.clearInterval(refreshInterval);
      refreshInterval = window.setInterval(getToken, data.maxAge - 60000);
    }
    if (data.path) localStorage.setItem('path', data.path);
    setState((prevState) => {
      return {
        ...prevState,
        user: { ...data.user, path: data.path, token: data.token },
      };
    });
  };
  const removeUser = () => {
    axios.defaults.headers.common['Authorization'] = '';
    window.clearInterval(refreshInterval);
    setState((prevState) => {
      return {
        ...prevState,
        user: { path: prevState.user.path },
      };
    });
  };

  const defaultState = {
    setUser,
    removeUser,
    user: {
      path: localStorage.getItem('path'),
    },
  };

  const initialState = {
    ...defaultState,
  };

  const [state, setState] = useState(initialState);
  const history = useHistory();

  useEffect(() => {
    getToken()
      .then((res) => {
        jwtLogin(res.data.token)
          .then((r) => {
            if (r?.data?.errors?.[0]?.error) {
              enqueueSnackbar(r?.data?.errors?.[0]?.error, {
                variant: 'error',
              });
              history.replace('/');
              return;
            }
            setUser(r.data);
          })
          .catch((e) => {
            console.log('E', e.response);
          });
      })
      .catch((err) => {
        console.log('no token');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
export default LoginContextProvider;

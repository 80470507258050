import React from 'react';
import { Button, Divider } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { cdnImg } from '../../../config/etc';
import ShareButtons from '../components/ShareButtons';
import { useEventContext } from '../../../contexts/EventContext';
// import ShareButtons from "../components/ShareButtons";
const back_q = cdnImg + 'back_q.png';
const bt_close = cdnImg + 'bt_close.png';
const cImg = cdnImg + 'c_img.png';
const cName = cdnImg + 'c_name.png';
const pfImg = cdnImg + 'pf_img.png';
const pfName = cdnImg + 'pf_name.png';
const sfImg = cdnImg + 'sf_img.png';
const sfName = cdnImg + 'sf_name.png';
const sgImg = cdnImg + 'sg_img.png';
const sgName = cdnImg + 'sg_name.png';
const pgImg = cdnImg + 'pg_img.png';
const pgName = cdnImg + 'pg_name.png';
// const btCafe = cdnImg + "bt_visit_cafe.png";
const btShare = cdnImg + 'btShare.png';
const btRestart = cdnImg + 'bt_restart.png';

export default function PositionTestResult({
  result,
  onRestart,
  onClose,
  onShare,
}) {
  // result = "pf";
  const { event } = useEventContext();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return (
    // <div className="absolute flex flex-col items-center border p-4">
    <div
      className={
        (event.dateState > 2 ? '' : ' absolute z-50 ') + 'flex h-full w-full '
      }
      style={{ backgroundColor: '#000000dd', height: window.innerHeight }}
    >
      <div
        className="m-auto bg-cover "
        style={{
          backgroundImage: `url(${back_q})`,
          // height: window.innerHeight,
          // "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
          // borderColor: "#f4aa2f",
        }}
      >
        <div
          className="sm:w-3xl text-white font-bold sm:border-4 border-yellow-500 p-2 flex flex-col pt-6 sm:pt-0 sm:justify-center items-center bg-cover"
          style={{
            backgroundImage: `url(${resultData[result].img})`,
            backgroundPosition: isMobile
              ? resultData[result].backPositionMo
              : resultData[result].backPosition,
            backgroundSize: isMobile
              ? resultData[result].backSizeMo + '%'
              : resultData[result].backSize + '%',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {event.dateState < 3 ? (
            <div className="w-full text-right">
              <Button onClick={onShare} className="">
                <img src={bt_close} alt="close" width={33} className="-mr-4" />
              </Button>
            </div>
          ) : (
            <div className="h-10"></div>
          )}
          <div
            className="w-full flex px-4 sm:px-12 pt-6"
            style={{
              justifyContent: isMobile
                ? 'flex-start'
                : resultData[result].align
                ? 'flex-end'
                : 'flex-start',
            }}
          >
            <div style={{ fontWeight: 300 }}>
              <div
                className="mb-1"
                style={{
                  marginTop: isMobile ? -40 : resultData[result].mt1,
                  fontSize: 20,
                }}
              >
                당신과 잘 어울리는 포지션은..
              </div>
              <img
                src={resultData[result].nameImg}
                alt={resultData[result].name}
                width={resultData[result].nameSize}
              />
              <div
                style={{
                  marginTop: isMobile ? 20 : resultData[result].mt2,
                  maxWidth: 300,
                }}
              >
                {resultData[result].desc}
              </div>
              <div
                className="w-full text-center mb-4"
                style={{ marginTop: isMobile ? 170 : resultData[result].mt3 }}
              >
                <Button onClick={onRestart}>
                  <img src={btRestart} alt="테스트다시하기" width={160} />
                </Button>
              </div>
            </div>
          </div>
          <Divider
            style={{ width: '90%', height: 0.5, backgroundColor: '#aaa' }}
          />
          <div className="flex flex-col items-center">
            {/* <Button variant="contained">내 결과 공유하기</Button> */}
            <h3
              className="mt-4 sm:mt-6 mb-4"
              style={{
                color: '#f4aa2f',
                fontSize: 18,
                textShadow: '2px 2px #000000aa',
              }}
            >
              {event.dateState < 3
                ? '내 결과를 공유하고 이벤트에 참여해보세요!'
                : '내 결과를 친구들에게 공유해 보세요!'}
            </h3>
            <div className="my-2">
              {result && (
                <ShareButtons
                  id="kakaoButtonMain"
                  message={
                    '[프리스타일2 레볼루션 플라잉덩크] 나에게 어울리는 농구 포지션은? - ' +
                    resultData[result]?.name
                  }
                  media={`${cdnImg}position_${
                    result !== 'c' ? result : 'center'
                  }.jpg`}
                  pathname={resultData[result]?.url}
                  result={result}
                />
              )}
            </div>
            <div className="sm:my-4">
              {event.dateState < 3 && (
                <Button onClick={onShare}>
                  <img src={btShare} alt="공유하러 가기" width={360} />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const resultData = {
  c: {
    mt1: 0,
    mt2: 110,
    mt3: 45,
    align: true,
    nameSize: 175,
    backSize: 87,
    backPosition: 'bottom',
    backSizeMo: 160,
    backPositionMo: '-10% 190px',
    nameImg: cName,
    name: '센터 [C]',
    img: cImg,
    desc: '골 밑에서 득점과 리바운드를 통한 강력한 한방을 노리는 센터! \n자유분방하고 도전하는 걸 좋아하는 당신에게 추천하는 포지션입니다.',
    url: '/position/center',
  },
  pf: {
    mt1: 0,
    mt2: 20,
    mt3: 135,
    align: false,
    nameSize: 330,
    backSize: 58,
    backPosition: 'right bottom',
    backSizeMo: 110,
    backPositionMo: 'center 200px',
    nameImg: pfName,
    name: '파워포워드 [PF]',
    img: pfImg,
    desc: '스몰 포워드 역할과 더불어 골밑에서 리바운드, 패스를 책임지는 파워포워드!\n자율적인 판단 능력이 뛰어나고 항상 신중한 당신에게 추천하는 포지션입니다.',
    url: '/position/powerForward',
  },
  sf: {
    mt1: 115,
    mt2: 25,
    mt3: 15,
    align: false,
    nameSize: 330,
    backSize: 50,
    backPosition: 'right bottom',
    backSizeMo: 90,
    backPositionMo: '10% 170px',
    nameImg: sfName,
    name: '스몰포워드 [SF]',
    img: sfImg,
    desc: '침착하게 한방을 노리는 성격의 당신,\n 직접적인 공격보다는 빠른 속공 실력이 필요한 스몰포워드가 딱이네요!',
    url: '/position/smallForward',
  },
  sg: {
    mt1: -20,
    mt2: 160,
    mt3: 15,
    align: false,
    nameSize: 300,
    backSize: 71,
    backPosition: 'right bottom',
    backSizeMo: 150,
    backPositionMo: 'center 140px',
    nameImg: sgName,
    name: '슈팅가드 [SG]',
    img: sgImg,
    desc: '정확한 슛 능력과, 다양한 역할을 수행해야 하는 슈팅가드!\n높은 집중력을 가진 다재다능한 당신에게 추천합니다.',
    url: '/position/shootingGuard',
  },
  pg: {
    mt1: 40,
    mt2: 20,
    mt3: 55,
    align: false,
    nameSize: 330,
    backSize: 70,
    backPosition: 'right bottom',
    backSizeMo: 130,
    backPositionMo: 'right 180px',
    nameImg: pgName,
    name: '포인트가드 [PG]',
    img: pgImg,
    desc: '팀의 사령탑이자 게임메이커로 통하는 포인트가드!\n팀의 경기를 이끌어 가는 역할로 넓은 시야와 패스 능력이 중요합니다.\n열정적이고 개인보다 팀을 중요시 생각하는 리더십을 가진, 당신에게 딱인 포지션이에요.',
    url: '/position/pointGuard',
  },
};

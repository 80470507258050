import { Avatar } from "@material-ui/core";
import React from "react";
// import moment from "moment";
import SnsButton from "./SnsButton";
import LoginBox from "./LoginBox";
import { aniContainer, aniItem } from "../../config/animation";
import { motion } from "framer-motion";
import { DoubleArrowTwoTone } from "@material-ui/icons";

export default function SNSConnect() {
	const { data } = JSON.parse(sessionStorage.getItem("connectData"));

	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className="max-w-lg mx-auto p-4 sm:mt-4"
		>
			<motion.h1
				variants={aniItem}
				className="font-bold text-xl text-purple-600 mb-6"
			>
				간편 가입 연동
			</motion.h1>
			<motion.p variants={aniItem} className="">
				기존에 사용하는 계정이 있습니다. 연결하려면 아래 로그인 수단 중 하나로
				로그인 해주세요.
			</motion.p>
			<motion.div
				variants={aniItem}
				className="flex items-center justify-center my-5"
			>
				<div className="">
					<Avatar src={data.avatar} className="mx-auto" />
					<p>{data.nickname}</p>
				</div>
				<DoubleArrowTwoTone fontSize="large" color="primary" className="mx-4" />
				<div className="">
					<Avatar src={data.user.avatar} className="mx-auto" />
					<p>{data.user.nickname}</p>
				</div>
			</motion.div>
			{data.user.paths && (
				<motion.div variants={aniItem} className="">
					{data.user.paths.map((path, idx) => {
						return path === "email" ? (
							<div key={path + idx}>
								<LoginBox isConnect={true} prevEmail={data.user.email_id} />
							</div>
						) : (
							<div key={path + idx} className=" my-2">
								<SnsButton path={path} stateStr="isConnect" />
							</div>
						);
					})}
				</motion.div>
			)}
		</motion.div>
	);
}

import {
	FacebookButton,
	// TwitterButton,
	NaverBlogButton,
	KaKaoStoryButton,
	// KaKaoTalkButton,
} from "react-social-kr";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import share_f from "../../../assets/share_f.png";
// import share_k from "../../../assets/share_k.png";
// import share_l from "../../../assets/share_l.png";
// import share_n from "../../../assets/share_n.png";
// import share_s from "../../../assets/share_s.png";
// import share_t from "../../../assets/share_t.png";
import { SNS } from "../../../config/snsKeys";
import Analytics from "../../../service/Analytics";
import { useSnackbar } from "notistack";
import { cdnImg } from "../../../config/etc";

const share_k = cdnImg + "sns_kakao.png";
const share_f = cdnImg + "sns_fb.png";
const share_l = cdnImg + "sns_url.png";
const share_n = cdnImg + "sns_blog.png";
const share_s = cdnImg + "sns_kas.png";

const ShareImg = ({ mobileImg, pcImg, type }) => (
	<div className="cursor-pointer">
		{/* <div className="sm:hidden flex flex-col items-center">
			<img src={mobileImg} alt={type} className="w-16 mb-1" />
			<span className="text-xxs">{type}</span>
		</div> 
		 <img src={pcImg} alt={type} className="hidden sm:block  " /> */}
		<img src={pcImg} alt={type} width={40} />
	</div>
);

const ShareButtons = ({ message, media, pathname, result, id }) => {
	// console.log("media: ", media);
	const { enqueueSnackbar } = useSnackbar();
	const clickShare = (type) => {
		Analytics.logEvent("clickShare", { position: result, type });
	};
	return (
		<div>
			{result ? (
				<div className="flex space-x-5 justify-center">
					{/* <KaKaoTalkButton
						id={id}
						message={message}
						media={media}
						jsKey={SNS.key.kakao}
						pathname={pathname}
						onClick={() => clickShare("kakao")}
					>
						<ShareImg mobileImg={share_k} pcImg={share_k} type="카카오톡" />
					</KaKaoTalkButton> */}
					<FacebookButton
						message={message}
						media={media}
						appId={SNS.key.facebook}
						pathname={pathname}
						onClick={() => clickShare("facebook")}
					>
						<ShareImg mobileImg={share_f} pcImg={share_f} type="페이스북" />
					</FacebookButton>
					{/* <TwitterButton
						message={message}
						media={media}
						pathname={pathname}
						onClick={() => clickShare("twitter")}
					>
						<ShareImg mobileImg={share_t} pcImg={share_t} type="트위터" />
					</TwitterButton> */}
					<NaverBlogButton
						message={message}
						media={media}
						pathname={pathname}
						onClick={() => clickShare("naver")}
					>
						<ShareImg mobileImg={share_n} pcImg={share_n} type="블로그" />
					</NaverBlogButton>
					<KaKaoStoryButton
						message={message}
						media={media}
						pathname={pathname}
						onClick={() => clickShare("kakaoStory")}
					>
						<ShareImg mobileImg={share_s} pcImg={share_s} type="스토리" />
					</KaKaoStoryButton>
					<CopyToClipboard
						text={"https://www.cyougame.kr" + pathname}
						onCopy={() => {
							enqueueSnackbar(
								"주소가 복사되었습니다. Ctrl-v 로 붙여넣기 하세요.",
								{
									variant: "success",
								}
							);
						}}
					>
						<div onClick={() => clickShare("copyLink")}>
							<ShareImg mobileImg={share_l} pcImg={share_l} type="링크복사" />
						</div>
					</CopyToClipboard>
				</div>
			) : (
				<div
					className="flex space-x-5 justify-center"
					onClick={() => {
						enqueueSnackbar(
							"공유하기 전에 먼저 포지션 테스트에 참여해 주세요.",
							{
								variant: "error",
							}
						);
					}}
				>
					<ShareImg mobileImg={share_k} pcImg={share_k} type="카카오톡" />

					<ShareImg mobileImg={share_f} pcImg={share_f} type="페이스북" />

					<ShareImg mobileImg={share_n} pcImg={share_n} type="블로그" />

					<ShareImg mobileImg={share_l} pcImg={share_l} type="링크복사" />
				</div>
			)}
		</div>
	);
};

export default ShareButtons;

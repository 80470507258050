import axios from "axios";
import { apiServer } from "../config/servers";

export const getPoint = (id) => axios.get(apiServer + "/point/getPoint/" + id);

export const getPointTotal = (id) =>
	axios.get(apiServer + "/point/getPointTotal/" + id);

export const spendPoint = (id, data) =>
	axios.put(apiServer + "/point/spendPoint/" + id, data);

import { checkDuplicated } from "../service/SnsAuth";
import { simpleMemoize } from "./utility";
// import _ from "lodash";
export const requiredValidator = (value) =>
	value ? undefined : "필수 항목입니다.";

export const specialCharValidator = (nickname) => {
	var nickLength = 0;
	// eslint-disable-next-line no-useless-escape
	var specialCheck = /[`~!@#$%^&*|\\\'\";:\/?]/gi;

	for (var i = 0; i < nickname.length; i++) {
		let nick = nickname.charAt(i);
		if (escape(nick).length > 4) {
			nickLength += 2;
		} else {
			nickLength += 1;
		}
	}
	if (nickname.search(/\s/) !== -1) {
		return "닉네임은 빈 칸을 포함 할 수 없습니다.";
	} else if (nickname.length < 2 || nickLength < 2 || nickLength > 20) {
		return "한글 2~10자, 영문/숫자 2~20자";
	} else if (specialCheck.test(nickname)) {
		return "특수문자를 포함 할 수 없습니다.";
	}
};

export const minMaxValidator = (value) =>
	isNaN(value) || value.toString().length !== 6
		? "6자리 숫자를 입력해 주세요."
		: undefined;

export const mustBeNumberValidator = (value) =>
	isNaN(value) ? "숫자를 입력해 주세요." : undefined;

export const minValueValidator = (min) => (value) =>
	isNaN(value) || value >= min
		? undefined
		: `${min}이상의 숫자를 입력해 주세요.`;

export const passwordCheckValidator = (value, va) =>
	value === va.password ? undefined : "비밀번호가 일치하지 않습니다";

export const prevPasswordCheckValidator = (value, va) =>
	value !== va.password
		? undefined
		: "기존과 같은 비밀번호를 설정할 수 없습니다";

export const newPasswordCheckValidator = (value, va) =>
	value === va.newPassword ? undefined : "비밀번호가 일치하지 않습니다";

export const passwordValidator = (value) => {
	var regex = /^.*(?=^.{8,55}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
	if (!regex.test(value)) {
		return "영문,숫자,특문 포함 8자리 이상";
	}
	return undefined;
};

export const emailValidator = (value) => {
	var regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
	if (!regex.test(value)) {
		return "이메일 형식에 맞지 않습니다.";
	}
	return undefined;
};

export const composeValidators = (...validators) => (value, allValues) =>
	validators.reduce(
		(error, validator) => error || validator(value, allValues),
		undefined
	);

export const nickAvailable = simpleMemoize(async (value) => {
	let isDuplicated = await checkDuplicated("nickname", value);
	if (isDuplicated) {
		return "이미 존재하는 닉네임입니다.";
	} else {
		return undefined;
	}
});
export const emailAvailable = simpleMemoize(async (value) => {
	let isDuplicated = await checkDuplicated(
		"emails",
		value.trim().toLowerCase()
	);
	if (isDuplicated) {
		let pathName = [];
		if (isDuplicated.email_id) pathName.push("이메일");
		if (isDuplicated.google_email) pathName.push("구글");
		if (isDuplicated.facebook_email) pathName.push("페이스북");
		if (isDuplicated.naver_email) pathName.push(" 네이버");
		if (isDuplicated.kakao_email) pathName.push("  카카오톡");
		return `${pathName}을 통해 가입한 이메일입니다.`;
	} else {
		return undefined;
	}
});
export const emailRegistered = simpleMemoize(async (value) => {
	let isDuplicated = await checkDuplicated(
		"emails",
		value.trim().toLowerCase()
	);
	if (!isDuplicated) {
		return `가입한 이메일이 아닙니다.`;
	} else {
		return undefined;
	}
});

import React, { useEffect, useState } from 'react';

import {
  Button,
  withStyles,
  SwipeableDrawer,
  IconButton,
  Divider,
} from '@material-ui/core';
// import bgLeft from "../../../assets/fd2/bg_menu_left.png";
// import bgRight from "../../../assets/fd2/bg_menu_right.png";
// import btGift from "../../../assets/fd2/icon_gift.png";
// import btMenu from "../../../assets/fd2/bt_menu.png";
// import bgBall from "../../../assets/fd2/bg_menu_ball.png";
// import bgMenu from "../../../assets/fd2/bg_menu.png";
// import btCoupon from "../../../assets/fd2/bt_coupon.png";
// import btClose from "../../../assets/fd2/bt_close.png";
// import bgLogin from "../../../assets/fd2/PC-png/03_positiontest/팝업/창유로그인-on-box.png";
// import btCancel from "../../../assets/fd2/PC-png/03_positiontest/팝업/창유로그인-no-취소버튼.png";
// import btLogin from "../../../assets/fd2/PC-png/03_positiontest/팝업/창유로그인-no-로그인하기버튼.png";
// import bgReward from "../../../assets/fd2/PC-png/03_positiontest/팝업/보상확인-bg.png";
// import bgRewardCoupon from "../../../assets/fd2/PC-png/03_positiontest/팝업/보상함-box.png";
// import bgRewardInvite from "../../../assets/fd2/PC-png/03_positiontest/팝업/친구초대 현황-box.png";
// import logo from "../../../assets/fd2/logo.png";

import { useMediaQuery } from 'react-responsive';
import FDSNS from '../components/FDSNS';
import { useHistory } from 'react-router';
import { useLoginContext } from '../../../contexts/LoginContext';
import { useEventContext } from '../../../contexts/EventContext';
import { cdnImg } from '../../../config/etc';
import { getEvent } from '../../../service/Events';
import { useCookies } from 'react-cookie';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';

const LinkButton = withStyles((theme) => {
  return {
    root: {
      // background: "#D2DDF6",
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
      '&:focus': {
        outline: 0,
      },
    },
  };
})(Button);

const bgLeft = cdnImg + 'bg_menu_left.png';
const bgRight = cdnImg + 'bg_menu_right.png';
const btGift = cdnImg + 'icon_gift.png';
const btMenu = cdnImg + 'bt_menu.png';
const bgBall = cdnImg + 'bg_menu_ball.png';
const bgMenu = cdnImg + 'bg_menu.png';
const btCoupon = cdnImg + 'bt_coupon.png';
const btClose = cdnImg + 'bt_close.png';
const bgLogin = cdnImg + 'pc/3/pop/창유로그인-on-box.png';
const bgLoginMo = cdnImg + 'mo/3/pop/창유로그인-no-bg.png';
const btCancel = cdnImg + 'pc/3/pop/창유로그인-no-취소버튼.png';
const btLogin = cdnImg + 'pc/3/pop/창유로그인-no-로그인하기버튼.png';
const bgReward = cdnImg + 'pc/3/pop/보상확인-bg.png';
const bgRewardCoupon = cdnImg + 'pc/3/pop/보상함-box.png';
const bgRewardInvite = cdnImg + 'pc/3/pop/친구초대 현황-box.png';
const logo = cdnImg + 'logo.png';

export default function FDMenu(props) {
  // console.log("props: ", props);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isReward, setIsReward] = useState(false);
  // const history = useHistory();
  const { user } = useLoginContext();
  const [cookies, setCookie] = useCookies();

  const toggleDrawer = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onGift = () => {
    if (user.id) {
      setIsReward(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setIsLogin(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (cookies) {
      setCookie('isRewardClicked', 'true');
    }
  };

  const [activePage, setActivePage] = useState();

  useEffect(() => {
    let tempActivePage = props.activePage;
    if (props.menuData[tempActivePage]?.sub) {
      props.menuData.forEach((menu, idx) => {
        if (menu.main) tempActivePage = idx;
      });
      // tempActivePage = 3;
    }
    setActivePage(tempActivePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activePage]);

  return (
    <div>
      {!isMobile ? (
        <div
          className="z-50 fixed flex justify-center w-full text-2xl p-2 "
          style={{
            backgroundColor: '#fff',
            justifyContent: isMobile ? 'space-between' : 'center',
          }}

          // style={{ height: props.height, backgroundColor: "#000000B4" }}
        >
          <Button onClick={onGift}>
            <img src={btGift} alt="보상함" width={30} />
          </Button>

          <div className="flex m-auto cursor-pointer">
            {props.menuData.map((menu, idx) => {
              if (!menu.sub)
                return (
                  <div
                    className="px-2 md:px-6"
                    key={idx}
                    style={{
                      borderRight: '1px solid #b0b0b0',
                      borderLeft: idx === 0 ? '1px solid #b0b0b0' : 0,
                      backgroundImage:
                        activePage === idx
                          ? `url(${bgLeft}),url(${bgRight}) `
                          : 'none',
                      backgroundPosition: '15px 10%,90% 70%',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '20px,15px',
                    }}
                  >
                    <LinkButton
                      // variant="contained"
                      style={{
                        fontWeight: activePage === idx ? 700 : 500,
                        color: activePage === idx ? '#f4aa2f' : '#565656',
                        whiteSpace: 'pre',
                      }}
                      onClick={() => {
                        props.setActivePage(idx);
                        setCookie('isRevisit', 'true');
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                      }}
                    >
                      {menu.name}
                    </LinkButton>
                  </div>
                );
              else return null;
            })}
          </div>
        </div>
      ) : (
        <div>
          <div
            className="z-50 fixed flex justify-center w-full text-2xl"
            style={{
              backgroundColor: '#fff',
              justifyContent: isMobile ? 'space-between' : 'center',
            }}

            // style={{ height: props.height, backgroundColor: "#000000B4" }}
          >
            <Button>
              <img
                src={btMenu}
                alt="보상함"
                width={30}
                onClick={toggleDrawer}
                className="py-2"
              />
            </Button>
            <div className="flex item-center justify-center -space-x-2">
              <FDSNS />
            </div>
          </div>
          <SwipeableDrawer
            anchor={'left'}
            open={isMenuOpen}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
          >
            <div onClick={toggleDrawer} className="h-full bg-black ">
              <div className="flex flex-col items-center justify-between h-full m-auto cursor-pointer">
                <div className="absolute top-2 right-0">
                  <Button onClick={toggleDrawer}>
                    <img src={btClose} alt="닫기" width={30} />
                  </Button>
                </div>
                <div className="flex flex-col items-center mt-8">
                  {props.menuData.map((menu, idx) => {
                    if (!menu.sub)
                      return (
                        <div
                          className="px-2 md:px-6 py-2 text-center"
                          key={idx}
                          style={
                            {
                              // width: "400px",
                            }
                          }
                        >
                          <LinkButton
                            // variant="contained"
                            style={{
                              fontWeight: activePage === idx ? 700 : 500,
                              color: activePage === idx ? '#f4aa2f' : '#fff',
                              whiteSpace: 'pre',
                              fontSize: 20,
                            }}
                            onClick={() => {
                              props.setActivePage(idx);
                              setCookie('isRevisit', 'true');
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                          >
                            <span
                              className="px-6"
                              style={{
                                backgroundImage:
                                  activePage === idx
                                    ? `url(${bgBall}),url(${bgBall}) `
                                    : 'none',
                                backgroundPosition: '0%,100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: '11px',
                              }}
                            >
                              {menu.name}
                            </span>
                          </LinkButton>
                        </div>
                      );
                    else return null;
                  })}
                  <Button onClick={onGift}>
                    <img src={btCoupon} alt="쿠폰함" width={300} />
                  </Button>
                </div>
                <img src={bgMenu} alt="메뉴" width={400} />
              </div>
            </div>
          </SwipeableDrawer>
        </div>
      )}
      {isLogin && (
        <LoginPop setIsLogin={setIsLogin}>
          {'보상함을 확인하기 위해\n창유 로그인이 필요합니다.'}
        </LoginPop>
      )}
      {isReward && <RewardPop setIsReward={setIsReward} />}
    </div>
  );
}

export const LoginPop = ({ setIsLogin, children }) => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return (
    <div
      className="absolute z-50 flex h-full w-full "
      style={{ backgroundColor: '#000000dd' }}
    >
      <div
        className="m-auto w-192 text-white font-bold p-2 flex flex-col  bg-cover  sm:pl-32 pb-8 sm:pb-16"
        style={{
          backgroundImage: `url(${isMobile ? bgLoginMo : bgLogin})`,
          backgroundPosition: 'center',
          marginTop: isMobile ? 0 : 'auto',
          border: isMobile ? '0' : '4px solid #f4aa2f',
          width: isMobile ? '100%' : 410,
          height: isMobile ? window.innerHeight : 'auto',
        }}
      >
        <div className="flex justify-end items-start w-full">
          <Button onClick={() => setIsLogin(false)} className="">
            <img src={btClose} alt="close" width={33} className="-mr-4" />
          </Button>
        </div>
        <pre className="text-center text-lg font-normal mb-4 mt-8">
          {children}
        </pre>
        <div className="flex justify-center">
          <Button style={{ padding: 0 }}>
            <img
              src={btLogin}
              alt="로그인하기"
              width={150}
              onClick={() => history.push('/login')}
            />
          </Button>
          <Button style={{ padding: 0 }} onClick={() => setIsLogin(false)}>
            <img src={btCancel} alt="취소" width={150} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const RewardPop = ({ setIsReward }) => {
  const { user } = useLoginContext();
  const { event, setEvent } = useEventContext();
  const [rewards, setRewards] = useState();
  const { enqueueSnackbar } = useSnackbar();
  // console.log("event: ", event);
  const makeRewards = (data) => {
    let tempRewards = [];
    console.log('event: ', data);
    if (data.testResult) {
      if (data.couponPT) {
        tempRewards.push({ ...rewardData.positionAll, coupon: data.couponPT });
      } else {
        tempRewards.push(rewardData.positionAll);
      }
    }
    if (data.couponPT2) {
      tempRewards.push({ ...rewardData.position30, coupon: data.couponPT2 });
    }
    if (data.referPhones) {
      let rewardType;
      if (data.referPhones.length >= 1) rewardType = 'invite1';
      if (data.referPhones.length >= 3) rewardType = 'invite2';
      if (data.referPhones.length >= 5) rewardType = 'invite3';
      if (data.couponRF)
        rewardType &&
          tempRewards.push({
            ...rewardData[rewardType],
            coupon: data.couponRF,
          });
      else rewardType && tempRewards.push(rewardData[rewardType]);
    }
    if (data.cafe) {
      let rewardType;
      if (data.cafe >= 3000) rewardType = 'cafe1';
      if (data.cafe >= 5000) rewardType = 'cafe2';
      if (data.cafe >= 10000) rewardType = 'cafe3';
      if (data.cafe >= 20000) rewardType = 'cafe4';
      rewardType && tempRewards.push(rewardData[rewardType]);
    }
    if (data.like) {
      let rewardType;
      if (data.like >= 300000) rewardType = 'like1';
      if (data.like >= 500000) rewardType = 'like2';
      if (data.like >= 1000000) rewardType = 'like3';
      if (data.like >= 2000000) rewardType = 'like4';
      rewardType && tempRewards.push(rewardData[rewardType]);
    }
    setRewards(tempRewards);
  };
  useEffect(() => {
    if (user.id)
      getEvent(user.id)
        .then((res) => {
          setEvent(res.data);
          makeRewards({ ...event, ...res.data });
        })
        .catch((err) => {
          console.error('no event data', err);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className="absolute z-50 flex h-full w-full flex-col"
      style={{ backgroundColor: '#000000dd', height: window.innerHeight }}
    >
      <div className="mx-auto h-full sm:h-auto sm:m-auto w-192 flex flex-col items-center">
        <div
          className="relative h-full sm:h-auto text-black font-bold  flex flex-col sm:justify-center items-center bg-cover py-8 px-4 sm:px-24"
          style={{
            backgroundImage: `url(${bgReward})`,
            width: 'inherit',
          }}
        >
          <div className="bg-black absolute top-0 right-0">
            <IconButton
              onClick={() => setIsReward(false)}
              style={{ padding: 0 }}
            >
              <img src={btClose} alt="close" width={38} className="" />
            </IconButton>
          </div>
          <img src={logo} width={180} alt="logo" />
          <p className="text-xl mt-5 mb-1">
            <b style={{ color: '#f4aa2f' }}>{user.nickname}</b>님 반갑습니다!
          </p>
          <Divider className="w-full " style={{ backgroundColor: '#666' }} />
          <div className="relative mt-3">
            <img src={bgRewardCoupon} alt="보상함" width={320} />
            <div className="absolute top-7" style={{ left: 138 }}>
              <span>보상함</span>
            </div>
          </div>
          {rewards &&
            rewards.map((reward, idx) => (
              <div key={idx}>
                <div
                  className="py-1 mt-1 border-4 text-center bg-white"
                  style={{ borderColor: '#f4aa2f', width: 320 }}
                >
                  <p style={{ color: '#666' }}>{reward.title}</p>
                  <p
                    className="text-xs py-1 font-normal"
                    style={{ color: '#f4aa2f' }}
                  >
                    {reward.reward}
                  </p>
                  {reward.coupon && (
                    <CopyToClipboard
                      text={reward.coupon ?? reward.method}
                      onCopy={() => {
                        enqueueSnackbar(
                          '쿠폰번호가 복사되었습니다. 입력창에 붙여넣기 하세요.',
                          {
                            variant: 'success',
                          }
                        );
                      }}
                    >
                      <span
                        className="text-sm px-2"
                        style={{ backgroundColor: '#f4aa2f' }}
                      >
                        {reward.coupon ? '쿠폰 번호: ' : '방법: '}

                        <span className="font-normal text-white">
                          {reward.coupon ?? reward.method}
                        </span>
                      </span>
                    </CopyToClipboard>
                  )}
                </div>
              </div>
            ))}
          <div className="mb-2"></div>
          <Divider className="w-full  " style={{ backgroundColor: '#666' }} />
          <div className="relative mt-3">
            <img src={bgRewardInvite} alt="친구초대" width={320} />
            <div className="absolute top-7" style={{ left: 125 }}>
              <span>친구 초대 현황 : </span>
              <span className="text-white">
                {event.referPhones?.length ?? 0}
              </span>
              <span>명</span>
            </div>
          </div>
        </div>

        <div className="hidden sm:flex bg-black w-full justify-center">
          <FDSNS type="pc" />
        </div>
      </div>
    </div>
  );
};
const rewardData = {
  positionAll: {
    title: '<포지션 테스트 공유 이벤트 보상>',
    reward: '300다이아, 40,000골드, 공통 스킬 훈련서 20개',
    method: '출시 일주일 전 발송 예정',
  },
  position30: {
    title: '<포지션 테스트 추첨 보상>',
    reward: '500플카, 50,000골드, 1,500다이아',
    method: '출시 일주일 전 발송 예정',
  },
  invite1: {
    title: '<친구초대 이벤트 보상 - 1명>',
    reward: '200다이아, 20,000골드, 대웅 룰렛권 1개',
    method: '출시 일주일 전 발송 예정',
  },
  invite2: {
    title: '<친구초대 이벤트 보상 - 3명>',
    reward: '600다이아, 60,000골드, 대웅 룰렛권 3개',
    method: '출시 일주일 전 발송 예정',
  },
  invite3: {
    title: '<친구초대 이벤트 보상 - 5명>',
    reward: '1,000다이아, 100,000골드, 대웅 룰렛권 5개',
    method: '출시 일주일 전 발송 예정',
  },
  like1: {
    title: '<좋아요 이벤트 - 30만 달성>',
    reward: '에너지 음료X3',
    method: '인게임 우편 발송',
  },
  like2: {
    title: '<좋아요 이벤트 - 50만 달성>',
    reward: '공통 진급 카드X5',
    method: '인게임 우편 발송',
  },
  like3: {
    title: '<좋아요 이벤트 - 100만 달성>',
    reward: '공통 스킬 훈련서X5',
    method: '인게임 우편 발송',
  },
  like4: {
    title: '<좋아요 이벤트 - 200만 달성>',
    reward: '10,000골드',
    method: '인게임 우편 발송',
  },
  cafe1: {
    title: '<공식카페 가입 이벤트 - 3,000 달성>',
    reward: '10,000골드, 에너지 음료 X 5',
    method: '인게임 우편 발송',
  },
  cafe2: {
    title: '<공식카페 가입 이벤트 - 5,000 달성>',
    reward: '공통 스킬 훈련서X10, 공통 진급 카드X10',
    method: '인게임 우편 발송',
  },
  cafe3: {
    title: '<공식카페 가입 이벤트 - 10,000 달성>',
    reward: '100다이아, 20,000골드, 다이아 룰렛권 3개',
    method: '인게임 우편 발송',
  },
  cafe4: {
    title: '<공식카페 가입 이벤트 - 20,000 달성>',
    reward: '200다이아,40,000골드, 다이아 룰렛권 5개',
    method: '인게임 우편 발송',
  },
};

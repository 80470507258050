import { Button } from "@material-ui/core";
// import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { cdnImg } from "../../../config/etc";
import { usePopContext } from "../../../contexts/PopContext";

// const bgPop = cdnImg + "bg_popup.jpg";
const bgPop = cdnImg + "PC-팝업수정-사이즈업.jpg";
const btConfirm = cdnImg + "팝업-확인.png";
const bt_close = cdnImg + "bt_close.png";

export default function PopUp({ popup }) {
	// const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	const [isClose, setIsClose] = useState();
	const { setPopup } = usePopContext();
	const onClose = () => {
		setIsClose(true);
		setPopup({ text: "" });
	};
	useEffect(() => {
		if (popup?.text) setIsClose(false);
	}, [popup?.text]);

	if (!popup?.text || isClose) return null;
	return (
		<div
			className="absolute top-0 left-0 bottom-0 right-0 z-50 flex"
			style={{ backgroundColor: "#000000aa" }}
		>
			<div
				className="m-auto bg-cover"
				style={{
					backgroundImage: `url(${bgPop})`,
					backgroundPosition: "center",
				}}
			>
				<div
					className="relative flex flex-col justify-center items-center  text-white font-bold border-4 border-yellow-500 bg-cover p-2"
					style={{
						minHeight: 100,
						minWidth: 650,
					}}
				>
					<div className="absolute -top-12 -right-12">
						<Button onClick={onClose} className="">
							<img src={bt_close} alt="close" width={33} className="-mr-4" />
						</Button>
					</div>

					<div className="flex flex-col items-center ">
						{/* <Button variant="contained">내 결과 공유하기</Button> */}
						<pre
							className="text-center text-black text-sm sm:text-2xl py-2 px-12 whitespace-pre"
							// style={{ fontSize: 23 }}
						>
							{popup.text}
						</pre>
						<Button onClick={onClose} className="">
							<img src={btConfirm} alt="확인" width={150} />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

import React, { useState } from "react";
import { useLoginContext } from "../../contexts/LoginContext";
import { Button } from "@material-ui/core";
import { signOut, logout } from "../../service/Auth";
import { Form } from "react-final-form";
import createDecorator from "final-form-focus";
import { motion } from "framer-motion";
import { aniContainer, aniItem } from "../../config/animation";
import { useSnackbar } from "notistack";
import GoBack from "../components/GoBack";
import { useHistory } from "react-router-dom";
import CheckAdapter from "../components/CheckAdapter";
import InputAdapter from "../components/InputAdapter";
import { composeValidators, requiredValidator } from "../../utils/validator";
import Analytics from "../../service/Analytics";

const SignOut = (props) => {
	const { user, removeUser } = useLoginContext();
	const { enqueueSnackbar } = useSnackbar();
	const [withdrawOpen] = useState(false);
	const [agree_withdraw, setAgree_withdraw] = useState(false);
	const history = useHistory();

	const cash = user.point_cash ?? 0;
	const fee = cash * 0.1 > 1000 ? user.point_cash * 0.1 : 1000;
	const withdrawAmount = cash - fee;

	function localNumber(value) {
		return new Intl.NumberFormat("ko-KR").format(value ?? 0);
	}

	function onSubmit(values) {
		let data = JSON.parse(JSON.stringify(values));
		if (withdrawOpen) {
			data.fee = fee;
			data.withdrawAmount = withdrawAmount;
		}
		signOut(user.id, data).then((res) => {
			enqueueSnackbar(
				"회원 탈퇴가 완료되었습니다. 그동안 이용해 주셔서 감사합니다.",
				{
					variant: "success",
				}
			);
			history.push("/");
			Analytics.onLogout(user);
			Analytics.logEvent("signOut", { withdraw: withdrawOpen });
			removeUser();
			logout();
		});
	}
	const focusOnError = createDecorator();
	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className=" p-4 sm:mt-4"
		>
			<motion.div variants={aniItem} className="sm:hidden">
				<GoBack isMember={true} setTab={props.setTab} />
			</motion.div>
			<motion.h1
				variants={aniItem}
				className="font-bold text-xl text-purple-600 mb-6"
			>
				회원 탈퇴
			</motion.h1>
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors, values }) => (
					<form onSubmit={handleSubmit} className="flex flex-col mb-4">
						<>
							<motion.div variants={aniItem}>
								<ul className="sign_out_ul text-sm">
									<li>
										회원 탈퇴 신청 후에는 로그인이 불가하며, 게임 및 웹서비스
										이용이 불가능합니다.
									</li>
									<li>
										회원 탈퇴 완료 후 회원 정보 등 개인정보는 모두 삭제되며,
										복구가 불가능합니다.
									</li>
									<li>
										회원 탈퇴 완료 후 해당 계정의 개인정보는 모두 삭제되며,
										삭제된 데이터는 복구되지 않습니다.
									</li>
									<li>
										회원 탈퇴 완료 후에는 해당 계정 및 캐릭터, 아이템 등을 다시
										이용할 수 없으니 신중하게 결정하시기 바랍니다.
									</li>
									<li>
										회원 탈퇴 완료 후 해당 계정이 보유하고 있는 포인트는 모두
										삭제되어 이용할 수 없으니 잔여 포인트가 있는 경우 모두
										소진하거나, 탈퇴하기 전에 먼저 환불 요청 처리하시기 랍니다.
									</li>
									<li>
										회원 탈퇴 이전 환불은 캐시 포인트에만 해당하며 탈퇴 수수료
										10%를 제한 금액을 환불합니다. 수수료 10%가 가 1,000원 미만일
										경우 1,000원을 수수료로 제합니다. 1,000캐시 미만은 수수료
										초과로 환불되지 않습니다.
									</li>
									{/* <li>
										회원 탈퇴 신청 취소는 15일 이내에 가능합니다. 본인 계정을
										타인이 도용하여 신청했을 시 15일 이내에 이의 신청을 해주셔야
										합니다.
									</li> */}
								</ul>
							</motion.div>
							<motion.div variants={aniItem} className="flex my-4">
								<div className="flex-1 rounded bg-white p-2 mr-2 text-sm">
									잔여 캐시 포인트 :{" "}
									<b className="text-purple-600">
										{localNumber(cash)}
										포인트
									</b>
								</div>
								{/* <Button
										onClick={() => setWithdrawOpen(true)}
										variant="contained"
										// color="inherit"
										disabled={!user.point_cash || user.point_cash < 1000}
									>
										환불 요청
									</Button> */}
							</motion.div>
						</>
						{user.point_cash > 1000 && (
							<>
								<motion.div
									variants={aniItem}
									className="flex justify-between px-4 py-2 text-sm bg-white border border-gray-200 mb-2"
								>
									<b>잔여 금액</b>
									<b>{localNumber(cash)}</b>
								</motion.div>
								<motion.div
									variants={aniItem}
									className="flex justify-between px-4 py-2 text-sm bg-white border border-gray-200 mb-2"
								>
									<b>수수료</b>
									<b>{localNumber(fee)}</b>
								</motion.div>
								<motion.div
									variants={aniItem}
									className="flex justify-between px-4 py-2 text-sm bg-white border border-gray-200 mb-4"
								>
									<b>환불 금액</b>
									<b className="text-purple-600">
										{localNumber(withdrawAmount)}
									</b>
								</motion.div>
								<motion.div variants={aniItem} className="flex mb-2">
									<div className="w-28 mr-2 bg-white">
										<InputAdapter
											validator={composeValidators(requiredValidator)}
											spinner="true"
											name="bankName"
											type="select"
											title="은행명"
										/>
									</div>
									<div className="flex-1 bg-white">
										<InputAdapter
											validator={composeValidators(requiredValidator)}
											spinner="true"
											name="account"
											type="text"
											title="계좌번호"
										/>
									</div>
								</motion.div>
							</>
						)}
						<motion.div variants={aniItem} className=" mb-3">
							<CheckAdapter
								name="agree_withdraw"
								checked={agree_withdraw}
								onClick={(e) => {
									setAgree_withdraw(e.target.checked);
								}}
								label={
									<span className="text-sm">
										주의 사항을 모두 확인했으며, 동의합니다.
									</span>
								}
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								fullWidth={true}
								disabled={
									submitting ||
									Object.keys(errors).length !== 0 ||
									pristine ||
									errors.length > 0 ||
									!agree_withdraw
								}
							>
								{user.point_cash > 1000 && "환불 요청 및"} 회원 탈퇴 신청
							</Button>
						</motion.div>
					</form>
				)}
			/>
		</motion.div>
	);
};

export default SignOut;

import React, { useEffect, useState } from "react";
import Router from "../router/Router";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import textBubble from "../assets/textBubble.png";
import { motion } from "framer-motion";
import { readDatas } from "../service/Content";
import Analytics from "../service/Analytics";
import { gaTraceId, kakaoPixelId } from "../config/firebase";

const etcPathArr = [
	"/login",
	"/register",
	"/Oauth",
	"/agreeTerms",
	"/snsConnect",
	"/nickModify",
	"/deleteFB",
	"/forgotPassword",
	"/resetPassword",
	"/emailAuth",
];

export default function Layout() {
	const location = useLocation();
	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies();
	const [supporter, setSupporter] = useState();
	const [isHeader, setIsHeader] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0);

		if (
			!etcPathArr.includes(location.pathname) &&
			!location.pathname.includes("resetPassword")
		) {
			setCookie("from", location.pathname);
		}

		Analytics.setCurrentScreen(location.pathname);
		const { title } = window.document;
		const { href, pathname } = window.location;

		// console.log("window.kakaoPixel: ", window.kakaoPixel);
		if (window.kakaoPixel) {
			window.kakaoPixel(kakaoPixelId).pageView(pathname + " 페이지 방문");
		} else {
			console.log("애드블록");
		}

		window.gtag("config", gaTraceId, {
			page_title: title,
			page_location: href,
			page_path: pathname,
		});

		if (
			[
				"/flyingdunk2/",
				"/flyingdunk2",
				"/position/center",
				"/position/powerForward",
				"/position/smallForward",
				"/position/pointGuard",
				"/position/shootingGuard",
				"/position/center/",
				"/position/powerForward/",
				"/position/smallForward/",
				"/position/pointGuard/",
				"/position/shootingGuard/",
			].includes(location.pathname)
		) {
			setIsHeader(false);
			setSupporter(false);
			return;
		} else {
			setIsHeader(true);
		}
		readDatas("etc", {}).then((res) => {
			setSupporter(
				res.data?.data?.[0]?.isSupporter ? res.data?.data?.[0].supporter : false
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);
	return (
		<main className=" h-screen flex flex-col justify-between">
			<div id="topWrap">
				{isHeader && <Header />}
				<Router isHeader={isHeader} />
			</div>
			{isHeader && <Footer />}
			{supporter && (
				<motion.div
					initial={{ translateY: -200, opacity: 0 }}
					animate={{ translateY: 0, opacity: 1 }}
					transition={{ delay: 1 }}
					className="fixed right-4 bottom-4 flex items-center px-2 py-0 rounded shadow-md bg-purple-600 text-white text-xxs font-bold cursor-pointer"
					onClick={() => window.open(supporter, "_blank")}
				>
					<img src={textBubble} alt="서포터즈" className="w-5 mr-2" />
					<div className="flex flex-col items-center">
						<p>cyou</p>
						<p>서포터</p>
					</div>
				</motion.div>
			)}
		</main>
	);
}

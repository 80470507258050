// import { Card } from "@material-ui/core";
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
// import { useHistory } from "react-router-dom";
// import { useEventContext } from "../../../contexts/EventContext";
import { useLoginContext } from '../../../contexts/LoginContext';
import Analytics from '../../../service/Analytics';
import { getReferLink, setPreviewEvent } from '../../../service/Events';
import { LoginPop, RewardPop } from './FDMenu';
import { useMediaQuery } from 'react-responsive';
import { cdnImg } from '../../../config/etc';
import { BtScroll } from '../FDMain';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEventContext } from '../../../contexts/EventContext';

const bg = cdnImg + 'bg5-1.jpg';
const btUrl = cdnImg + 'pc/5-1/url발급버튼.png';
const btInvite = cdnImg + 'pc/5-1/초대한친구확인버튼.png';
const event1 = cdnImg + 'pc/5-1/이벤트01.png';
const reward = cdnImg + 'event1.png';
const rewardMo = cdnImg + 'event1Mo.png';
const btCopy = cdnImg + 'pc/4/copy-버튼.png';
const bgTest = cdnImg + 'bg_test.jpg';
const btGift = cdnImg + 'bt_gift.png';

export default function FDEventRefer(props) {
  const { user } = useLoginContext();
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState('');
  const [isLogin, setIsLogin] = useState();
  const [isReward, setIsReward] = useState();
  const [loginText, setLoginText] = useState();
  const { event } = useEventContext();
  const textUrl = useRef();
  // const { event } = useEventContext();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isBig = useMediaQuery({ query: '(min-height: 1040px)' });

  const onUrlIssue = () => {
    if (user.id) {
      getReferLink(user)
        .then((res) => {
          setUrl(res.data);
          const el = textUrl.current;
          el.select();
          document.execCommand('copy');
          enqueueSnackbar(
            'URL이 발급되었습니다. URL을 붙여넣어서 공유하세요.',
            {
              variant: 'success',
            }
          );
          setPreviewEvent({
            referUrl: res.data,
            uid: user.id,
            nickname: user.nickname,
          });
          Analytics.logEvent('friendInvite', { userId: user.id });
        })
        .catch((err) => {
          console.error('url발급 에러', err);
        });
    } else {
      console.log('로그인해야해', user);
      setLoginText('URL을 발급 받기 위해\n먼저 로그인해 주세요');
      setIsLogin(true);
      // history.push("/login");
    }
  };

  const onReward = () => {
    if (user.id) {
      setIsReward(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setLoginText('보상함 확인을 위해\n먼저 로그인해 주세요');
      setIsLogin(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div
      // className="p-4 h-full max-w-2xl m-auto flex flex-col items-center justify-center"
      style={{
        backgroundColor: '#000',
        backgroundImage: `url(${bg})`,
        // "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
        borderColor: '#f4aa2f',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: !isBig ? '100%' : window.innerHeight,
      }}
    >
      <div
        className="h-full m-auto flex flex-col items-center justify-start text-white px-2 sm:px-4 pt-14 pb-4 sm:py-16"
        style={{
          // height: isMobile ? "100%" : window.innerHeight,
          // paddingLeft: isMobile && status !== "main" ? 0 : 15,
          // paddingRight: isMobile && status !== "main" ? 0 : 15,
          color: '#402c00',
        }}
      >
        <div className="w-full flex flex-col items-center  mt-8 sm:mt-12">
          {/* <div>EVENT 01</div> */}
          <img
            src={event1}
            alt="이벤트1"
            width={isMobile ? 190 : 240}
            // className="mt-4 sm:mt-24"
          />
          <h1
            className="text-xl sm:text-2xl mt-4 sm:mt-8 font-700"
            style={{ fontWeight: 500 }}
          >
            친구 초대 이벤트!
          </h1>
          <div
            className="text-3xl sm:text-4xl mt-6 font-bold text-center "
            style={{ fontWeight: 900 }}
          >
            <h2>500만이 선택한 농구게임!</h2>
            <h2>“지금 친구랑 함께 농구 PVP를 즐겨보세요!”</h2>
          </div>
          {event.dateState > 0 ? (
            <div
              className="mt-12 eventCoverBox flex flex-col justify-between text-center "
              style={{
                background: `linear-gradient(#000c 0%,#000a 100%), center / 1000px no-repeat url(${bgTest})`,
              }}
            >
              <div
                className="p-4 sm:p-10 flex flex-col justify-center"
                style={{ minHeight: '18rem' }}
              >
                <h1>
                  <b>친구초대 이벤트 종료!</b>
                </h1>
                <h2 className="mt-8 flex items-center justify-center">
                  왼쪽 상단의
                  <Button onClick={onReward} style={{ padding: 0 }}>
                    <h2>
                      <b> &nbsp;보상함 아이콘</b>
                    </h2>
                    <img src={btGift} alt="보상함" width={isMobile ? 30 : 50} />
                  </Button>
                  을 눌러
                </h2>
                <h2>로그인 후, 보상을 확인해주세요.</h2>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-sm mt-6 sm:mb-2 text-center">
                아래 [내 URL 발급받기] 링크를 주변에 공유하세요!
                <br />
                {/* </div>
					<div className="text-center"> */}
                링크 공유를 통해 사전 예약하는 친구 초대가 완료될 시, 보상이
                지급됩니다.
              </div>
              <input
                type="text"
                value={url}
                ref={textUrl}
                readOnly
                className="w-1 opacity-0"
              ></input>
              <div className="flex flex-wrap items-center justify-center">
                <Button
                  onClick={onUrlIssue}
                  style={{ marginBottom: 8, padding: 0 }}
                >
                  <img src={btUrl} alt="내 URL 발급받기" width={330} />
                </Button>
                <Button
                  onClick={onReward}
                  style={{ marginBottom: 8, padding: 0 }}
                >
                  <img src={btInvite} alt="초대한 친구 확인하기" width={330} />
                </Button>
              </div>
              {url && (
                // <div className="bg-white p-2 w-sm rounded border-4 border-yellow-500 text-center">
                // 	{url}
                // </div>
                <div className="">
                  <CopyToClipboard
                    text={url}
                    onCopy={() => {
                      enqueueSnackbar(
                        '추천 URL이 복사되었습니다. Ctrl-v 로 붙여넣기 하세요.',
                        {
                          variant: 'success',
                        }
                      );
                    }}
                  >
                    <div className="flex items-center">
                      <img
                        src={btCopy}
                        alt="복사하기"
                        width={130}
                        className="z-10 cursor-pointer"
                      />
                      <div className="bg-white -ml-4 -mt-1 p-2 px-12 rounded border-4 border-yellow-500 text-center">
                        {url}
                      </div>
                    </div>
                  </CopyToClipboard>
                </div>
              )}
              <img
                src={isMobile ? rewardMo : reward}
                alt="친구초대 상품"
                width={900}
              />
              <span
                className="text-sm mt-6 text-center"
                style={{ backgroundColor: '#c8bca2BB' }}
              >
                * 본 보상은 누적 인원 기준이 아닌 최고 달성 인원 기준으로 1회
                지급됩니다. (중복 수령 불가)
              </span>
              <span
                className="text-sm text-center mb-2"
                style={{ backgroundColor: '#c8bca2BB' }}
              >
                * 본 보상은 사전예약 기간 종료 후 일괄 지급됩니다.
              </span>
            </div>
          )}
        </div>
        {isLogin && <LoginPop setIsLogin={setIsLogin}>{loginText}</LoginPop>}
        {isReward && <RewardPop setIsReward={setIsReward} />}
        <BtScroll setActivePage={props.setActivePage} />
      </div>
    </div>
  );
}
// const marks = [
// 	{
// 		value: 1,
// 		label: "1명",
// 		img: "https://placehold.it/100x100",
// 		reward: "reward1",
// 	},
// 	{
// 		value: 3,
// 		label: "3명",
// 		img: "https://placehold.it/100x100",
// 		reward: "reward2",
// 	},
// 	{
// 		value: 5,
// 		label: "5명",
// 		img: "https://placehold.it/100x100",
// 		reward: "reward3",
// 	},
// 	{
// 		value: 10,
// 		label: "10명",
// 		img: "https://placehold.it/100x100",
// 		reward: "reward4",
// 	},
// ];

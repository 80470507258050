import React, { useCallback } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Link,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { motion } from "framer-motion";

const NoticeList = ({ game }) => {
	const decorateArticle = useCallback((article) => {
		if (!article) return;
		let tag = article.substring(article.indexOf("[") + 1, article.indexOf("]"));
		let content = article.substring(article.indexOf("]") + 1).trim();
		return (
			<>
				<span
					className="w-14 text-center inline-block rounded border text-xs mr-2  "
					style={{ padding: "0.1rem 0" }}
				>
					{tag || "공지"}
				</span>
				<span className=" text-xs xl:text-sm ">{content}</span>
			</>
		);
	}, []);
	const container = {
		visible: {
			transition: {
				delayChildren: 0.6,
				staggerChildren: 0.1,
			},
		},
	};
	const item = {
		hidden: { y: -20, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	};
	return (
		<div className="w-full sm:h-31 overflow-hidden hover:overflow-auto">
			<div
				className="flex items-center sm:hidden border border-gray-300 px-1 py-1"
				style={{ paddingTop: "0.1rem" }}
			>
				<Accordion className="w-full article " elevation={0}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<div className="w-full">
							<Link
								className="truncate block"
								href={game.list[0]?.url}
								color="inherit"
								target="_blank"
								rel="noreferrer"
							>
								{decorateArticle(game.list[0]?.title)}
							</Link>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div className="w-full">
							{game.list?.map((article, idx) => {
								if (idx !== 0) {
									return (
										<Link
											key={article.title + idx}
											className="truncate block"
											href={article.url}
											color="inherit"
											target="_blank"
											rel="noreferrer"
										>
											{decorateArticle(article.title)}
										</Link>
									);
								}
							})}
						</div>
					</AccordionDetails>
				</Accordion>
			</div>

			<motion.div
				variants={container}
				initial="hidden"
				animate="visible"
				className="hidden sm:block"
			>
				{game.list?.map((article) => (
					<motion.div variants={item} key={article.title}>
						<Link
							className="truncate block"
							href={article.url}
							color="inherit"
							target="_blank"
							rel="noreferrer"
						>
							{decorateArticle(article.title)}
						</Link>
					</motion.div>
				))}
			</motion.div>
		</div>
	);
};

export default NoticeList;

import React from "react";
import RegisterBox from "./auth/RegisterBox";
import SNSBox from "./auth/SNSBox";
import LoginRedirect from "./components/LoginRedirect";
import LoginDivider from "./components/LoginDivider";
import GoBack from "./components/GoBack";

const Register = () => {
	return (
		<div className="m-auto max-w-lg p-4 mt-4">
			<LoginRedirect />
			<GoBack />
			<h1 className="font-bold text-lg text-purple-600">회원가입</h1>
			<RegisterBox />
			<LoginDivider isRegister={true} />
			<SNSBox isLogin={false} />
		</div>
	);
};

export default Register;

import React from "react";
import { Field } from "react-final-form";
import Spinner from "./Spinner";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
	root: {
		// "& label.Mui-focused": {
		// 	color: "green",
		// },
		"& .MuiInputBase-input": {
			font: "-webkit-control",
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
		},
		"& .MuiInputLabel-outlined": {
			transform: "translate(14px, 10px) scale(.85)",
		},
		"& .MuiOutlinedInput-input": {
			paddingTop: "9px",
			paddingBottom: "9px",
		},
	},
})(TextField);

// const useStyles = makeStyles();

const InputAdapter = (props) => {
	const {
		validator,
		name,
		title,
		spinner,
		initialValue,
		focused,
		disabled,
		type = "text",
		inputProps,
		onInput,
	} = props;
	return (
		<Field name={name} validate={validator} initialValue={initialValue}>
			{({ input, meta, ...rest }) => (
				<div className="w-full relative">
					<CssTextField
						{...input}
						{...rest}
						label={title}
						type={type}
						disabled={disabled}
						onInput={onInput}
						inputProps={inputProps}
						autoFocus={focused}
						variant="outlined"
						fullWidth
						onChange={(event, value) => input.onChange(event.target.value)}
						error={meta.touched && (!!meta.error || !!meta.submitError)}
						helperText={meta.touched ? meta.error || meta.submitError : ""}
					/>
					{meta.validating && spinner && <Spinner absolute={true} />}
				</div>
			)}
		</Field>
	);
};

export default InputAdapter;

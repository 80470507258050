import React from "react";
import { useHistory } from "react-router-dom";
import Meta from "../../../pages/components/Meta";
import { Button } from "@material-ui/core";
import { cdnImg } from "../../../config/etc";
import { useMediaQuery } from "react-responsive";
const btGo = cdnImg + "position_test_go.png";
const back_q = cdnImg + "back_q.png";

export default function ShootingGuard() {
	const history = useHistory();
	const position = "sg";
	const image = cdnImg + `position_big_${position}.jpg`;
	const imageMo = cdnImg + `position_${position}.jpg`;
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	return (
		<div
			className="flex flex-col items-center justify-center h-screen w-full bg-no-repeat bg-cover px-3"
			style={{
				backgroundImage: `url(${back_q})`,
			}}
		>
			<Meta
				data={{
					title:
						"[프리스타일2 레볼루션 플라잉덩크] 나에게 어울리는 농구 포지션은? : 슈팅가드",
					description:
						"정확한 슛 능력과, 다양한 역할을 수행해야 하는 슈팅가드! 높은 집중력을 가진 다재다능한 당신에게 추천합니다.",
					image,
					width: "1200",
					height: "628",
				}}
			/>
			<img
				src={isMobile ? imageMo : image}
				alt={position}
				className="mb-8"
				width={800}
			/>
			<Button
				onClick={() =>
					history.replace(`/flyingdunk2?positionShare=${position}`)
				}
			>
				<img src={btGo} alt="포지션 테스트 하러 가기" />
			</Button>
		</div>
	);
}

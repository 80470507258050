import React, { createContext, useContext, useState } from 'react';

const Context = createContext({
  setEvent: () => {},
  event: { dateState: 0 },
});
export const useEventContext = () => useContext(Context);

const EventContextProvider = ({ children }) => {
  const setEvent = (data) => {
    setState((prevState) => {
      return {
        ...prevState,
        event: { ...prevState?.event, ...data },
      };
    });
  };

  const defaultState = {
    setEvent,
    event: {},
  };

  const initialState = {
    ...defaultState,
  };

  const [state, setState] = useState(initialState);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};
export default EventContextProvider;

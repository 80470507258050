import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { cdnImg } from "../../../config/etc";
import { BtScroll } from "../FDMain";
import CharacterPositionDetail from "./CharacterPositionDetail";

const bg = cdnImg + "bg6.jpg";
const char1 = cdnImg + "pc/6/비티니-활성.png";
const char2 = cdnImg + "pc/6/브릿지릴리-활성.png";
const char3 = cdnImg + "pc/6/페드로-활성.png";
const char4 = cdnImg + "pc/6/정훈-활성.png";
const char5 = cdnImg + "pc/6/BANG-활성.png";
const charDis1 = cdnImg + "pc/6/비티니-비활성.png";
const charDis2 = cdnImg + "pc/6/브릿지릴리-비활성.png";
const charDis3 = cdnImg + "pc/6/페드로-비활성.png";
const charDis4 = cdnImg + "pc/6/정훈-비활성.png";
const charDis5 = cdnImg + "pc/6/BANG-비활성.png";
const charSel1 = cdnImg + "char1.png";
const charSel2 = cdnImg + "char2.png";
const charSel3 = cdnImg + "char3.png";
const charSel4 = cdnImg + "char4.png";
const charSel5 = cdnImg + "char5.png";
const charSelMo1 = cdnImg + "charMo1.png";
const charSelMo2 = cdnImg + "charMo2.png";
const charSelMo3 = cdnImg + "charMo3.png";
const charSelMo4 = cdnImg + "charMo4.png";
const charSelMo5 = cdnImg + "charMo5.png";
const charBg1 = cdnImg + "charbg1.jpg";
const charBg2 = cdnImg + "charbg2.jpg";
const charBg3 = cdnImg + "charbg3.jpg";
const charBg4 = cdnImg + "charbg4.jpg";
const charBg5 = cdnImg + "charbg5.jpg";
const charBgMo1 = cdnImg + "charbgmo1.jpg";
const charBgMo2 = cdnImg + "charbgmo2.jpg";
const charBgMo3 = cdnImg + "charbgmo3.jpg";
const charBgMo4 = cdnImg + "charbgmo4.jpg";
const charBgMo5 = cdnImg + "charbgmo5.jpg";
const btChar1 = cdnImg + "pc/6/비티니-버튼-활성.png";
const btChar2 = cdnImg + "pc/6/브릿지릴리-버튼-활성.png";
const btChar3 = cdnImg + "pc/6/페드로-버튼-활성.png";
const btChar4 = cdnImg + "pc/6/정훈-버튼-활성.png";
const btChar5 = cdnImg + "pc/6/BANG-버튼-활성.png";
const btCharDis1 = cdnImg + "pc/6/비티니-버튼-비활성.png";
const btCharDis2 = cdnImg + "pc/6/브릿지릴리-버튼-비활성.png";
const btCharDis3 = cdnImg + "pc/6/페드로-버튼-비활성.png";
const btCharDis4 = cdnImg + "pc/6/정훈-버튼-비활성.png";
const btCharDis5 = cdnImg + "pc/6/BANG-버튼-비활성.png";

export default function CharacterPosition(props) {
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	const isBig = useMediaQuery({ query: "(min-height:1100px)" });
	const [activeChar, setActiveChar] = useState();
	const [selectedChar, setSelectedChar] = useState(-1);
	const onSelect = (idx) => {
		console.log("idx: ", idx);
		setSelectedChar(-2);
		setTimeout(() => {
			setSelectedChar(idx);
			window.scrollTo({ top: 0, behavior: "smooth" });
		}, 1);
	};
	return (
		<div>
			{selectedChar === -1 ? (
				<div
					// className="p-4 h-full max-w-2xl m-auto flex flex-col items-center justify-center"
					style={{
						backgroundColor: "#000",
						backgroundImage: `url(${bg})`,
						borderColor: "#f4aa2f",
						backgroundSize: "cover",
						backgroundPosition: "top center",
						backgroundRepeat: "no-repeat",
						backgroundAttachment: "fixed",
						height: !isBig ? "100%" : window.innerHeight,
					}}
				>
					<div className="h-full m-auto flex flex-col items-center justify-start text-white px-2 sm:px-0 pt-14 pb-4 sm:py-16">
						<div className="event2 w-full flex flex-wrap  md:flex-nowrap justify-center items-center px-2 mt-8 sm:mt-24 sm:px-24">
							{positionData.map((position, index) => (
								// <div key={index} style={{ bakcground: position.img }}>
								// 	<h3 className="text-xl font-bold ">{position.name}</h3>
								// 	<h4>{position.nick}</h4>
								// </div>
								<Button
									key={index}
									onClick={() => onSelect(index)}
									onMouseOver={() => setActiveChar(index)}
									style={{ padding: 0, margin: isMobile ? "3px -2px" : -4 }}
								>
									<img
										src={
											isMobile
												? position.img
												: index === activeChar
												? position.img
												: position.dis
										}
										alt={position.name}
										width={isMobile ? 110 : 250}
									/>
								</Button>
							))}
						</div>
						<BtScroll setActivePage={props.setActivePage} />
					</div>
				</div>
			) : (
				<CharacterPositionDetail
					selectedChar={selectedChar}
					setSelectedChar={setSelectedChar}
					onSelect={onSelect}
					setActivePage={props.setActivePage}
				/>
			)}
		</div>
	);
}

export const positionData = [
	{
		name: "포인트가드",
		nick: "농구장 엘프",
		img: char1,
		dis: charDis1,
		char: charSel1,
		charMo: charSelMo1,
		charBg: charBg1,
		charBgMo: charBgMo1,
		btChar: btChar1,
		btCharDis: btCharDis1,
	},
	{
		name: "파워포워드",
		nick: "덩크 여왕",
		img: char2,
		dis: charDis2,
		char: charSel2,
		charMo: charSelMo2,
		charBg: charBg2,
		charBgMo: charBgMo2,
		btChar: btChar2,
		btCharDis: btCharDis2,
	},
	{
		name: "센터",
		nick: "골밑의 패왕",
		img: char3,
		dis: charDis3,
		char: charSel3,
		charMo: charSelMo3,
		charBg: charBg3,
		charBgMo: charBgMo3,
		btChar: btChar3,
		btCharDis: btCharDis3,
	},
	{
		name: "스몰포워드",
		nick: "미들슛의 왕자",
		img: char4,
		dis: charDis4,
		char: charSel4,
		charMo: charSelMo4,
		charBg: charBg4,
		charBgMo: charBgMo4,
		btChar: btChar4,
		btCharDis: btCharDis4,
	},
	{
		name: "슈팅가드",
		nick: "3점 저격수",
		img: char5,
		dis: charDis5,
		char: charSel5,
		charMo: charSelMo5,
		charBg: charBg5,
		charBgMo: charBgMo5,
		btChar: btChar5,
		btCharDis: btCharDis5,
	},
];

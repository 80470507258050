import React from 'react';
import { Button } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
// import Analytics from '../../../service/Analytics';
import { cdnImg } from '../../../config/etc';
import { BtScroll } from '../FDMain';
import { motion } from 'framer-motion';
import { useEventContext } from '../../../contexts/EventContext';

const bgTest = cdnImg + 'bg_test.jpg';
const bg = cdnImg + 'bg_kuroko.jpg';
const bg_mo = cdnImg + 'bg_kuroko_mo.jpg';
const logo = cdnImg + 'logo_fd2k.png';
const logo_kuroko = cdnImg + 'logo_kuroko.png';
const title = cdnImg + 'title_kuroko.png';
const title2 = cdnImg + 'slogan_kuroko.png';
const copy_kuroko = cdnImg + 'copy_kuroko.png';
const btGo = cdnImg + 'bt_kuroko.png';
const x = cdnImg + 'x.png';
const k1 = cdnImg + 'k1.png';
const k2 = cdnImg + 'k2.png';
const k3 = cdnImg + 'k3.png';
const k4 = cdnImg + 'k4.png';
const btDown = cdnImg + '지금다운로드하기-버튼.png';

export default function Kuroko(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  // const isBig = useMediaQuery({ query: '(min-height: 1300px)' });
  const { event } = useEventContext();

  return (
    <div
      style={{
        backgroundColor: '#000',
        backgroundImage: `url(${bgTest})`,
        borderColor: '#f4aa2f',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: isMobile ? '100%' : window.innerHeight,
      }}
    >
      <div className="h-full m-auto flex flex-col items-center justify-start text-white pb-4">
        <div className="h-full flex flex-col items-center mt-20">
          <div className="relative mt-20 sm:my-auto ">
            <div className="hidden sm:block absolute bottom-3 right-6 z-10">
              <img src={copy_kuroko} alt="copy_kuroko" width={330} />
            </div>
            <div className="absolute top-3 sm:top-6 bottom-6 left-0 right-0 flex flex-col justify-between items-center z-10">
              <div>
                <div
                  initial="hidden"
                  animate="visible"
                  className="flex items-center justify-center"
                >
                  <motion.img
                    initial={{ x: -140, opacity: 0 }}
                    animate={{ x: 0, opacity: 1, transition: { delay: 0.2 } }}
                    src={logo_kuroko}
                    alt="쿠로코농구 로고"
                    width={isMobile ? 130 : 270}
                  />
                  <motion.img
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1, transition: { delay: 0.6 } }}
                    src={x}
                    alt="X"
                    width={isMobile ? 25 : 50}
                    className={isMobile ? 'mx-2' : 'mx-6'}
                  />
                  <motion.img
                    initial={{ x: 140, opacity: 0 }}
                    animate={{ x: 0, opacity: 1, transition: { delay: 0.4 } }}
                    src={logo}
                    alt="플라잉덩크 레볼루션 로고"
                    width={isMobile ? 100 : 200}
                  />
                </div>
                <motion.img
                  initial={{ y: 50, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.1 },
                  }}
                  src={title2}
                  alt="쿠로코의 농구 콜라보 12월 15일 오픈!"
                  width={300}
                  className="sm:hidden mt-3"
                />
                <motion.img
                  initial={{ y: 50, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.6 },
                  }}
                  src={title}
                  alt="역대급 콜라보레이션!"
                  width={300}
                  className="sm:hidden mt-3"
                />
              </div>
              <div className="flex flex-col items-center justify-center">
                <motion.img
                  initial={{ y: 50, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.1 },
                  }}
                  src={title2}
                  alt="쿠로코의 농구 콜라보 12월 15일 오픈!"
                  width={400}
                  className="hidden sm:block"
                />
                <motion.img
                  initial={{ y: 50, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                    transition: { duration: 0.5, delay: 0.6 },
                  }}
                  src={title}
                  alt="역대급 콜라보레이션!"
                  width={400}
                  className="hidden sm:block mt-2"
                />
                {event.dateState > 0 ? (
                  <Button
                    onClick={() => {
                      window.open('https://fd2.onelink.me/fOvs/d7bedffe');
                    }}
                  >
                    <motion.img
                      initial={{ y: 50, opacity: 0 }}
                      animate={{
                        y: 0,
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.7 },
                      }}
                      src={btDown}
                      alt="지금 다운로드"
                      width={isMobile ? 200 : 360}
                    />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      props.goPage(1);
                    }}
                  >
                    <motion.img
                      initial={{ y: 50, opacity: 0 }}
                      animate={{
                        y: 0,
                        opacity: 1,
                        transition: { duration: 0.5, delay: 0.7 },
                      }}
                      src={btGo}
                      alt="지금 사전예약하기"
                      width={isMobile ? 200 : 360}
                    />
                  </Button>
                )}
              </div>
            </div>
            <div>
              <img
                src={isMobile ? bg_mo : bg}
                alt="쿠로코의 농구 캐릭터"
                width={2480}
              />
              <div className="hidden sm:flex items-end absolute bottom-0 left-0 right-0 ">
                <motion.img
                  src={k1}
                  initial={{ x: 20, y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.4 } }}
                  width={600}
                  className="min-w-0 min-h-0 object-contain"
                />
                <motion.img
                  src={k2}
                  initial={{ x: 0, y: -200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.7 } }}
                  width={600}
                  className="min-w-0 min-h-0 object-contain"
                />
                <motion.img
                  src={k3}
                  initial={{ x: 0, y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}
                  width={680}
                  className="min-w-0 min-h-0 object-contain"
                />
                <motion.img
                  src={k4}
                  initial={{ x: -40, y: -200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 1.3 } }}
                  width={600}
                  className="min-w-0 min-h-0 object-contain"
                />
              </div>
            </div>
          </div>

          <BtScroll setActivePage={props.setActivePage} />
          <div className="pt-12 h-4 w-4"></div>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import AES from "crypto-js/aes";
import { apiServer } from "../config/servers";

export const getCafeMemberCount = (cafeName) =>
	axios.get(apiServer + "/cafeId/" + cafeName);

export const getReferLink = (user) =>
	axios.get(apiServer + "/getReferUrl", {
		params: { uid: user.id, nickname: user.nickname },
	});

export const getEvent = (userId) =>
	axios.get(apiServer + "/getEvent/" + userId);

export const checkDuplicatedPhone = (phone) =>
	axios.get(apiServer + "/auth/checkDuplicated", {
		params: { field: "phone", value: phone, isEvent: true },
	});

export const getGallery = () => axios.get(apiServer + "/getGallery");
export const getEventSetting = () =>
	axios.get(apiServer + "/v1/fd2Setting/6146eb46ae3abfcb08c51330");

// Todo 사전예약저장 -> 쿠폰 지급?
// Todo -> 레퍼러 체크 -> 친구수 증가 -> 친구 쿠폰 지급
// ? 포지션 테스트 -> url 저장 -> 쿠폰 지급

// 네이버 카페 주소
// 문자 요금 충전
// 카카오 픽셀 트랙 ID
//

export const sendSMS = (phone, authNumber) => {
	const encrypted = AES.encrypt(authNumber.toString(), "rlarldnjs").toString();
	console.log("encrypted: ", encrypted);
	return axios.post(apiServer + "/sendAuthSMS", {
		phone,
		authNumber: encrypted,
	});
};

export const setPreviewRegist = (data) => {
	return axios.post(apiServer + "/setPreviewRegist", data);
};
export const setPreviewEvent = (data) => {
	return axios.post(apiServer + "/setPreviewEvent", data);
};

import React, { useEffect, useState } from "react";
import { readDatas } from "../service/Content";
import _ from "../utils/@lodash";
import NoticeList from "./components/NoticeList";
import down1 from "../assets/down1.png";
import down2 from "../assets/down2.png";
import down3 from "../assets/down3.png";
import { Dialog, Divider, Link } from "@material-ui/core";
import GameSlider from "./components/GameSlider";
import { motion } from "framer-motion";
import {
	FacebookButton,
	TwitterButton,
	NaverBlogButton,
	KaKaoStoryButton,
	KaKaoTalkButton,
} from "react-social-kr";
import ShareIcon from "@material-ui/icons/Share";
import share_f from "../assets/share_f.png";
import share_k from "../assets/share_k.png";
import share_l from "../assets/share_l.png";
import share_n from "../assets/share_n.png";
import share_s from "../assets/share_s.png";
import share_t from "../assets/share_t.png";
import share_m_f from "../assets/share_m_f.png";
import share_m_k from "../assets/share_m_k.png";
import share_m_l from "../assets/share_m_l.png";
import share_m_n from "../assets/share_m_n.png";
import share_m_s from "../assets/share_m_s.png";
import share_m_t from "../assets/share_m_t.png";
import { SNS } from "../config/snsKeys";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";
import Meta from "./components/Meta";
import Analytics from "../service/Analytics";

const ShareImg = ({ mobileImg, pcImg, type }) => (
	<div className="cursor-pointer">
		<div className="sm:hidden flex flex-col items-center">
			<img src={mobileImg} alt={type} className="w-16 mb-1" />
			<span className="text-xxs">{type}</span>
		</div>
		<img src={pcImg} alt={type} className="hidden sm:block w-5 " />
	</div>
);

export const ShareButtons = ({
	message,
	media,
	pathname,
	enqueueSnackbar,
	clickShare,
	id,
}) => (
	<div className="flex">
		<CopyToClipboard
			text={window.location}
			onCopy={() => {
				enqueueSnackbar("주소가 복사되었습니다. Ctrl-v 로 붙여넣기 하세요.", {
					variant: "success",
				});
			}}
		>
			<div className="mr-2 sm:mr-1" onClick={() => clickShare("copyLink")}>
				<ShareImg
					mobileImg={share_l}
					pathname
					pcImg={share_m_l}
					type="링크복사"
				/>
			</div>
		</CopyToClipboard>
		<FacebookButton
			message={message}
			media={media}
			appId={SNS.key.facebook}
			pathname={pathname}
			className="mr-2 sm:mr-1"
			onClick={() => clickShare("facebook")}
		>
			<ShareImg mobileImg={share_f} pcImg={share_m_f} type="페이스북" />
		</FacebookButton>
		<TwitterButton
			message={message}
			media={media}
			className="mr-2 sm:mr-1"
			pathname={pathname}
			onClick={() => clickShare("twitter")}
		>
			<ShareImg mobileImg={share_t} pcImg={share_m_t} type="트위터" />
		</TwitterButton>
		<NaverBlogButton
			message={message}
			media={media}
			className="mr-2 sm:mr-1"
			pathname={pathname}
			onClick={() => clickShare("naver")}
		>
			<ShareImg mobileImg={share_n} pcImg={share_m_n} type="블로그" />
		</NaverBlogButton>
		<KaKaoStoryButton
			message={message}
			media={media}
			className="mr-2 sm:mr-1"
			pathname={pathname}
			onClick={() => clickShare("kakaoStory")}
		>
			<ShareImg mobileImg={share_s} pcImg={share_m_s} type="스토리" />
		</KaKaoStoryButton>
		{/* <MobileView> */}
		<KaKaoTalkButton
			id={id}
			message={message}
			media={media}
			jsKey={SNS.key.kakao}
			pathname={pathname}
			onClick={() => clickShare("kakao")}
		>
			<ShareImg mobileImg={share_k} pcImg={share_m_k} type="카카오톡" />
		</KaKaoTalkButton>
		{/* </MobileView> */}
	</div>
);

const Game = (props) => {
	const gameState = props.location.state;
	const pathname = props.location.pathname;
	const gameTitle = props.match?.params?.id?.replace(/-/g, " ");
	const [game, setGame] = useState(gameState);
	const [isShareOpen, setIsShareOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const container = {
		visible: {
			transition: {
				// delayChildren: 0.2,
				staggerChildren: 0.2,
			},
		},
	};
	const item = {
		hidden: { y: -40, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	};
	const item2 = {
		hidden: { x: 40, opacity: 0 },
		visible: {
			x: 0,
			opacity: 1,
			transition: { duration: 1 },
		},
	};

	const clickStore = (store) => {
		Analytics.logEvent("clickStore", { game: game.title, store });
	};
	const clickShare = (type) => {
		Analytics.logEvent("clickShare", { game: game.title, type });
	};

	useEffect(() => {
		setGame(gameState);
		if (!gameState) {
			readDatas("game", { where: { title: gameTitle } }).then((res) => {
				let gamesData = res.data?.data;
				// setGame(gamesData[0]);
				readDatas("cafenotice", {
					where: { cafe_id: gamesData[0].cafe_id },
				}).then((cafeRes) => {
					let cafesData = cafeRes.data?.data;
					let mergedData = cafesData.map((cafe) => {
						return _.merge(
							cafe,
							gamesData.filter((game) => game.cafe_id === cafe.cafe_id)[0]
						);
					});
					// const mergedData = _.mergeWith(cafesData, gamesData);
					setGame(mergedData[0]);
				});
			});
		}
		// return () => {};
	}, [gameTitle, gameState]);
	return (
		<>
			{game && (
				<div>
					<Meta
						data={{
							siteName: "cyou게임-" + gameTitle,
							title: gameTitle,
							description: game.slogan,
							image: game.mobile_img,
							width: 720,
							height: 720,
						}}
					/>
					<div className="relative">
						<motion.img
							initial={{ opacity: 0.7, filter: "grayscale(100%)" }}
							animate={{ opacity: 1, filter: "grayscale(0%)" }}
							transition={{
								type: "ease",
								duration: 3,
							}}
							src={game.home_img}
							alt={game.title}
							className="hidden sm:block z-1 w-full max-h-game object-cover"
						/>
						<motion.img
							initial={{ opacity: 0.7, filter: "grayscale(100%)" }}
							animate={{ opacity: 1, filter: "grayscale(0%)" }}
							transition={{
								type: "ease",
								duration: 3,
							}}
							src={game.mobile_img}
							alt={game.title}
							className="sm:hidden z-1 w-full max-h-game object-cover"
						/>
						<div className=" mx-auto h-full max-w-8xl bg-red-400">
							<motion.img
								initial={{
									opacity: 0,
									// transform: "translateY(-30%) scale(0)",
								}}
								animate={{
									opacity: 1,
									// transform: "translateY(-50%) scale(1)",
								}}
								transition={{
									type: "spring",
									delay: 1,
									duration: 1,
								}}
								src={game.logo_img}
								alt={game.title}
								className="game-logo z-10 absolute w-52 bottom-1/2 left-1/2 transform translate-y-full sm:translate-y-1/2 -translate-x-1/2 sm:w-70 sm:ml-6 sm:left-auto sm:translate-x-0"
								// style={{ transform: "translateY(-50%)" }}
							/>
							<motion.div
								variants={container}
								initial="hidden"
								animate="visible"
								className="game-sns z-10 absolute flex space-x-2 ml-2 bottom-0 sm:bottom-1/2 left-1/2 -translate-x-1/2 transform -translate-y-full sm:translate-y-32 sm:left-auto sm:translate-x-0 scale-150 sm:scale-100"
								// style={{ transform: "translateY(350%)" }}
							>
								<Link
									href={game.play_store_url}
									onClick={() => clickStore("playStore")}
									target="_blank"
								>
									<motion.img
										variants={item}
										src={down1}
										alt={game.title}
										className="w-24"
									/>{" "}
								</Link>
								<Link
									href={game.app_store_url}
									onClick={() => clickStore("appStore")}
									target="_blank"
								>
									<motion.img
										variants={item}
										src={down2}
										alt={game.title}
										className="w-24"
									/>
								</Link>
								<Link
									href={game.one_store_url}
									onClick={() => clickStore("oneStore")}
									target="_blank"
								>
									<motion.img
										variants={item}
										src={down3}
										alt={game.title}
										className="w-24"
									/>
								</Link>
							</motion.div>
						</div>
					</div>
					<motion.div
						variants={container}
						initial="hidden"
						animate="visible"
						className="py-4 sm:px-2 flex flex-col sm:flex-row-reverse mx-auto max-w-8xl justify-between"
					>
						<motion.div
							initial={{ opacity: 0, y: 50 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{
								type: "spring",
								duration: 1,
							}}
							className="mobile flex-1 px-4 mb-2 sm:px-0 sm:max-w-md sm:-mt-28 sm:pr-6 sm:ml-16"
						>
							<div className="hidden sm:block">
								<GameSlider game_id={game.id} dots="slick-dots-vertical" />
							</div>
							<div className="sm:hidden mb-8">
								<GameSlider game_id={game.id} dots="slick-dots" />
							</div>
							{game.list && <NoticeList game={game} />}
						</motion.div>
						<div className="flex-1 mt-6 sm:mt-0 p-4 bg-white sm:p-0 sm:bg-transparent">
							<motion.div
								variants={item2}
								className="font-bold text-lg mb-2 flex flex-wrap items-center justify-between relative"
							>
								<h2>{game.title}</h2>

								<div
									className="sm:hidden border border-gray-200 flex items-center justify-center cursor-pointer"
									onClick={() => setIsShareOpen(true)}
								>
									<ShareIcon />
								</div>
								<div className="hidden sm:flex items-center justify-center">
									<span className="text-xs mr-2">공유하기 </span>
									<div className=" flex items-center justify-center w-full sm:w-auto -mr-2 sm:mr-0 p-3 sm:p-0">
										<ShareButtons
											id="kakaoButton"
											message={game.title}
											media={game.home_img}
											pathname={pathname}
											clickShare={clickShare}
											enqueueSnackbar={enqueueSnackbar}
										/>
									</div>
								</div>
								<Dialog
									open={isShareOpen}
									onClose={() => setIsShareOpen(false)}
									className=""
								>
									<div className=" flex items-center justify-center w-full sm:w-auto -mr-2 sm:mr-0 p-3 sm:p-0">
										<ShareButtons
											id="kakaoButtonM"
											message={game.title}
											media={game.home_img}
											pathname={pathname}
											clickShare={clickShare}
											enqueueSnackbar={enqueueSnackbar}
										/>
									</div>
								</Dialog>
							</motion.div>
							<motion.span variants={item2} className="block text-sm mb-3">
								{game.slogan}
							</motion.span>
							<Divider />
							<motion.pre
								variants={item2}
								className="text-xs mt-3 whitespace-pre-wrap"
							>
								{game.desc}
							</motion.pre>
						</div>
					</motion.div>
				</div>
			)}
		</>
	);
};

export default Game;

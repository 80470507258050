// import { withStyles } from "@material-ui/core";
// import { Slider } from "@material-ui/core";
import { Button } from '@material-ui/core';
// import { Card } from "@material-ui/core";
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { cdnImg } from '../../../config/etc';
import { useEventContext } from '../../../contexts/EventContext';
import Analytics from '../../../service/Analytics';
import { BtScroll } from '../FDMain';
// import { useSnackbar } from "notistack";
// import { usePopContext } from "../../../contexts/PopContext";

const bg = cdnImg + 'bg5-3.jpg';
const title = cdnImg + 'pc/5-3/타이틀.png';
const titleMo = cdnImg + 'mo/5-3/타이틀.png';
const btCafe = cdnImg + 'mo/5-3/카페가입하기버튼.png';
const gauge = cdnImg + 'pc/5-3/게이지.png';
const gaugebg = cdnImg + 'pc/5-3/게이지-box.png';
const gaugebgMo = cdnImg + 'mo/5-3/게이지-box.png';
const reward3000 = cdnImg + 'pc/5-3/3000명경품.png';
const reward5000 = cdnImg + 'pc/5-3/5000명경품.png';
const reward10000 = cdnImg + 'pc/5-3/10000명경품.png';
const reward20000 = cdnImg + 'pc/5-3/20000명경품.png';
// const bgReward = cdnImg + "pc/5-3/경품-box.png";
const bgReward = cdnImg + '경품-box.jpg';
const bgRewardMo = cdnImg + 'mo/5-3/경품-box.png';
const ball = cdnImg + 'pc/5-3/게이지-농구공.png';
const bgTest = cdnImg + 'bg_test.jpg';

export default function FDEventCafe(props) {
  const { event } = useEventContext();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isBig = useMediaQuery({ query: '(min-height:1100px)' });
  // event.cafe = 1999;
  // const { setPopup } = usePopContext();

  // const { enqueueSnackbar } = useSnackbar();

  const onCafeGo = () => {
    window.open('https://cafe.naver.com/flyingdunk2', '_cafe');
    // const msg = "향후 오픈예정입니다. 기대해주세요!";
    // if (isMobile) {
    // 	enqueueSnackbar(msg, {
    // 		variant: "info",
    // 	});
    // } else {
    // 	setPopup({ text: msg });
    // }

    Analytics.logEvent('cafeVisit');
  };
  return (
    <div
      // className="p-4 h-full max-w-2xl m-auto flex flex-col items-center justify-center"
      style={{
        backgroundColor: '#000',
        backgroundImage: `url(${bg})`,
        // "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
        borderColor: '#f4aa2f',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: !isBig ? '100%' : window.innerHeight,
      }}
    >
      <div
        className="h-full m-auto flex flex-col items-center justify-start text-white px-2 sm:px-0 pt-14 pb-4 sm:py-16"
        style={{
          // height: isMobile ? "100%" : window.innerHeight,
          // paddingLeft: isMobile && status !== "main" ? 0 : 15,
          // paddingRight: isMobile && status !== "main" ? 0 : 15,
          color: '#000',
        }}
      >
        <div className="event2 w-full flex flex-col items-center px-0 mt-8 sm:mt-12">
          <img
            src={isMobile ? titleMo : title}
            alt="공식카페 가입 이벤트"
            width={isMobile ? 320 : 570}
          />
          <span
            className="mt-2 mb-4 sm:mt-0 sm:mb-0"
            style={{ fontWeight: 900, fontSize: isMobile ? 23 : 32 }}
          >
            공식카페 가입 이벤트!
          </span>
          {/* <div className="flex item-center">
						<span>현재 카페 가입자 수</span>
						<span className="font-bold text-3xl">{event.cafe}명</span>
					</div> */}
          {/* <LikeSlider
						value={event.cafe + 0}
						max={10000}
						disabled
						marks={marks}
					/> */}
          <div
            className="relative"
            style={{
              width: isMobile ? 320 : 880,
              height: isMobile ? 55 : 60,
              backgroundImage: `url(${isMobile ? gaugebgMo : gaugebg})`,
              backgroundPosition: 'center top',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div
              style={{
                width:
                  (isMobile ? 80 : 220) *
                  (event.cafe < 3000
                    ? 0
                    : event.cafe < 5000
                    ? 1
                    : event.cafe < 10000
                    ? 2
                    : event.cafe < 20000
                    ? 3
                    : 4),
                height: isMobile ? 35 : 60,
                backgroundImage: `url(${gauge})`,
                backgroundPosition: '8px 7px',
                backgroundSize: '864px',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
            <div
              className="w-full h-full absolute top-1 left-0 flex justify-between"
              style={{ height: isMobile ? 36 : 50 }}
            >
              <div className="w-0 h-full bg-black"></div>
              <div className="w-1 h-full bg-black"></div>
              <div className="w-1 h-full bg-black"></div>
              <div className="w-1 h-full bg-black"></div>
              <div className="w-0 h-full bg-black"></div>
            </div>
            <img
              src={ball}
              alt="농구공"
              width={isMobile ? 50 : 62}
              className="absolute -top-1"
              style={{
                left:
                  (isMobile ? 80 : 220) *
                    (event.cafe < 3000
                      ? 0
                      : event.cafe < 5000
                      ? 1
                      : event.cafe < 10000
                      ? 2
                      : event.cafe < 20000
                      ? 3
                      : 4) -
                  (isMobile ? 22 : 31),
              }}
            />
          </div>
          <div
            className="flex flex-wrap w-full item-center justify-center"
            style={{
              backgroundImage: isMobile ? 'none' : `url(${bgReward})`,
              backgroundPosition: 'center top',
              backgroundSize: '4550px',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {marks.map((mark, index) => {
              const isReached = mark.value <= event.cafe + 0;
              return (
                <div
                  key={index}
                  className="item m-0.5 sm:m-0  pt-1 sm:pt-0"
                  style={{
                    backgroundImage: !isMobile ? 'none' : `url(${bgRewardMo})`,
                    backgroundSize: 'cover',
                    width: isMobile ? 165 : 220,
                    borderRadius: isMobile ? 10 : 0,
                  }}
                >
                  <div
                    className=" text-center  text-md sm:text-xl font-bold"
                    style={{
                      color: isReached ? '#f4aa2f' : '#fff',
                      padding: isMobile ? 0 : 7.4,
                    }}
                  >
                    {mark.label} 달성!
                  </div>
                  <div
                    className="relative flex flex-col items-center justify-center py-2"
                    // style={{ border: "1px solid #000" }}
                  >
                    <img
                      src={mark.img}
                      alt={mark.reward}
                      style={{ height: isMobile ? 60 : 90 }}
                    />
                  </div>
                  <div className="w-full p-1 -mt-1 pt-3 sm:pt-0 sm:pb-2 flex justify-center item-center text-xxs sm:text-xs text-white text-center whitespace-pre ">
                    {mark.reward}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="mt-4 p-1 rounded text-xs sm:text-sm"
            style={{ backgroundColor: '#dddddddd' }}
          >
            * 본 보상은 최고 달성 기준으로 1회 지급됩니다. (중복 수령 불가)
          </div>
          <div
            className="absolute top-16 right-3 text-xs"
            style={{ opacity: 0.3 }}
          >
            {isMobile ? '' : event.cafe}
          </div>
          <div className="relative sm:mt-4">
            <Button
              onClick={onCafeGo}
              // variant="contained"
            >
              <img src={btCafe} alt="공식카페 가입하기" width={350} />
              <span className="absolute top-4 text-white">
                해당 이벤트를 공유해보세요!
              </span>
            </Button>
          </div>
          {event.dateState > 0 && (
            <div
              className="relative eventCoverBox mt- flex flex-col  justify-between text-center "
              style={{
                background: `linear-gradient(#000c 0%,#000a 100%), center / 1000px no-repeat url(${bgTest})`,
              }}
            >
              <div className="p-4 sm:p-10 ">
                <h1>
                  <b>쿠폰 등록 방법</b>
                </h1>

                <h2 className="mt-4">
                  {'게임실행>로비화면 좌측 시스템 설정>계정 설정>쿠폰 교환'}
                </h2>
              </div>
            </div>
          )}
        </div>
        <BtScroll setActivePage={props.setActivePage} />
      </div>
    </div>
  );
}

export const PopAlert = ({ children }) => {
  return <div>{children}</div>;
};
// const sliderHeight = 30;
// const sliderRadius = 4;
// const LikeSlider = withStyles({
// 	root: {
// 		width: "80%",
// 		marginTop: 20,
// 		marginBottom: sliderHeight + 10,
// 		"& .MuiSlider-thumb.Mui-disabled": {
// 			display: "none",
// 		},
// 	},
// 	disabled: {},
// 	track: {
// 		borderRadius: sliderRadius,
// 		backgroundColor: "#2e2eff",
// 		height: sliderHeight,
// 	},
// 	rail: {
// 		borderRadius: sliderRadius,
// 		backgroundColor: "#f4aa2f",
// 		height: sliderHeight,
// 	},
// 	mark: { bottom: -sliderHeight / 2 - 5, height: 5 },
// 	markLabel: { top: 45 },
// })(Slider);
const marks = [
  {
    value: 3000,
    label: '3,000명',
    img: reward3000,
    reward: '10,000골드\n에너지 음료 5개',
  },
  {
    value: 5000,
    label: '5,000명',
    img: reward5000,
    reward: '공통 스킬 훈련서 X 10\n공통 진급 카드 X 10',
  },
  {
    value: 10000,
    label: '10,000명',
    img: reward10000,
    reward: '100 다이아 | 20,000 골드\n다이아 룰렛권 3개',
  },
  {
    value: 20000,
    label: '20,000명',
    img: reward20000,
    reward: '200 다이아 | 40,000 골드\n다이아 룰렛권 5개',
  },
];

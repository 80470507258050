import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Divider } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { cdnImg } from '../../../config/etc';
import { useEventContext } from '../../../contexts/EventContext';

const back_q = cdnImg + 'back_q.png';
const back_q1 = cdnImg + 'back_q1.png';
const back_q2 = cdnImg + 'back_q2.png';
const back_q3 = cdnImg + 'back_q3.png';
const bt_close = cdnImg + 'bt_close.png';
const logo = cdnImg + 'logo.png';
const start = cdnImg + 'position_test_start.png';

export default function PositionTestQuestion({ onResult, onClose }) {
  const [index, setIndex] = useState(0);
  const [isStart, setIsStart] = useState(true);
  const totalWeight = useRef({}).current;
  const { event } = useEventContext();

  const total = questionary.length;
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const initData = () => {
    setIndex(0);
    const initWeight = { pg: 0, pf: 0, c: 0, sf: 0, sg: 0 };
    _.forEach(initWeight, (w, id) => {
      totalWeight[id] = w;
    });
  };
  const onAnswer = (weight) => {
    _.forEach(weight, (w, id) => {
      totalWeight[id] = (totalWeight[id] || 0) + w;
    });
    if (index < total - 1) {
      setIndex((prev) => (prev += 1));
    } else {
      onResult(totalWeight);
      initData();
    }
  };
  return (
    <div
      className={
        (event.dateState > 2 ? 'pt-10 ' : ' absolute z-50 ') +
        'flex h-full w-full sm:px-2'
      }
      style={{ backgroundColor: '#000000dd', height: window.innerHeight }}
    >
      <div
        className="sm:m-auto w-3xl text-white font-bold sm:border-4 border-yellow-500 p-2 flex flex-col pt-6 sm:pt-0 sm:justify-center bg-cover"
        style={{
          backgroundImage: `url(${back_q})`,
          // height: isMobile ? "calc(100vh - 55px)" : "auto",
          // "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
          // borderColor: "#f4aa2f",
        }}
      >
        {isStart ? (
          <div className="flex flex-col justify-center items-center">
            {event.dateState < 3 ? (
              <div className="flex justify-end w-full">
                <Button onClick={onClose} className="">
                  <img
                    src={bt_close}
                    alt="close"
                    width={33}
                    className="-mr-4"
                  />
                </Button>
              </div>
            ) : (
              <div className="h-10"></div>
            )}
            <h2
              className="text-xl mb-3 mt-10 sm:mt-0"
              style={{ fontSize: 24, fontWeight: 500 }}
            >
              농구할 때 당신의 포지션은?
            </h2>
            <Button onClick={() => setIsStart(false)}>
              <img src={start} alt="포지션테스트" width={480} />
            </Button>
            <div className="text-xs font-normal mt-6 mb-12 text-center">
              <p>500만 농구인이 선택한 농구게임!</p>
              <p>
                프리스타일2 레볼루션 플라잉덩크가 당신에게 어울리는 농구
                포지션을 알려드립니다.
              </p>
            </div>
          </div>
        ) : (
          <div className="relative px-2">
            <div className="flex justify-center sm:justify-start items-start">
              <span className="text-5xl sm:text-6xl">Q{index + 1}</span>
            </div>
            {event.dateState < 3 && (
              <div className="absolute top-0 right-0">
                <Button onClick={onClose} className="">
                  <img
                    src={bt_close}
                    alt="close"
                    width={33}
                    className="-mr-4"
                  />
                </Button>
              </div>
            )}
            <div
              className="mx-auto py-8 sm:py-12 text-xl sm:text-2xl text-center flex justify-center items-center"
              style={{
                height: isMobile ? 180 : 165,
              }}
            >
              <pre
                style={{
                  whiteSpace: isMobile ? 'normal' : 'pre-wrap',
                }}
              >
                {questionary[index].question}
              </pre>
            </div>
            <div className="max-w-lg mx-auto ">
              <Divider style={{ backgroundColor: '#aaa' }} />
              <div
                className="w-full flex flex-col space-y-2 sm:space-y-4 justify-center items-center my-2"
                style={{ height: isMobile ? 220 : 300 }}
              >
                {questionary[index].answers.map((answer, idx) => (
                  <Button
                    key={idx}
                    // variant="contained"
                    // fullWidth
                    onClick={() => onAnswer(answer.weight)}
                    style={{
                      width: isMobile ? 300 : 485,
                      height: isMobile ? 55 : 85,
                      fontSize: isMobile ? 13 : 15,
                      backgroundImage: `url(${
                        idx === 0 ? back_q1 : idx === 1 ? back_q2 : back_q3
                      })`,
                      // backgroundImage: `url('https://kr.object.ncloudstorage.com/cyougame/images/back_q${
                      // 	idx + 1
                      // }.png')`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      color: 'white',
                      // margin: "10px auto",
                      lineHeight: 1.2,
                      fontWeight: 700,
                      // WebkitTextStroke: "1.5px #000",
                      // stroke: "red",
                      // fill: "yellow",
                      // strokeWidth: "1.5px",
                      // paintOrder: "stroke fill",
                      textShadow: `-1.5px -1.5px 0 #000,
							  0   -1.5px 0 #000,
							  1.5px -1.5px 0 #000,
							  1.5px  0   0 #000,
							  1.5px  1.5px 0 #000,
							  0    1.5px 0 #000,
							  -1.5px  1.5px 0 #000,
							  -1.5px  0   0 #000`,
                    }}
                    // label={<span>ab</span>}
                  >
                    <pre>{answer.text}</pre>
                  </Button>
                ))}
              </div>
              <Divider style={{ backgroundColor: '#aaa' }} />
            </div>
            <img
              // src="https://kr.object.ncloudstorage.com/cyougame/images/logo_390x222.png"
              src={logo}
              alt="플라잉덩크2"
              style={{ width: isMobile ? 200 : 280, margin: '10px auto 20px' }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const questionary = [
  {
    question: '출근 시간, 사람이 가득한 전철.. 이럴 때 당신은?',
    answers: [
      {
        text: '답답한 건 싫어… 옆 칸으로 이동한다.',
        weight: { pg: 2, pf: 1, c: 2, sf: 3, sg: 1 },
      },
      {
        text: '조금 답답하지만, 여기 있어야\n빨리 환승할 수 있어 그냥 참아야지',
        weight: { pg: 1, pf: 0, c: 3, sf: 0, sg: 2 },
      },
      {
        text: '시간도 넉넉하니 그냥 다음 열차를 타야겠다.',
        weight: { pg: 1, pf: 3, c: 0, sf: 2, sg: 0 },
      },
    ],
  },

  {
    question: '친구가 응급실에 갔다고 연락이 왔다. 당신의 반응은?',
    answers: [
      {
        text: '헉 무슨 일이야? 보험은 있어??\n응급실은 비용이 비싼데….',
        weight: { pg: 2, pf: 1, c: 3, sf: 0, sg: 2 },
      },
      {
        text: '몸은 괜찮아?? 걱정된다.\n많이 다치진 않았지?',
        weight: { pg: 1, pf: 0, c: 0, sf: 3, sg: 1 },
      },
      {
        text: '어디가 아픈 거야??병명이 뭐래?',
        weight: { pg: 3, pf: 3, c: 1, sf: 2, sg: 3 },
      },
    ],
  },

  {
    question: '새로운 사람을 소개받는 자리에 초대받았다.',
    answers: [
      {
        text: '재밌겠다~ 대화 주제를 찾아본다.',
        weight: { pg: 2, pf: 3, c: 2, sf: 1, sg: 0 },
      },
      {
        text: '살짝 불편한데…. 걱정이 앞선다.',
        weight: { pg: 0, pf: 0, c: 1, sf: 2, sg: 3 },
      },
    ],
  },

  {
    question: '야근 후 집에 도착한 나는 늦은 저녁을 먹으려 하는데..',
    answers: [
      {
        text: '아 졸려..조금 배고프지만 자고 내일 아침에 먹을래',
        weight: { pg: 3, pf: 2, c: 1, sf: 0, sg: 1 },
      },
      {
        text: '어제 사놓은 식재료가 있으니 요리해 먹어야겠다.',
        weight: { pg: 1, pf: 3, c: 0, sf: 1, sg: 2 },
      },
      {
        text: '귀찮으니까 시켜먹어야지. 배달앱을 살펴본다.',
        weight: { pg: 0, pf: 0, c: 3, sf: 2, sg: 1 },
      },
    ],
  },

  {
    question: '항상 가던 식당에 새로운 신메뉴가 생겼다. \n이럴때 나는?',
    answers: [
      {
        text: '오? 맛이 궁금한데? 신메뉴를 시켜본다.',
        weight: { pg: 1, pf: 0, c: 2, sf: 3, sg: 1 },
      },
      {
        text: '음 신메뉴가 생겼구나.. 평소 시킨걸 먹는다.',
        weight: { pg: 2, pf: 2, c: 0, sf: 1, sg: 2 },
      },
    ],
  },

  {
    question:
      '약속이 없었던 친구가 갑작스럽게 술을 먹자 한다.\n지금 나는 아무 약속이 없긴 한데…',
    answers: [
      {
        text: '오 마침 나도 일 없었는데 한번 나가볼까~?',
        weight: { pg: 2, pf: 2, c: 1, sf: 0, sg: 2 },
      },
      {
        text: '그래도 약속 없이 만나긴 싫어~\n나도 집에서 할 일이 있는 걸',
        weight: { pg: 1, pf: 3, c: 0, sf: 1, sg: 0 },
      },
      {
        text: '이런 갑작스러운 모임 정말 좋아!! \n야호 신난다!',
        weight: { pg: 0, pf: 0, c: 3, sf: 2, sg: 1 },
      },
    ],
  },

  {
    question:
      '오랜 시간 만난 친구 커플이 싸워서 냉전 중이다. \n이럴 때 당신은?',
    answers: [
      {
        text: '서로의 의견을 들어보며 중재를 하고, \n화해를 시도한다.',
        weight: { pg: 3, pf: 1, c: 1, sf: 2, sg: 0 },
      },
      {
        text: '아 몰라, 지지든 볶든지 관심 없다. \n자리를 피한다.',
        weight: { pg: 2, pf: 3, c: 2, sf: 0, sg: 3 },
      },
      {
        text: '오… 이거 꽤 재밌는데? \n흥미롭게 구경을 한다.',
        weight: { pg: 1, pf: 0, c: 3, sf: 1, sg: 1 },
      },
    ],
  },

  {
    question:
      '어렸을때부터 키우고 싶었던 \n희귀애완동물 입양홍보지를 전달받았다. ',
    answers: [
      {
        text: '나도 이제 책임질 수 있는 상황이 되었다,\n입양 상담을 신청한다. ',
        weight: { pg: 2, pf: 1, c: 3, sf: 1, sg: 1 },
      },
      {
        text: '그래도 한 생명을 책임지는 건데, \n조금 불안해.. 유튜브로 대리 만족한다.',
        weight: { pg: 0, pf: 2, c: 0, sf: 2, sg: 3 },
      },
    ],
  },

  {
    question:
      '한적한 주말 찌뿌둥한 몸을 풀기 위해 운동을 \n해볼까 하는 당신, 당신의 운동 선택은?',
    answers: [
      {
        text: '오늘, 농구할사람~\n단톡방 친구들에게 농구를 제안한다.',
        weight: { pg: 1, pf: 2, c: 2, sf: 3, sg: 0 },
      },
      {
        text: '여러 사람이랑 운동 하기 귀찮아, \n그냥 노래들으며 조깅한다.',
        weight: { pg: 2, pf: 3, c: 1, sf: 1, sg: 3 },
      },
      {
        text: '농구 모임 카페에 가입하여, \n초면의 사람들과 신나게 농구를 즐긴다.',
        weight: { pg: 2, pf: 0, c: 1, sf: 2, sg: 2 },
      },
    ],
  },

  {
    question: '팀 프로젝트를 진행하게되었다. \n가장 먼저 해야할 일은?',
    answers: [
      {
        text: '정보가 있어야 방향이 잡히지, \n자료조사부터 한다',
        weight: { pg: 0, pf: 2, c: 0, sf: 2, sg: 1 },
      },
      {
        text: '업무 분배부터 한다. \n각자 역할을 맡아 진행해야지',
        weight: { pg: 2, pf: 2, c: 2, sf: 2, sg: 0 },
      },
    ],
  },

  {
    question: '공짜 여행권이 당첨됐다. \n다음 중 한 곳을 선택해야하는데..',
    answers: [
      {
        text: '비밀에 둘러쌓인 아름다운 섬',
        weight: { pg: 0, pf: 0, c: 3, sf: 0, sg: 2 },
      },
      {
        text: '구경할게 많고 맛집도 잔뜩있는 도시',
        weight: { pg: 2, pf: 2, c: 0, sf: 1, sg: 0 },
      },
      {
        text: '유명 명소를 한번에 돌 수 있도록 짠 \n자유여행 패키지',
        weight: { pg: 2, pf: 2, c: 0, sf: 1, sg: 1 },
      },
    ],
  },

  {
    question: '당신에게 초능력이 생긴다면 어떤 능력을 갖고 싶은가?',
    answers: [
      {
        text: '상대방의 마음을 읽는 능력',
        weight: { pg: 2, pf: 2, c: 1, sf: 2, sg: 1 },
      },
      {
        text: '미래를 볼수 있는 능력',
        weight: { pg: 0, pf: 0, c: 1, sf: 1, sg: 3 },
      },
      {
        text: '하늘을 날 수 있는 능력',
        weight: { pg: 2, pf: 0, c: 3, sf: 1, sg: 2 },
      },
    ],
  },
];

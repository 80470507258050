export const aniContainer = {
	visible: {
		transition: {
			delayChildren: 0,
			staggerChildren: 0.1,
		},
	},
};
export const aniItem = {
	hidden: { y: -40, opacity: 0 },
	visible: {
		y: 0,
		opacity: 1,
	},
};

import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
	// flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	padding: "0rem",
	borderWidth: 3,
	borderRadius: 4,
	borderColor: "#ddd",
	borderStyle: "dashed",
	backgroundColor: "#eee",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out",
};

const activeStyle = {
	borderColor: "#2196f3",
};

const acceptStyle = {
	borderColor: "#00e676",
};

const rejectStyle = {
	borderColor: "#ff1744",
};

export default function Dropper({ onDropAccepted, width, height, id, src }) {
	const config = {
		multiple: false,
		accept: "image/*",
		onDropAccepted: (e) => onDropAccepted(e, id),
	};
	const {
		acceptedFiles,
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone(config);

	const style = useMemo(() => {
		// console.log("width: ", width);
		let realWidth = Number(width.substring(0, width.length - 2));
		// console.log("realWidth: ", realWidth);
		let tempWidth = width;
		let tempHeight = height;
		if (realWidth > 500) {
			let ratio = 500 / realWidth;
			tempWidth = "500px";
			tempHeight =
				Number(height.substring(0, height.length - 2)) * ratio + "px";
		}
		return {
			width: tempWidth,
			height: tempHeight,
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		};
	}, [width, height, isDragActive, isDragAccept, isDragReject]);

	const files = () => {
		var tempSrc = src;
		if (!tempSrc) return null;
		return (
			<img
				src={tempSrc}
				className="object-cover rounded-sm"
				alt={src ? src : acceptedFiles[0]?.path}
				style={{
					width: style.width,
					height:
						Number(style.height.substring(0, style.height.length - 2)) -
						8 +
						"px",
				}}
			/>
		);
	};
	return (
		<section className="inline-block text-center">
			<div {...getRootProps({ style })} className="text-xs">
				<input id={id} {...getInputProps()} />
				{isDragReject && <p>이미지 형식이 아닙니다.</p>}
				{isDragAccept && <p>드롭해 주세요.</p>}
				{!isDragActive && !files() && <p>이미지 드롭 또는 클릭</p>}
				{files() && !isDragReject && !isDragAccept && <div>{files()}</div>}
			</div>
			<button {...getRootProps()} className="text-xs">
				등록
			</button>
		</section>
	);
}

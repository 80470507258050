import React, { useEffect } from "react";
import { SNS } from "../../config/snsKeys";
import { useLoginContext } from "../../contexts/LoginContext";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import { getSnsData, putSnsConnect } from "../../service/SnsAuth";
import { useCookies } from "react-cookie";
import Spinner from "../components/Spinner";
import { useSnackbar } from "notistack";
import Analytics from "../../service/Analytics";

const siteOrigin = document.location.origin;

const Oauth = () => {
	const history = useHistory();
	const location = useLocation();
	const { user, setUser } = useLoginContext();
	const code = qs.parse(location.search).code;
	const error = qs.parse(location.search).error;
	const codeState = qs.parse(location.search).state || "";
	const [cookies] = useCookies();
	const { enqueueSnackbar } = useSnackbar();

	const errorHandle = (e) => {
		history.replace(cookies.from || "/", { complete: false });
		enqueueSnackbar(
			"인증에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
			{ variant: "error" }
		);
	};
	useEffect(() => {
		if (code && codeState) {
			let connectStr = codeState.split("-");
			if (connectStr[0] === "connect" && user.id) {
				//SNS 연동 시도할 경우
				getSnsData(
					code,
					siteOrigin + "/Oauth",
					SNS.key[connectStr[1]],
					connectStr[1]
				)
					.then((res) => {
						if (user.id) {
							let data = {};
							if (res.data.connect_id) {
								data.data = res.data.data;
							} else {
								data.data = res.data;
								data.data.user = user;
							}
							data.connect_id = user.id;
							putSnsConnect(data)
								.then((result) => {
									setUser(result.data);
									Analytics.onSignIn(result.data.user);
									Analytics.logEvent("SnsConnectByManual");
									history.replace(cookies.from || "/", {
										complete: connectStr[1],
									});
								})
								.catch(errorHandle);
						}
					})
					.catch(errorHandle);
				return;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, codeState, user.id]);

	useEffect(() => {
		if (error) {
			history.replace(cookies.from || "/");
		}
		if (code && codeState) {
			if (codeState === "isConnect") {
				//가입 시도 중 동일 이메일 있을 경우
				let data = JSON.parse(sessionStorage.getItem("connectData"));
				putSnsConnect(data)
					.then((result) => {
						setUser(result.data);
						Analytics.onSignIn(result.data.user);
						Analytics.logEvent("SnsConnectByLogin");

						sessionStorage.removeItem("connectData");
						history.replace(cookies.from || "/");
					})
					.catch(errorHandle);
				return;
			}
			let connectStr = codeState.split("-");
			if (connectStr[0] !== "connect") {
				//일반 가입
				getSnsData(code, siteOrigin + "/Oauth", SNS.key[codeState], codeState)
					.then((res) => {
						if (res.data?.errors?.[0]?.error) {
							enqueueSnackbar(res.data?.errors?.[0]?.error, {
								variant: "error",
							});
							history.replace("/");
							return;
						}
						if (res.data.connect_id) {
							sessionStorage.setItem("connectData", JSON.stringify(res.data));
							history.replace({
								pathname: "/snsConnect",
							});
						} else if (res.data.isRegister) {
							history.replace({
								pathname: "/agreeTerms",
								state: res.data,
							});
						} else {
							// 일반 로그인
							setUser(res.data);
							Analytics.onSignIn(res.data.user);
							Analytics.logEvent("SnsLogin", { path: codeState });

							if (cookies.from) {
								history.replace(cookies.from);
							} else {
								history.replace("/");
							}
						}
					})
					.catch(errorHandle);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, codeState]);

	return (
		<div className="h-screen w-full flex justify-center items-center bg-gray-700">
			<Spinner />
		</div>
	);
};

export default Oauth;

import React, { createContext, useContext, useState } from "react";

const Context = createContext({
	setPopup: () => {},
	popup: {},
});
export const usePopContext = () => useContext(Context);

const PopProvider = ({ children }) => {
	const setPopup = (data) => {
		console.log("data: ", data);
		setState((prevState) => {
			return { ...prevState, popup: data };
		});
	};

	const defaultState = {
		setPopup,
		popup: {},
	};

	const initialState = {
		...defaultState,
	};

	const [state, setState] = useState(initialState);

	return <Context.Provider value={state}>{children}</Context.Provider>;
};
export default PopProvider;

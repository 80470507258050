import React, { useState } from "react";
import { useLoginContext } from "../contexts/LoginContext";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { snsRegister } from "../service/SnsAuth";
import { Form } from "react-final-form";
import InputAdapter from "./components/InputAdapter";
import {
	composeValidators,
	nickAvailable,
	requiredValidator,
	specialCharValidator,
} from "../utils/validator";
import createDecorator from "final-form-focus";
import CheckAdapter from "./components/CheckAdapter";
import { signUpWithEmail } from "../service/Auth";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { aniContainer, aniItem } from "../config/animation";
import GoBack from "./components/GoBack";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import Analytics from "../service/Analytics";
import { useCookies } from "react-cookie";

const focusOnError = createDecorator();

const AgreeTerms = ({ location }) => {
	const data = location.state;
	console.log("data: ", data, data.isRegister);
	const [agree_terms, setAgree_terms] = useState(false);
	const [agree_private, setAgree_private] = useState(false);
	const [agree_event, setAgree_event] = useState(false);
	const [agree_5year, setAgree_5year] = useState(false);
	const history = useHistory();
	const { setUser } = useLoginContext();
	const { enqueueSnackbar } = useSnackbar();
	const [cookies] = useCookies();

	const onRegister = (values) => {
		if (data.isRegister) {
			snsRegister({
				...data,
				...values,
				agree_terms,
				agree_private,
				agree_event,
				agree_5year,
			})
				.then((res) => {
					res.data.path = data.path;
					setUser(res.data);
					Analytics.onSignUp(res.data.user);
					Analytics.logEvent("register", { path: data.path });
					enqueueSnackbar("축하합니다. 회원가입이 완료되었습니다.", {
						variant: "success",
					});

					history.replace(cookies.from || "/");
				})
				.catch((err) => {
					enqueueSnackbar(
						"회원 가입 처리에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
						{ variant: "error" }
					);
				});
		} else {
			signUpWithEmail({
				...data,
				agree_terms,
				agree_private,
				agree_event,
				agree_5year,
			})
				.then((res) => {
					setUser(res);
					Analytics.onSignUp(res.user);
					Analytics.logEvent("register", { path: "email" });
					enqueueSnackbar("축하합니다. 회원가입이 완료되었습니다.", {
						variant: "success",
					});

					history.replace(cookies.from || "/");
				})
				.catch((err) => {
					enqueueSnackbar(
						"회원 가입 처리에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
						{ variant: "error" }
					);
				});
		}
	};
	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className="mx-auto max-w-lg p-4 mt-4"
		>
			{/* {data.isNickModified && (
				<>
					<b>{data.nickname.split("_")[0]}</b> 닉네임은 이미 사용중입니다.
				</>
			)} */}
			<Form
				onSubmit={onRegister}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors, values }) => (
					<form onSubmit={handleSubmit} className="flex flex-col ">
						<motion.div variants={aniItem}>
							<GoBack />
						</motion.div>
						<motion.h1
							variants={aniItem}
							className="font-bold text-xl text-purple-600 mb-3"
						>
							약관 동의
						</motion.h1>
						<div className="space-y-2">
							{data.isRegister && (
								<motion.div variants={aniItem}>
									<InputAdapter
										validator={composeValidators(
											requiredValidator,
											specialCharValidator,
											nickAvailable
										)}
										spinner="true"
										name="nickname"
										type="text"
										title="닉네임"
										focused={true}
										initialValue={data.nickname}
									/>
								</motion.div>
							)}

							<motion.div variants={aniItem}>
								<CheckAdapter
									name="agree_all"
									label={<b>모두 동의합니다.</b>}
									checked={
										agree_terms && agree_private && agree_event && agree_5year
									}
									onClick={(e) => {
										console.log(submitting, Object.keys(errors), errors);
										// setAgree_all(e.target.checked);
										setAgree_terms(e.target.checked);
										setAgree_private(e.target.checked);
										setAgree_event(e.target.checked);
										setAgree_5year(e.target.checked);
									}}
								/>
								<p className="pl-7 text-xs">
									창유코리아 서비스 이용약관, 개인정보 수집 및 이용, 유효기간
									5년(선택), 이벤트 등 안내 메일 수신(선택)에 모두 동의합니다.
								</p>
							</motion.div>
							<motion.hr
								variants={aniItem}
								style={{ marginTop: "1.5rem" }}
							></motion.hr>
							<motion.div variants={aniItem}>
								<CheckAdapter
									name="agree_terms"
									checked={agree_terms}
									onClick={(e) => {
										setAgree_terms(e.target.checked);
									}}
									label={
										<span className="text-sm">
											창유코리아 서비스 이용약관 동의
										</span>
									}
								/>
							</motion.div>
							<motion.div
								variants={aniItem}
								className=" h-36 bg-white border-gray-200 border overflow-auto "
							>
								<TermsOfUse isComponent={true} />
							</motion.div>
							<motion.div variants={aniItem}>
								<CheckAdapter
									name="agree_private"
									checked={agree_private}
									onClick={(e) => {
										setAgree_private(e.target.checked);
									}}
									label={
										<span className="text-sm">개인정보 수집 및 이용 동의</span>
									}
								/>
							</motion.div>
							<motion.div
								variants={aniItem}
								className="h-36 bg-white border-gray-200 border overflow-auto "
							>
								<PrivacyPolicy isComponent={true} />
							</motion.div>
							<motion.div variants={aniItem}>
								<CheckAdapter
									name="agree_event"
									checked={agree_event}
									onClick={(e) => {
										setAgree_event(e.target.checked);
									}}
									label={
										<span className="text-sm">
											<b className="text-purple-600">(선택)</b>이벤트 등
											프로모션 안내 메일을 수신합니다.
										</span>
									}
									// name="agree_private"
								/>
							</motion.div>
							<motion.div variants={aniItem}>
								<CheckAdapter
									name="agree_5year"
									checked={agree_5year}
									onClick={(e) => {
										setAgree_5year(e.target.checked);
									}}
									label={
										<span className="text-sm">
											<b className="text-purple-600">(선택)</b>개인정보
											유효기간을 5년으로 선택합니다.
										</span>
									}
								/>
								<p className="pl-7 text-xs mb-4 text-gray-500 -mt-2">
									선택하지 않으면 1년으로 설정됩니다.
								</p>
							</motion.div>
							<motion.div variants={aniItem}>
								<Button
									type="submit"
									color="primary"
									variant="contained"
									disabled={
										submitting ||
										Object.keys(errors).length !== 0 ||
										errors.length > 0 ||
										!agree_terms ||
										!agree_private
									}
									fullWidth={true}
								>
									회원 가입
								</Button>
							</motion.div>
						</div>
					</form>
				)}
			/>
		</motion.div>
	);
};

export default AgreeTerms;

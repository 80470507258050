
import axios from "axios";
import { apiServer } from "../config/servers";
import { SNS } from "../config/snsKeys";


export const checkDuplicated = async (field, value) => {
	const res = await axios
		.get(apiServer + "/auth/checkDuplicated?field=" + field + "&value=" + value)
		.catch(console.error);
	return res.data;
};

const siteOrigin = document.location.origin;
const redirect_uri = siteOrigin + "/Oauth";

/**
  @param {string} path SNS경로
  @param {string} stateStr 연결정보 isConnect 또는 connect-SNS경로
 */
export const getCode = (path, stateStr) => {
	let url = `${SNS.code[path]}?response_type=code&client_id=${
		SNS.key[path]
	}&redirect_uri=${redirect_uri}&state=${stateStr ? stateStr : path}`;
	switch (path) {
		case SNS.GOOGLE:
			url +=
				"&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";
			break;
		case SNS.FACEBOOK:
			url += "&scope=public_profile,email";
			break;
		default:
			break;
	}
	window.location.href = url;
};
export const getSnsData = (code, redirect_uri, client_id, state) => {
	let requestUri;
	requestUri = apiServer + "/auth/snsData";
	return axios.get(requestUri, {
		params: {
			grant_type: "authorization_code",
			code: code,
			redirect_uri,
			client_id,
			state,
		},
	});
};

export const snsRegister = (data) => {
	return axios.post(apiServer + "/auth/snsRegister", data);
};

export const putSnsConnect = (data) => {
	return axios.put(apiServer + "/auth/snsConnect", data);
};
export const putSnsDisConnect = (data) => {
	return axios.put(apiServer + "/auth/snsDisConnect", data);
};
export const disconnectSns = (data, setUser, history, cookies) => {
	axios
		.put(apiServer + "/auth/snsDisconnect", data)
		.then((res) => {
			setUser({});
			history.replace(cookies.from || "/");
		})
		.catch((e) => console.log(e));
};

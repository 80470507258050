/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import { Button, Slider } from '@material-ui/core';
import { useCookies } from 'react-cookie';
// import { useSnackbar } from "notistack";
import { withStyles } from '@material-ui/core';
// import ball from "../../../assets/ball.png";
// import { Card } from "@material-ui/core";
import { useEventContext } from '../../../contexts/EventContext';
import { setPreviewEvent } from '../../../service/Events';
import { useLoginContext } from '../../../contexts/LoginContext';
import Analytics from '../../../service/Analytics';
import { useMediaQuery } from 'react-responsive';
import { cdnImg } from '../../../config/etc';
import { BtScroll } from '../FDMain';
import { motion } from 'framer-motion';

const bg = cdnImg + 'bg5-2.jpg';
const bgCount = cdnImg + 'bgCounter.jpg';
const title = cdnImg + 'pc/5-2/타이틀.png';
const titleMo = cdnImg + 'mo/5-2/타이틀.png';
const ball = cdnImg + 'pc/5-2/게이지-농구공.png';
const like30 = cdnImg + 'like30.jpg';
const like50 = cdnImg + 'like50.jpg';
const like100 = cdnImg + 'like100.jpg';
const like200 = cdnImg + 'like200.jpg';
const like30clear = cdnImg + 'like30clear.jpg';
const like50clear = cdnImg + 'like50clear.jpg';
const like100clear = cdnImg + 'like100clear.jpg';
const like200clear = cdnImg + 'like200clear.jpg';
const btLike = cdnImg + 'pc/5-2/좋아요 버튼.png';
const bgTest = cdnImg + 'bg_test.jpg';

export default function FDEventLike(props) {
  // const [like, setLike] = useState(0);
  // const [alreadyAttend, setAlreadyAttend] = useState(false);
  const [cookies, setCookie] = useCookies();
  // const { enqueueSnackbar } = useSnackbar();
  const { event } = useEventContext();
  const { user } = useLoginContext();

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isBig = useMediaQuery({ query: '(min-height: 1240px)' });

  const [ani, setAni] = useState();

  let timer;
  const onLike = () => {
    const today = new Date().toLocaleDateString('ko-KR');
    // console.log("cookies: ", cookies.liked);
    if (today !== cookies.liked) {
      if (user.id)
        setPreviewEvent({
          isLiked: true,
          uid: user.id,
          nickname: user.nickname,
        });
      setCookie('liked', today);
      setCookie('likeCount', 0);
    }

    let likeCount = isNaN(cookies.likeCount) ? 0 : Number(cookies.likeCount);
    likeCount++;
    console.log('likeCount: ', likeCount);
    if (likeCount > 3000) {
      // enqueueSnackbar(
      // 	`오늘 좋아요 이벤트 참여횟수가 1000회를 넘었습니다. 내일 또 참여해 주세요!`,
      // 	{
      // 		variant: "warning",
      // 	}
      // );
      return;
    }

    setCookie('likeCount', likeCount++);

    clearTimeout(timer);
    setAni(true);
    timer = setTimeout(() => {
      setAni(false);
    }, 500);
    firebase
      .database()
      .ref()
      .update({ likes: firebase.database.ServerValue.increment(1) });

    // enqueueSnackbar(
    // 	`좋아요 이벤트에 참여 완료했습니다. 내일 또 참여해 주세요!`,
    // 	{
    // 		variant: "success",
    // 	}
    // );
    Analytics.logEvent('likeEvent', { userId: user.id ?? '' });
    // } else {
    // 	enqueueSnackbar(
    // 		`오늘(${today})은 이미 참여했습니다. 내일 다시 참여해 주세요!`,
    // 		{
    // 			variant: "error",
    // 		}
    // 	);
    // }
    // setAlreadyAttend(true);
  };

  return (
    <div
      // className="p-4 h-full max-w-2xl m-auto flex flex-col items-center justify-center"
      style={{
        backgroundColor: '#000',
        backgroundImage: `url(${bg})`,
        // "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
        borderColor: '#f4aa2f',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: !isBig ? '100%' : window.innerHeight,
      }}
    >
      <div
        className="h-full m-auto flex flex-col items-center justify-start text-white px-2 sm:px-4 pt-14 pb-4 sm:py-16"
        style={{
          // height: isMobile ? "100%" : window.innerHeight,
          // paddingLeft: isMobile && status !== "main" ? 0 : 15,
          // paddingRight: isMobile && status !== "main" ? 0 : 15,
          color: '#402c00',
        }}
      >
        <div className="event2 w-full flex flex-col items-center mt-8 sm:mt-12">
          <img
            src={isMobile ? titleMo : title}
            alt="좋아요 이벤트"
            width={isMobile ? 320 : 520}
          />
          <p className="my-1 text-center text-xs sm:text-base">
            본 이벤트는 무한정 참여 가능합니다. '좋아요'를 원하는만큼
            눌러주세요.
          </p>
          <div
            className="flex justify-center items-center text-white mt-1 py-2 px-4 text-lg sm:text-xl font-bold rounded-full border-8 border-white shadow-inner "
            style={{
              backgroundImage: `url(${bgCount})`,
              boxShadow: '2px 5px #00000066',
            }}
          >
            <span>현재 좋아요 수</span>
            <span
              className="text-4xl sm:text-6xl ml-3 text-center"
              style={{ color: '#f4aa2f', minWidth: isMobile ? 100 : 200 }}
            >
              {event.like?.toLocaleString('ko-KR')}
            </span>
          </div>
          <LikeSlider
            value={event.like + 0}
            max={20e5}
            disabled
            marks={marks}
          />
          <div className="relative -mt-5 sm:-mb-8">
            <Button onClick={onLike}>
              <img
                src={btLike}
                alt="좋아요 꾸욱"
                width={isMobile ? 330 : 390}
              />
            </Button>
            {ani && (
              <motion.img
                initial={{ scale: 0.68, opacity: 0.0, top: 0 }}
                animate={{ scale: 1, opacity: 1, top: -40 }}
                transition={{
                  duration: 0.5,
                  y: { type: 'spring', stiffness: 100 },
                }}
                src={ball}
                alt="공"
                width={40}
                className="absolute top-0 "
                style={{ left: isMobile ? 155 : 185 }}
              />
            )}
          </div>
          {event.dateState > 0 ? (
            <div
              className="sm:mt-12 eventCoverBox flex flex-col justify-between text-center "
              style={{
                background: `linear-gradient(#000c 0%,#000a 100%), center / 1000px no-repeat url(${bgTest})`,
              }}
            >
              <div className="p-4 sm:p-10 flex flex-col justify-center">
                <h1>
                  <b>좋아요 이벤트 종료!</b>
                </h1>
                <h2 className="mt-4 sm:mt-8 flex items-center justify-center">
                  보상은
                  <span className="text-yellow-300">
                    &nbsp;정식 서비스 오픈 후 쿠폰 교환&nbsp;
                  </span>
                  에서
                </h2>
                <h2>
                  <span className="text-yellow-300"> [LIKEFD2]</span> 를
                  입력하면 받을 수 있습니다.
                </h2>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap item-center justify-center -mt-2 mb-1 sm:my-3">
              {marks.map((mark, index) => {
                const isReached = mark.value <= event.like + 0;
                const rewardSize = isMobile ? 150 : 190;
                return (
                  <div key={index}>
                    {isReached ? (
                      <img
                        src={mark.clear}
                        alt={mark.label + 'clear'}
                        width={rewardSize}
                        className=" m-1 sm:m-3 "
                      />
                    ) : (
                      <img
                        src={mark.img}
                        alt={mark.reward}
                        width={rewardSize}
                        className=" m-1 sm:m-3"
                      />
                    )}
                  </div>
                  // <Card key={index} className="item border">
                  // 	<div className="p-3 text-center border-b ">
                  // 		{mark.label} 달성
                  // 	</div>
                  // 	<div className="relative p-4 ">
                  // 		{isReached ? (
                  // 			<img src={mark.reward} alt="clear" />
                  // 		) : (
                  // 			<img src={mark.img} alt={mark.label} />
                  // 		)}
                  // 		<div className="border rounded bg-gray-300 flex justify-center item-center mt-4">
                  // 			{mark.reward}
                  // 		</div>
                  // 	</div>
                  // </Card>
                );
              })}
            </div>
          )}
          {/* <Button onClick={onLike} disabled={alreadyAttend}> */}
          {/* <div style={{ marginBottom: 30 }}></div> */}
        </div>
        <BtScroll setActivePage={props.setActivePage} />
      </div>
    </div>
  );
}

const sliderHeight = 25;
const ballHeight = 50;
const sliderRadius = 40;
const border = '6px solid #000';
const LikeSlider = withStyles((theme) => {
  return {
    root: {
      width: 650,
      marginTop: 25,
      marginBottom: sliderHeight + 10,
      '& .MuiSlider-thumb.Mui-disabled': {
        width: ballHeight,
        height: ballHeight,
        marginTop: -ballHeight / 2 + sliderHeight / 2 - 2,
        marginLeft: -ballHeight / 2,

        // backgroundColor: "#F72323e1",
        background: `url(${ball})`,
        borderRadius: '100%',
        backgroundSize: 'contain',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        marginTop: 10,
      },
    },
    disabled: {},
    track: {
      border,
      borderRadius: sliderRadius,
      backgroundColor: '#f4aa2f',
      height: sliderHeight,
    },
    rail: {
      border,
      borderRadius: sliderRadius,
      backgroundColor: '#fff',
      height: sliderHeight,
      opacity: 1,
    },
    mark: {
      bottom: -sliderHeight / 2 + 7,
      height: 16,
      color: '#000',
      width: 2,
      '&:last-child': { color: '#fa0' },
      [theme.breakpoints.down('sm')]: {
        bottom: -sliderHeight / 2 + 14,
      },
    },
    markActive: {
      backgroundColor: '#000',
      width: 3,
    },
    markLabel: { opacity: 0, top: 45 },
  };
})(Slider);
const marks = [
  {
    value: 30e4,
    label: '30만',
    img: like30,
    clear: like30clear,
    reward: '에너지 음료 3',
  },
  {
    value: 50e4,
    label: '50만',
    img: like50,
    clear: like50clear,
    reward: '공통 진급 카드 5',
  },
  {
    value: 10e5,
    label: '100만',
    img: like100,
    clear: like100clear,
    reward: '공통 스킬 훈련서 5',
  },
  {
    value: 20e5,
    label: '200만',
    img: like200,
    clear: like200clear,
    reward: '10,000 골드',
  },
  // {
  // 	value: 10e5,
  // 	label: "100만",
  // 	img: "https://placehold.it/100x100",
  // 	reward: "reward5",
  // },
];

import "./App.css";
import Layout from "./layout/Layout";
import { ThemeProvider } from "@material-ui/core";
import theme from "./layout/theme";
import { createElement, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import LoginContextProvider from "./contexts/LoginContext";
import { CookiesProvider } from "react-cookie";
import { SnackbarProvider } from "notistack";
import { initFirebase } from "./service/Analytics";

import EventContextProvider from "./contexts/EventContext";
import PopProvider from "./contexts/PopContext";

function App() {
	const AppProvider = ({ contexts, children }) =>
		contexts.reduce(
			(prev, context) =>
				createElement(context, {
					children: prev,
				}),
			children
		);

	useEffect(() => {
		initFirebase();
		console.log("App initiating - ", process.env.NODE_ENV);
		if (process.env.NODE_ENV === "production") {
			console.log = function () {};
		}
		// return () => {
		// };
	}, []);
	return (
		<SnackbarProvider
			maxSnack={5}
			anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
		>
			<AppProvider
				contexts={[LoginContextProvider, EventContextProvider, PopProvider]}
			>
				<HelmetProvider>
					<CookiesProvider>
						<ThemeProvider theme={theme}>
							<Layout />
						</ThemeProvider>
					</CookiesProvider>
				</HelmetProvider>
			</AppProvider>
		</SnackbarProvider>
	);
	// return (
	// 	<Provider store={store}>
	// 		<PersistGate persistor={persistor}>
	// 			<ThemeProvider theme={theme}>
	// 				<Layout />
	// 			</ThemeProvider>
	// 		</PersistGate>
	// 	</Provider>
	// );
}

export default App;

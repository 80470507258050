import { Button, Tab, Tabs, withStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { aniContainer, aniItem } from "../config/animation";
import ConnectedSNS from "./auth/ConnectedSNS";
import NickModify from "./auth/NickModify";
import PointHistory from "./auth/PointHistory";
import SignOut from "./auth/SignOut";
import ResetPassword from "./ResetPassword";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `tabpanel-${index}`,
	};
}

const MemberTabs = withStyles((theme) => ({
	root: {
		// borderBottom: "1px solid #e8e8e8",
	},
	indicator: {
		// backgroundColor: "#fff",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		marginBottom: 5,
		"& > span": {
			// maxWidth: 80,
			width: "80%",
			backgroundColor: "#ddd",
			// backgroundColor: theme.palette.primary.main,
		},
	},
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const MemberTab = withStyles((theme) => ({
	root: {
		textTransform: "none",
		minWidth: 72,
		height: 70,
		fontWeight: theme.typography.fontWeightRegular,
		backgroundColor: "#fff",
		border: ".1px solid #eee",
		// marginRight: theme.spacing(4),
		"&:hover": {
			// color: theme.palette.primary.main,
			color: "#000",
			fontWeight: "bold",
			opacity: 1,
		},
		"&$selected": {
			color: "#fff",
			backgroundColor: "#050505",
			fontWeight: theme.typography.fontWeightMedium,
		},
		"&:focus": {
			// color: "#40a9ff",
		},
	},
	selected: {},
}))(Tab);

const MemberButton = withStyles((theme) => ({
	root: {
		height: 50,
		fontWeight: theme.typography.fontWeightBold,
		backgroundColor: "#fff",
		marginBottom: theme.spacing(1),
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
}))(Button);

const Member = ({ location }) => {
	const selectedTab = location?.state?.tab;
	const tabCount = 5;
	const tab = JSON.parse(sessionStorage.getItem("memberTab"));
	const [tabValue, setTabValue] = React.useState(
		selectedTab
			? selectedTab
			: tab
			? tab
			: window.innerWidth < 600
			? tabCount
			: 0
	);
	// screen.width

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
		sessionStorage.setItem("memberTab", newValue);
	};

	useEffect(() => {
		if (selectedTab) setTabValue(selectedTab);
	}, [selectedTab]);

	return (
		<div className="">
			<div className="flex items-center bg-gray-200">
				<h1
					className="w-32 py-4 pl-4 font-bold text-xl"
					onClick={() => {
						if (window.innerWidth < 600) setTabValue(5);
					}}
				>
					회원정보
				</h1>
				<div className="sm:px-4 w-full sm:max-w-2xl mx-auto  hidden sm:block">
					<MemberTabs
						value={tabValue < tabCount ? tabValue : 0}
						onChange={handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
						aria-label="full width tabs example"
					>
						<MemberTab
							label={<span className="text-xs md:text-sm">기본 정보</span>}
							{...a11yProps(0)}
						/>
						<MemberTab
							label={<span className="text-xs md:text-sm">비밀번호 변경</span>}
							{...a11yProps(1)}
						/>
						<MemberTab
							label={<span className="text-xs md:text-sm">간편가입 연동</span>}
							{...a11yProps(2)}
						/>
						<MemberTab
							label={<span className="text-xs md:text-sm">포인트 내역</span>}
							{...a11yProps(3)}
						/>
						<MemberTab
							label={<span className="text-xs md:text-sm">회원 탈퇴</span>}
							{...a11yProps(4)}
						/>
					</MemberTabs>
				</div>
				<div className="hidden sm:block sm:w-32"></div>
			</div>
			<div className="relative mxrounded-xl w-full sm:max-w-lg mx-auto">
				<TabPanel value={tabValue} index={0}>
					<NickModify setTab={setTabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<ResetPassword setTab={setTabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={2}>
					<ConnectedSNS setTab={setTabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={3}>
					<PointHistory setTab={setTabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={4}>
					<SignOut setTab={setTabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={5} className="p-4">
					<motion.div
						variants={aniContainer}
						initial="hidden"
						animate="visible"
					>
						<motion.div variants={aniItem}>
							<MemberButton
								fullWidth
								variant="contained"
								onClick={() => setTabValue(0)}
							>
								기본 정보
							</MemberButton>
						</motion.div>
						<motion.div variants={aniItem}>
							<MemberButton
								fullWidth
								variant="contained"
								onClick={() => setTabValue(1)}
							>
								비밀번호 변경
							</MemberButton>
						</motion.div>
						<motion.div variants={aniItem}>
							<MemberButton
								fullWidth
								variant="contained"
								onClick={() => setTabValue(2)}
							>
								간편가입 연동
							</MemberButton>
						</motion.div>
						<motion.div variants={aniItem}>
							<MemberButton
								fullWidth
								variant="contained"
								onClick={() => setTabValue(3)}
							>
								포인트 내역
							</MemberButton>
						</motion.div>
						<motion.div variants={aniItem}>
							<MemberButton
								fullWidth
								variant="contained"
								onClick={() => setTabValue(4)}
							>
								회원 탈퇴
							</MemberButton>
						</motion.div>
					</motion.div>
				</TabPanel>
			</div>
		</div>
	);
};

export default Member;

import React, { useState } from "react";
import { Form } from "react-final-form";
import createDecorator from "final-form-focus";
import {
	composeValidators,
	emailValidator,
	passwordValidator,
	requiredValidator,
} from "../../utils/validator";
import { Button, Divider } from "@material-ui/core";
import InputAdapter from "../components/InputAdapter";
import CheckLabel from "../components/CheckLabel";
import { Link, useHistory, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useLoginContext } from "../../contexts/LoginContext";
import { signInWithEmail } from "../../service/Auth";
import { useCookies } from "react-cookie";
import { putSnsConnect } from "../../service/SnsAuth";
import { aniContainer, aniItem } from "../../config/animation";
import { useSnackbar } from "notistack";

const focusOnError = createDecorator();

export default function LoginBox({ isConnect, prevEmail }) {
	const { setUser } = useLoginContext();
	const location = useLocation();
	const history = useHistory();
	const [cookies, setCookie] = useCookies(["user"]);
	const [persist, setPersist] = useState(
		cookies.persist ? JSON.parse(cookies.persist) : false
	);
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = (values) => {
		values.persist = persist;
		return signInWithEmail(values)
			.then((res) => {
				if (res?.errors?.[0]?.error) {
					enqueueSnackbar(res?.errors?.[0]?.error, { variant: "error" });
					return;
				}
				if (isConnect) {
					// 연결 확인만 하고, 받은 데이터로 저장 처리 PutSNSConnect
					let data = JSON.parse(sessionStorage.getItem("connectData"));
					putSnsConnect(data)
						.then((result) => {
							setUser(result.data);

							sessionStorage.removeItem("connectData");
							history.replace(cookies.from || "/");
						})
						.catch((e) =>
							enqueueSnackbar(
								"연동에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
								{ variant: "error" }
							)
						);
					return;
				} else {
					setUser(res);
				}
			})
			.catch((err) => {
				enqueueSnackbar(
					"로그인에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
					{ variant: "error" }
				);
				return err;
			});
	};
	const onPersist = (e) => {
		setPersist(e.target.checked);
		setCookie("persist", e.target.checked);
	};

	return (
		<div className="relative mxrounded-xl py-4 w-full ">
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors }) => (
					<motion.form
						variants={aniContainer}
						initial="hidden"
						animate="visible"
						onSubmit={handleSubmit}
						className="flex flex-col space-y-2"
					>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(requiredValidator, emailValidator)}
								spinner="true"
								name="email"
								type="email"
								title="이메일"
								disabled={isConnect}
								initialValue={prevEmail}
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(
									requiredValidator,
									passwordValidator
								)}
								name="password"
								type="password"
								title="비밀번호"
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={
									submitting ||
									Object.keys(errors).length !== 0 ||
									pristine ||
									errors.length > 0
								}
								fullWidth={true}
							>
								{isConnect ? "이메일로 연결" : "이메일로 로그인"}
							</Button>
						</motion.div>
						{!isConnect && (
							<motion.div
								variants={aniItem}
								className="flex justify-between items-center text-xs"
							>
								<CheckLabel
									label="로그인 유지"
									name="persist"
									checked={persist || false}
									onChange={onPersist}
								/>
								<div className="flex">
									<Link className="mr-1.5" to="/forgotPassword">
										비번찾기
									</Link>
									<Divider orientation="vertical" flexItem />
									<Link
										className="ml-1.5"
										to={{
											pathname: "/register",
											state: { from: location.pathname },
										}}
									>
										회원가입
									</Link>
								</div>
							</motion.div>
						)}
					</motion.form>
				)}
			/>
		</div>
	);
}

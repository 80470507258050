import { Divider, Link } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import cyou_logo_gray from "../assets/cyou_logo_gray.png";
import youtubeLogo from "../assets/youtubeLogo.png";

const Footer = () => {
	const history = useHistory();
	return (
		<footer
			className="sm:text-xxs sm:text-left bottom-0 w-full text-xs text-center bg-black"
			style={{ backgroundColor: "#222" }}
		>
			<span className="sm:justify-start max-w-8xl relative flex items-center justify-center px-2 py-5 mx-auto space-x-2 text-white">
				<Link
					onClick={() => history.push("/termsOfUse")}
					variant="inherit"
					color="inherit"
					className="cursor-pointer"
				>
					이용약관
				</Link>
				<span>|</span>
				<Link
					onClick={() => history.push("/privacyPolicy")}
					variant="inherit"
					color="inherit"
					className="cursor-pointer"
				>
					개인정보처리방침
				</Link>
				<Link
					href="https://www.youtube.com/channel/UCsTezNAYKds4XyHVDdC0wcw/featured"
					target="_blank"
					variant="inherit"
					color="inherit"
					className="right-4 absolute cursor-pointer"
				>
					<img
						src={youtubeLogo}
						alt="공식 유투브"
						className="youtube_share transition-500 w-6"
					/>
				</Link>
			</span>
			<Divider
				className="sm:block hidden w-full m-0"
				style={{ background: "#666" }}
			/>
			<div className="max-w-8xl px-2 mx-auto">
				<div>
					<img
						src={cyou_logo_gray}
						alt="cyou로그"
						className="sm:block hidden h-6 my-2"
					/>
				</div>
				<div className="mb-2 font-normal" style={{ color: "#929292" }}>
					<div>창유닷컴코리아 유한회사</div>
					<div>
						대표자 : 더원천 | 사업자등록번호 : 214-88-54044 |
						통신판매사업신고번호 : 제 2019-서울강남-02875호 | 주소 : 서울특별시
						강남구 테헤란로 314, 6층 금성빌딩 | 이메일 :
						changyouhelp@gmail.com | 전화번호 : 02-2052-0190
					</div>
					<div className="mt-1">
						Copyright © Changyoukorea. All Rights Reserved.
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

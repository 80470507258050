import { Button } from "@material-ui/core";
import { useMediaQuery } from "react-responsive";
import React from "react";
import { cdnImg } from "../../../config/etc";

const bgComplete = cdnImg + "bg_complete.jpg";
const textComplete = cdnImg + "text_complete.png";
const bt_close = cdnImg + "bt_close.png";
const btConfirm = cdnImg + "bt_confirm.png";

export default function PopUp({ onClose, setActivePage }) {
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	return (
		<div
			className="absolute z-10 flex h-full w-full"
			style={{ backgroundColor: "#000000dd" }}
		>
			<div
				className="m-auto bg-cover"
				style={{
					backgroundImage: `url(${bgComplete})`,
					backgroundPosition: "center",
					// "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
					// borderColor: "#f4aa2f",
				}}
			>
				<div
					className="flex flex-col justify-between items-center max-w-3xl sm:w-3xl sm:h-3xl text-white font-bold border-4 border-yellow-500 p-2 bg-cover"
					style={{
						minHeight: 400,
						// backgroundImage: `url(${resultData[result].img})`,
						// backgroundPosition: resultData[result].backPosition,
						// backgroundSize: resultData[result].backSize + "%",
						// backgroundRepeat: "no-repeat",
					}}
				>
					<div className="w-full">
						<div className="w-full text-right">
							<Button onClick={onClose} className="">
								<img src={bt_close} alt="close" width={33} className="-mr-4" />
							</Button>
						</div>
						<div className="w-full justify-center flex px-4 pt-4" style={{}}>
							<img src={textComplete} alt="사전예약 완료" width={580} />
						</div>
					</div>

					<div className="flex flex-col items-center ">
						{/* <Button variant="contained">내 결과 공유하기</Button> */}
						<h3
							className="sm:mb-4 text-center text-white text-md sm:text-2xl text-shadow"
							style={{ textShadow: "2px 2px #000000aa" }}
						>
							<span style={{ color: "#f4aa2f" }}>
								{"<프리스타일2:레볼루션 플라잉덩크>"}
							</span>
							<span>만의</span>
							<br />
							<span>다양한 이벤트도 놓치지 마세요</span>
							<div className="flex justify-center items-center my-2 sm:my-4 font-normal text-xs">
								<div className="mr-4">
									<p>내게 어울리는 포지션은?</p>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											setActivePage();
											onClose();
										}}
									>
										포지션 테스트
									</Button>
								</div>
								<div>
									<p>최대 10만골드, 1천다이아</p>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											setActivePage(2);
											onClose();
										}}
									>
										친구초대 이벤트
									</Button>
								</div>
							</div>
						</h3>

						<div className="my-2 sm:my-4">
							<Button onClick={onClose}>
								<img
									src={btConfirm}
									alt="확인"
									style={{ width: isMobile ? 120 : 210 }}
								/>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

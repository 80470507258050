import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import FDMain from '../events/flyingDunk2/FDMain';

import Page404 from '../layout/Page404';
import AgreeTerms from '../pages/AgreeTerms';
import DeleteFB from '../pages/auth/DeleteFB';
import NickModify from '../pages/auth/NickModify';
import Oauth from '../pages/auth/Oauth';
import SNSConnect from '../pages/auth/SNSConnect';
import EmailAuth from '../pages/EmailAuth';
import ForgotPassword from '../pages/ForgotPassword';
import Game from '../pages/Game';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Member from '../pages/Member';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Register from '../pages/Register';
import ResetPassword from '../pages/ResetPassword';
import TermsOfUse from '../pages/TermsOfUse';

import ReactPixel from 'react-facebook-pixel';
import Center from '../events/flyingDunk2/postitionPages/Center';
import PowerForward from '../events/flyingDunk2/postitionPages/PowerForward';
import SmallForward from '../events/flyingDunk2/postitionPages/SmallForward';
import PointGuard from '../events/flyingDunk2/postitionPages/PointGuard';
import ShootingGuard from '../events/flyingDunk2/postitionPages/ShootingGuard';
import Redirect from './Redirect';

const Router = (props) => {
  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('189600383156434', options);
    ReactPixel.pageView();
  });
  return (
    <div className={(!props.isHeader ? 'mt-0' : 'mt-14') + ' block sm:mt-0'}>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/home" component={Home} exact />

        <Route path="/login" component={Login} exact />
        <Route path="/register" component={Register} exact />

        <Route path="/member" component={Member} exact />
        <Route path="/snsConnect" component={SNSConnect} exact />
        <Route path="/agreeTerms" component={AgreeTerms} exact />
        <Route path="/nickModify" component={NickModify} exact />
        <Route path="/Oauth" component={Oauth} exact />
        <Route path="/deleteFB" component={DeleteFB} exact />
        <Route path="/emailAuth" component={EmailAuth} exact />
        <Route path="/forgotPassword" component={ForgotPassword} exact />
        <Route
          path="/resetPassword/:resetToken"
          component={ResetPassword}
          exact
        />

        <Route path="/game/:id" component={Game} exact />

        <Route path="/flyingdunk2" component={FDMain} exact />
        <Route path="/position/center" component={Center} exact />
        <Route path="/position/powerForward" component={PowerForward} exact />
        <Route path="/position/smallForward" component={SmallForward} exact />
        <Route path="/position/pointGuard" component={PointGuard} exact />
        <Route path="/position/shootingGuard" component={ShootingGuard} exact />

        <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
        <Route path="/termsOfUse" component={TermsOfUse} exact />
        <Route path="/cypher" component={Redirect} exact />
        <Route component={Page404} />
      </Switch>
    </div>
  );
};

export default Router;

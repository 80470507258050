export const SNS = {
	KAKAO: "kakao",
	NAVER: "naver",
	GOOGLE: "google",
	FACEBOOK: "facebook",
	key: {
		kakao: "e545b33f51718fe391e475af62900da9",
		naver: "sKSmjamOfhhH7Hlx9wuH",
		google:
			"325325591704-f5vbqmu7en15mjl689qg4hb266u4rfv7.apps.googleusercontent.com",
		facebook: "170538884642757",
		// facebook: "857454198162517",
	},
	code: {
		kakao: "https://kauth.kakao.com/oauth/authorize",
		naver: "https://nid.naver.com/oauth2.0/authorize",
		google: "https://accounts.google.com/o/oauth2/auth",
		facebook: "https://www.facebook.com/v9.0/dialog/oauth",
	},
};

import React from 'react';
import { Button } from '@material-ui/core';
// import logo from "../../../assets/fd2/logo_744x504.png";
// import bt_preregist from "../../../assets/fd2/bt_preregist.png";
// import slogan from "../../../assets/fd2/slogan.png";
import { cdn, cdnImg } from '../../../config/etc';
import { useMediaQuery } from 'react-responsive';
import { useEventContext } from '../../../contexts/EventContext';
import { Fd2StoreDown } from '../components/FDDown';

const logo = cdnImg + 'logo_744x504.png';
const bt_preregist = cdnImg + 'bt_preregist.png';
const slogan = cdnImg + 'slogan.png';
export default function HeadVideo({ setActivePage }) {
  const isMobile = useMediaQuery({ query: '(max-width:600px)' });
  const { event } = useEventContext();
  return (
    <div className="relative w-full bg-black">
      <div className=" flex flex-col items-center w-full">
        <video
          className="absolute h-full w-full object-cover"
          autoPlay="autoplay"
          loop={true}
          muted="muted"
          playsInline
        >
          {isMobile ? (
            <source src={cdn + 'video/flyingdunk2.mp4'} type="video/mp4" />
          ) : (
            <source
              src={
                cdn +
                (event.dateState > 0 ? 'video/fd2live.mp4' : 'video/fd2.mp4')
              }
              type="video/mp4"
            />
          )}
        </video>
      </div>
      <div
        className="relative flex flex-col items-center justify-center p-8"
        style={{ height: window.innerHeight }}
        // style={{ height: window.innerHeight - props.height }}
      >
        {isMobile && (
          <>
            <img
              // src="https://cyougame.kr.object.ncloudstorage.com/images/logo3-1614294893935.png"
              src={logo}
              alt="logo"
              className="h- shadow-2  object-contain mb-4"
              style={{ width: 540 }}
            />
            <img
              src={slogan}
              className="mb-12"
              alt="No.1 농구게임의 귀환 프리스타일2:레볼루션 플라잉덩크"
              style={{ width: 328 }}
            />
          </>
        )}
        {event.dateState > 0 ? (
          <div
            style={{ marginTop: isMobile ? 0 : window.innerHeight / 2 + 100 }}
          >
            <Fd2StoreDown size={200} />
          </div>
        ) : (
          <Button
            style={{ marginTop: isMobile ? 0 : window.innerHeight / 2 + 100 }}
            onClick={() => {
              setActivePage(1);
            }}
          >
            {/* 사전 예약 ▶ */}
            <img src={bt_preregist} alt="사전예약" style={{ width: 437 }} />
          </Button>
        )}
      </div>
    </div>
  );
}

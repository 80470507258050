import axios from "axios";
import { apiServer } from "../config/servers";
import Analytics from "./Analytics";

axios.defaults.withCredentials = true;

export const signUpWithEmail = (data) => {
	return new Promise((resolve, reject) => {
		axios
			.post(apiServer + "/auth/register", data)
			.then((response) => {
				if (response.data.error) {
					reject(response.data.error);
				} else {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log("error: ", error.response);
				let errorResponse = {};
				if (error.response?.status === 500) {
					errorResponse.nickname = "이미 등록된 닉네임입니다.";
				} else {
					errorResponse.email = "이미 등록된 이메일입니다.";
				}
				reject(errorResponse);
			});
	});
};

export const signInWithEmail = (data) => {
	return new Promise((resolve, reject) => {
		axios
			.post(apiServer + "/auth/login", data)
			.then((response) => {
				if (response.data.error) {
					reject(response.data.error);
				} else {
					Analytics.onSignIn(response.data.user);
					resolve(response.data);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const jwtLogin = (token) =>
	axios.post(
		apiServer + "/auth/jwtLogin",
		{},
		{ headers: { Authorization: token } }
	);

export const getToken = () => axios.post(apiServer + "/auth/token");

export const logout = () => axios.delete(apiServer + "/auth/logout", {});

export const forgotPassword = ({ email }) =>
	axios.post(apiServer + "/auth/forgot-password", {
		email,
	});

export const resetPassword = (data, resetToken) => {
	if (resetToken)
		return axios.post(apiServer + "/auth/reset-password/" + resetToken, data);
	else return axios.post(apiServer + "/auth/reset-password-withAuth/", data);
};

export const sendAuthEmail = (data) =>
	axios.post(apiServer + "/auth/sendAuthEmail/", data);

export const confirmEmailAuthNumber = (data) =>
	axios.post(apiServer + "/auth/confirmEmailAuthNumber/", data);

export const signOut = (id, data) =>
	axios.delete(apiServer + "/auth/signOut/" + id, { data });
// ?delete의 경우 {data:{}} 의 형태로 config 값 전송하면 body.data로 수신 가능

export const editUser = (id, data) =>
	axios.put(apiServer + "/user/" + id, data);

// const setSession = (access_token) => {
// 	// console.log("access_token: ", access_token);
// 	if (access_token) {
// 		window.localStorage.setItem("jwt_access_token", access_token);
// 		axios.defaults.headers.common["Authorization"] = access_token;
// 	} else {
// 		window.localStorage.removeItem("jwt_access_token");
// 		delete axios.defaults.headers.common["Authorization"];
// 	}
// };

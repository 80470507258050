import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import Analytics from '../../../service/Analytics';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import { cdnImg } from '../../../config/etc';
import { BtScroll } from '../FDMain';
import { useEventContext } from '../../../contexts/EventContext';
// import FileCopyIcon from "@material-ui/icons/FileCopy";
// import skill1 from "../../../assets/fd2/PC-png/04_SNSchallenge/대웅팝업/전용스킬-1.png";
// import skill2 from "../../../assets/fd2/PC-png/04_SNSchallenge/대웅팝업/전용스킬-2.png";
// import skill3 from "../../../assets/fd2/PC-png/04_SNSchallenge/대웅팝업/전용스킬-3.png";
// import skill4 from "../../../assets/fd2/PC-png/04_SNSchallenge/대웅팝업/전용스킬-4.png";
// import skillZone from "../../../assets/fd2/PC-png/04_SNSchallenge/대웅팝업/존스킬.png";
// import skillSpecial from "../../../assets/fd2/PC-png/04_SNSchallenge/대웅팝업/특성.png";

const title = cdnImg + 'pc/4/타이틀.png';
const titleMo = cdnImg + 'pc/4/타이틀.png';
// const blind = cdnImg + '블라인드.jpg';
// const blindMo = cdnImg + '블라인드mo.jpg';
const bgContent = cdnImg + 'sns_new.jpg';
const bgContentMo = cdnImg + 'sns_new_mo.jpg';
// const bgContent = cdnImg + "bgContent.jpg";
// const bgContentMo = cdnImg + "bgContentMo.jpg";
const bg = cdnImg + 'bg4.jpg';
const btCopy = cdnImg + 'pc/4/copy-버튼.png';
const iconMusic = cdnImg + 'pc/4/서브타이틀.png';
const character = cdnImg + 'pc/4/pop/대웅-캐릭터.png';
const skillBox = cdnImg + 'pc/4/pop/스킬-box.png';
const name = cdnImg + 'pc/4/pop/이름.png';
const nike = cdnImg + 'nike.png';
const bgTest = cdnImg + 'bg_test.jpg';

// const nameMo = cdnImg + "mo/4/pop/이름.png";

const skills = cdnImg + 'skills_daewoong.png';
const daewoong = cdnImg + 'daewoong.jpg';
const btClose = cdnImg + 'bt_close.png';

export default function FDSNSChallenge(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [openDaewoong, setOpenDaewoong] = useState();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isBig = useMediaQuery({ query: '(min-height: 1107px)' });
  const { event } = useEventContext();

  const tag =
    '#프리스타일2레볼루션 #플덩2 #플덩2출시일\n#농구게임#플덩2_SNS챌린지 #농구가하고싶어요';
  const clickShare = (type) => {
    console.log('type: ', type);
    Analytics.logEvent('snsChallenge', { type });
  };
  return (
    <div
      // className="p-4 h-full max-w-2xl m-auto flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${bg})`,
        // "url('https://kr.object.ncloudstorage.com/cyougame/images/back_q.png')",
        borderColor: '#f4aa2f',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: !isBig ? '100%' : window.innerHeight,
      }}
    >
      <div
        className="h-full m-auto flex flex-col items-center justify-start text-white  px-2 sm:px-4 pt-14 pb-4 sm:py-16"
        // style={{
        // 	height: isMobile ? "100%" : window.innerHeight,
        // }}
      >
        {/* {status === "main" && ( */}
        <div className=" flex flex-col items-center">
          <img
            src={isMobile ? titleMo : title}
            alt="포지션 Test 공유 이벤트"
            width={700}
            style={{ marginLeft: isMobile ? 10 : 90 }}
          />
          <div className="flex items-center mt-4 mb-6">
            <h2
              className="mr-1 -mt-1 font-bold"
              style={{
                fontSize: isMobile ? 14 : 18,
                textShadow: '0px 2px 2px #000000aa',
              }}
            >
              힙하게 농구 즐기고 선물 받아가자
            </h2>
            <img src={iconMusic} alt="♪" width={14} />
          </div>
          {event.dateState > 1 ? (
            <div
              className="eventCoverBox flex flex-col justify-between text-center "
              style={{
                background: `linear-gradient(#000c 0%,#000a 100%), center / 1000px no-repeat url(${bgTest})`,
              }}
            >
              <div
                className="p-4 sm:p-10 flex flex-col justify-center"
                style={{ minHeight: '18rem' }}
              >
                <h1>
                  <b>SNS 챌린지 이벤트 종료!</b>
                </h1>
                <h1>감사합니다!</h1>
              </div>
              <span className="bottomBox px-10 py-3 text-lg">
                ※당첨자는 프리스타일2 레볼루션
                <br />
                <span
                  className="text-white cursor-pointer"
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/freestyle2_revolution/'
                    )
                  }
                >
                  공식 인스타그램
                </span>{' '}
                및
                <span
                  className="text-white cursor-pointer"
                  onClick={() =>
                    window.open('https://cafe.naver.com/flyingdunk2')
                  }
                >
                  {' '}
                  공식카페
                </span>
                에서 확인해주세요.
              </span>
            </div>
          ) : (
            <div className="relative flex items-center justify-between">
              {/* <div>참여방법</div> */}
              <img
                src={isMobile ? bgContentMo : bgContent}
                alt="참여방법"
                width={670}
                style={{ boxShadow: '8px 8px #00000055' }}
              />
              <div
                onClick={() => setOpenDaewoong(true)}
                className="absolute top-0 right-0 cursor-pointer"
                style={{
                  // background: "#fa0a",
                  width: isMobile ? '100%' : 280,
                  top: isMobile ? (window.innerWidth / 100) * 60 : 0,
                  height: isMobile ? (window.innerWidth / 100) * 50 : 250,
                }}
              ></div>
              {!isMobile && (
                <img
                  src={nike}
                  alt="나이키 에어조던"
                  className="absolute top-0 left-0 "
                  width={430}
                  style={{
                    marginLeft: -430,
                    marginTop: -90,
                    filter: 'drop-shadow(8px 8px #00000055)',
                  }}
                />
              )}
              <div className="absolute w-full justify-center bottom-6">
                {/* <ol>
								<li>
									인스타그램에서 본인의 하프라인슛 영상을 촬영한 영상 업로드
									(예시)
								</li>
								<li>아래 필수 해시태그와 함께 업로드 (전체공개 필수)</li>
							</ol> */}
                <CopyToClipboard
                  text={tag}
                  onCopy={() => {
                    enqueueSnackbar(
                      '해시태그가 복사되었습니다. Ctrl-v 로 붙여넣기 하세요.',
                      {
                        variant: 'success',
                      }
                    );
                  }}
                >
                  <div
                    className="flex flex-wrap flex-col-reverse sm:flex-row items-center justify-center "
                    onClick={() => clickShare('copyHashTag')}
                  >
                    <img
                      src={btCopy}
                      alt="복사"
                      width={isMobile ? 120 : 150}
                      className="z-10 cursor-pointer"
                    />
                    <div
                      className={
                        'font-bold cursor-text' + isMobile
                          ? ' my-2'
                          : ' -ml-4 pl-6 py-0 pr-12'
                      }
                      style={{
                        backgroundColor: '#fff',
                        color: '#7e2fc0',
                        borderWidth: 4,
                        borderColor: '#ffa900',
                        // width: 450,
                        fontSize: 12,
                      }}
                    >
                      <pre>{tag}</pre>
                      {/* <CopyText value={tag} readOnly fullWidth multiline="true" /> */}
                    </div>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          )}
          {/* <div className="flex items-center justify-between">
						<div>이벤트 경품</div>
						<div className="flex items-center">
							<div className="flex items-center">
								<img src="" alt="핸드폰" />
								<div>
									<div>1명</div>
									<div>최신형</div>
									<div>핸드폰 증정</div>
								</div>
							</div>
							<div className="flex items-center">
								<img src="" alt="전원 보상" />
								<div>
									<div>전원</div>
									<div>챌린지 참여자 대상</div>
									<div>인게임 아이템 증정</div>
								</div>
							</div>
						</div>
					</div> */}
        </div>
        <BtScroll setActivePage={props.setActivePage} />
        {openDaewoong && (
          <div
            className="absolute p-0 top-0 left-0 w-full h-full flex flex-col items-center sm:justify-center z-40"
            style={{ backgroundColor: isMobile ? '#000' : '#000000dd' }}
          >
            <div
              className={
                'relative mt-20 sm:m-auto p-0 sm:p-16 text-xxs sm:text-sm text-white '
              }
              style={
                {
                  // 	borderColor: "#f4aa2f",
                  // backgroundImage: `url(${bgPrivacy})`,
                  // 	backgroundSize: "cover",
                }
              }
            >
              <div className="absolute sm:top-10 right-0 sm:right-28 z-50 whitespace-pre-line  ">
                <Button onClick={() => setOpenDaewoong(false)}>
                  <div className={isMobile ? '' : 'p-1 bg-black'}>
                    <img src={btClose} alt="닫기" width={isMobile ? 33 : 33} />
                  </div>
                </Button>
              </div>
              {isMobile ? (
                <img
                  src={daewoong}
                  alt=""
                  style={{ width: 1440, marginTop: 0 }}
                />
              ) : (
                // <div
                // 	style={{
                // 		backgroundImage: `url(${daewoong})`,
                // 		backgroundSize: "cover",
                // 		backgroundPosition: "top center",
                // 		backgroundRepeat: "no-repeat",
                // 		width: "100%",
                // 		height: "100%",
                // 	}}
                // >
                // 	{/* <div className="flex flex-col items-center">
                // 		<img src={nameMo} alt="대웅 이름" style={{ width: 600 }} />
                // 		<div
                // 			style={{
                // 				backgroundImage: `url(${skillBox})`,
                // 				backgroundSize: "110%",
                // 				backgroundPosition: "bottom center",
                // 				backgroundRepeat: "no-repeat",
                // 			}}
                // 		>
                // 			<img
                // 				src={skills}
                // 				alt=""
                // 				style={{ width: 340, marginTop: -130 }}
                // 			/>
                // 		</div>
                // 	</div> */}
                // </div>
                <div className="flex items-center ">
                  <img
                    src={skillBox}
                    alt="스킬"
                    width={780}
                    style={{ marginTop: 10, marginRight: -550 }}
                  />
                  <img
                    src={skills}
                    alt="스킬"
                    width={350}
                    style={{ marginTop: 10, marginRight: -30 }}
                  />
                  <img
                    src={character}
                    alt="대웅 캐릭터"
                    width={450}
                    style={{ zIndex: 1 }}
                  />
                  <img
                    src={name}
                    alt="대웅 이름"
                    width={950}
                    style={{ marginTop: 10, marginLeft: -820 }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
// export const CopyText = withStyles((theme) => ({
// 	root: {
// 		"&:focus": { outline: 0 },
// 		backgroundColor: "#fff",
// 		borderWidth: 4,
// 		borderColor: "#f4aa2f",
// 		".MuiInputBase-root": {
// 			color: "#f0f",
// 		},
// 	},
// }))(TextField);

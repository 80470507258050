import { Button } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { Form } from "react-final-form";
import {
	composeValidators,
	minMaxValidator,
	requiredValidator,
} from "../utils/validator";
import InputAdapter from "./components/InputAdapter";
import createDecorator from "final-form-focus";
import { useSnackbar } from "notistack";
import { aniContainer, aniItem } from "../config/animation";
import { confirmEmailAuthNumber } from "../service/Auth";
import GoBack from "./components/GoBack";

const focusOnError = createDecorator();

const EmailAuth = (props) => {
	const data = props.location?.state;
	console.log("data: ", data);
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = (values) => {
		confirmEmailAuthNumber({ email: data.email, authNumber: values.authNumber })
			.then((res) => props.history.replace("/agreeTerms", data))
			.catch((err) => {
				enqueueSnackbar("인증 번호가 맞지 않습니다. 다시 확인해 주세요.", {
					variant: "error",
				});
			});
	};
	const onlyNumbers = (e) => {
		e.target.value = e.target.value.replace(/[^0-9]/g, "");
	};
	return (
		<div className="relative mxrounded-xl p-4 mt-4 w-full sm:max-w-lg mx-auto">
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors }) => (
					<motion.form
						variants={aniContainer}
						initial="hidden"
						animate="visible"
						onSubmit={handleSubmit}
						className="flex flex-col "
					>
						<motion.div variants={aniItem}>
							<GoBack />
						</motion.div>
						<motion.h1
							variants={aniItem}
							className="font-bold text-xl text-purple-600"
						>
							이메일 인증
						</motion.h1>
						<>
							<motion.h3 variants={aniItem} className="text-sm my-5">
								<b className="font-bold ">{data.email}</b>
								로 인증번호를 전송했습니다.
								<br />
								전송된 인증번호를 입력하면 회원가입이 진행됩니다.
								<br />
								인증번호 유효기간은 1시간입니다.
							</motion.h3>
							<div className=" space-y-2">
								<motion.div variants={aniItem}>
									<InputAdapter
										validator={composeValidators(
											requiredValidator,
											minMaxValidator
										)}
										spinner="true"
										name="authNumber"
										onInput={(e) => onlyNumbers(e)}
										title="인증 번호"
										inputProps={{ maxLength: 6, minLength: 6 }}
									/>
								</motion.div>

								<motion.div variants={aniItem}>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										disabled={
											submitting ||
											Object.keys(errors).length !== 0 ||
											pristine ||
											errors.length > 0
										}
										fullWidth={true}
									>
										계속 회원가입
									</Button>
								</motion.div>
							</div>
							<motion.div variants={aniItem} className="text-xxs mt-4 mb-2">
								* 간편가입(카카오톡, 네이버, 구글, 페이스북) 비밀번호 찾기는 각
								SNS서비스에서 가능합니다.
							</motion.div>
						</>
					</motion.form>
				)}
			/>
		</div>
	);
};

export default EmailAuth;

import React from "react";
import Button from "@material-ui/core/Button";
import SideMenu from "./SideMenu";
import {
	AppBar,
	IconButton,
	SwipeableDrawer,
	Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/logoCi.png";
import { useHistory } from "react-router-dom";
import { useLoginContext } from "../contexts/LoginContext";

export default function Header() {
	const [isSiderOpen, setIsSiderOpen] = React.useState(false);
	const history = useHistory();
	const { user } = useLoginContext();

	const toggleDrawer = () => {
		setIsSiderOpen(!isSiderOpen);
	};

	return (
		<header className="shadow-md fixed sm:relative w-full z-50">
			<AppBar position="static" color="default">
				<Toolbar className="flex justify-between items-center">
					<div className="flex items-center">
						<div className="sm:hidden">
							<IconButton
								edge="start"
								color="inherit"
								aria-label="menu"
								onClick={toggleDrawer}
							>
								<MenuIcon />
							</IconButton>
						</div>
						<img
							onClick={() => history.push("/")}
							src={logo}
							className="max-h-8 cursor-pointer"
							alt="cyou 로고"
						/>
					</div>
					{!user.id ? (
						<div className="sm:hidden -mr-2">
							<Button
								onClick={() => history.push("/login")}
								color="inherit"
								// variant="outlined"
								edge="end"
							>
								<b>로그인</b>
							</Button>
						</div>
					) : (
						<div className="sm:hidden -mr-2">
							<Button
								onClick={() => history.push("/member")}
								color="inherit"
								// variant="outlined"
								edge="end"
							>
								<b className="text-purple-600">{user.nickname}</b>님
							</Button>
						</div>
					)}
				</Toolbar>
			</AppBar>
			<SwipeableDrawer
				anchor={"left"}
				open={isSiderOpen}
				onClose={toggleDrawer}
				onOpen={toggleDrawer}
			>
				<div onClick={toggleDrawer} className="h-full">
					<SideMenu />
				</div>
			</SwipeableDrawer>
		</header>
	);
}

import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import Meta from "./components/Meta";
import LoginBox from "./auth/LoginBox";
import SNSBox from "./auth/SNSBox";
import { useLoginContext } from "../contexts/LoginContext";
import UserInfo from "./auth/UserInfo";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { readDatas } from "../service/Content";
import _ from "../utils/@lodash";
import ZCard from "./components/ZCard";
import MainSlider from "./components/MainSlider";
import { motion } from "framer-motion";
import LoginDivider from "./components/LoginDivider";
import { Divider } from "@material-ui/core";
import MobileGames from "./components/MobileGames";

export default function Home() {
	// const history = useHistory();
	const { user } = useLoginContext();
	const [games, setGames] = useState();
	useEffect(() => {
		const localGames = localStorage.getItem("games");
		if (localGames) setGames(JSON.parse(localGames));
		readDatas("game").then((gameRes) => {
			let gamesData = gameRes.data.data.filter(
				(game) => game.is_active === true
			);
			// setGames(gamesData);
			readDatas("cafenotice").then((cafeRes) => {
				let cafesData = cafeRes.data.data;
				// const mergedData = _.mergeWith(cafesData, gamesData);
				// setGames(mergedData);
				let mergedData = gamesData.map((game) => {
					return _.merge(
						cafesData.filter((cafe) => game.cafe_id === cafe.cafe_id)[0],
						game
					);
				});
				localStorage.setItem("games", JSON.stringify(mergedData));
				setGames(mergedData);
			});
		});

		// return () => {};
	}, []);
	const container = {
		visible: {
			transition: {
				delayChildren: 0.3,
				staggerChildren: 0.3,
			},
		},
	};
	const item = {
		hidden: { y: -40, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	};
	return (
		<>
			<Meta
				data={{
					title: "cyou 게임",
					description: "언제나 즐거운 게이머들의 공간 cyou 게임",
					image:
						"https://cyougame.kr.object.ncloudstorage.com/images/logoCi-1614566516592.png",
					width: "132",
					height: "52",
				}}
			/>
			<div className="">
				<motion.div
					initial={{ transform: "translateY(-50px)", opacity: 0 }}
					animate={{ transform: "translateY(0px)", opacity: 1 }}
					className=" mb-12 "
				>
					<MainSlider />
				</motion.div>
				<motion.div
					initial={{ transform: "translateX(50px)", opacity: 0 }}
					animate={{ transform: "translateX(0px)", opacity: 1 }}
					transition={{
						type: "spring",
						stiffness: 260,
						damping: 20,
						delay: 1,
					}}
					className="px-4 sm:px-2 mb-4 mont text-2xl max-w-8xl mx-auto"
				>
					Mobile Games
				</motion.div>
				<div className="flex w-full items-start mt-4 justify-center ">
					{games && (
						<motion.div
							variants={container}
							initial="hidden"
							animate="visible"
							className="flex flex-col sm:flex-row sm:flex-wrap items-center justify-center max-w-full sm:max-w-5xl px-2 sm:px-0"
						>
							{games.map((game, idx) => {
								if (game.is_active) {
									return (
										<motion.div
											key={idx}
											variants={item}
											className="ani_container m-2 mt-0 max-w-full min-w-5xl px-2 sm:px-0 w-full sm:max-w-sm flex-1"
										>
											<ZCard game={game} />
										</motion.div>
									);
								} else {
									return null;
								}
							})}
						</motion.div>
					)}
					<div className="flex justify-start items-start max-w-full">
						<div className="loginBox shadow rounded bg-white hidden sm:block mx-2 px-4 min-w-6xl max-w-xs">
							{!user.id ? (
								<motion.div
									initial={{ scale: 0 }}
									animate={{ scale: 1 }}
									transition={{
										duration: 0.2,
									}}
								>
									<LoginBox />
									<LoginDivider />
									<SNSBox />
								</motion.div>
							) : (
								<motion.div
									initial={{ scale: 0 }}
									animate={{ scale: 1 }}
									transition={{
										type: "spring",
										stiffness: 260,
										damping: 20,
										duration: 0.2,
									}}
								>
									<UserInfo />
									<Divider />
									<MobileGames games={games} />
								</motion.div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

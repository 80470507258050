import React, { useEffect, useState } from "react";
import { useLoginContext } from "../../contexts/LoginContext";
import { withStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import { aniContainer, aniItem } from "../../config/animation";
import GoBack from "../components/GoBack";
import { getPointTotal } from "../../service/Point";
import { DataGrid } from "@material-ui/data-grid";
import moment from "moment";

const chargeColumns = [
	{
		field: "desc",
		headerName: "내역",
		width: 170,
	},
	{
		field: "amount",
		headerName: "수량",
		width: 70,
		type: "number",
	},
	{
		field: "kind",
		headerName: "종류",
		width: 50,
		menu: false,
	},
	{
		field: "createdAt",
		headerName: "충전",
		width: 110,
	},
	{
		field: "expiresAt",
		headerName: "소멸",
		width: 70,
	},
];
const spendColumns = [
	{
		field: "desc",
		headerName: "내역",
		width: 220,
	},
	{
		field: "amount",
		headerName: "수량",
		width: 100,
		type: "number",
	},
	{
		field: "createdAt",
		headerName: "사용",
		width: 150,
	},
];

const PointDataGrid = withStyles((theme) => ({
	root: {
		fontSize: "12px",
	},
}))(DataGrid);

const PointHistory = (props) => {
	const { user, setUser } = useLoginContext();
	const [point, setPoint] = useState();
	function localNumber(value) {
		return new Intl.NumberFormat("ko-KR").format(value ?? 0);
	}
	useEffect(() => {
		if (user.id)
			getPointTotal(user.id).then((res) => {
				res.data.charge?.map((c) => {
					c.id = c._id;
					c.kind = c.isFree ? "무료" : "캐시";
					c.expiresAt = moment(c.expiresAt).format("YY-MM-DD");
					c.createdAt = moment(c.createdAt).format("YY-MM-DD HH:mm");
					return c;
				});
				res.data.spend?.map((c) => {
					c.id = c._id;
					c.createdAt = moment(c.createdAt).format("YY-MM-DD HH:mm");
					return c;
				});
				res.data.charge = res.data.charge?.reverse();
				res.data.spend = res.data.spend?.reverse();
				setPoint(res.data);
				setUser({
					user: {
						...user,
						point_cash: res.data.point_cash,
						point_free: res.data.point_free,
					},
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id]);
	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className=" p-4 sm:mt-4"
		>
			<motion.div variants={aniItem} className="sm:hidden">
				<GoBack isMember={true} setTab={props.setTab} />
			</motion.div>
			<motion.h1
				variants={aniItem}
				className="font-bold text-xl text-purple-600 mb-6"
			>
				포인트 내역
			</motion.h1>
			<motion.div
				variants={aniItem}
				className="flex justify-between px-4 py-2 text-sm bg-white border border-gray-200 mb-2"
			>
				<b>
					<b className="text-purple-600">cyou</b> 캐시 포인트
				</b>
				<b className="text-purple-600 transition-500">
					{localNumber(point?.point_cash ?? user.point_cash)}
				</b>
			</motion.div>
			<motion.div
				variants={aniItem}
				className="flex justify-between px-4 py-2 text-sm bg-white border border-gray-200 mb-2"
			>
				<b>
					<b className="text-purple-600">cyou</b> 무료 포인트
				</b>
				<b className="text-purple-600">
					{localNumber(point?.point_free ?? user.point_free)}
				</b>
			</motion.div>
			<div>
				<motion.h2 variants={aniItem} className="font-bold mt-4 mb-2">
					충전 내역
				</motion.h2>
				<motion.div variants={aniItem}>
					<div className="w-full h-64">
						<PointDataGrid
							rows={point?.charge ?? []}
							columns={chargeColumns}
							pageSize={5}
							rowHeight={46}
							density="compact"
							LocaleText={{ noRowsLabel: "자료가 없습니다." }}
						/>
					</div>
				</motion.div>
				<motion.h2 variants={aniItem} className="font-bold mt-4 mb-2">
					사용 내역
				</motion.h2>
				<motion.div variants={aniItem}>
					<div className="w-full h-64">
						<PointDataGrid
							rows={point?.spend ?? []}
							columns={spendColumns}
							pageSize={5}
							rowHeight={46}
							density="compact"
							LocaleText={{ noRowsLabel: "자료가 없습니다." }}
						/>
					</div>
				</motion.div>
			</div>
		</motion.div>
	);
};
export default PointHistory;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const DeleteFB = () => {
	useEffect(() => {}, []);

	return (
		<div className="h-full w-full flex justify-center items-center">
			<div className="h-full flex flex-col mt-32 justify-center items-center">
				<h1>
					보유한 캐시포인트에 대해 확인 후 환불 요청 및 탈퇴 처리 하실 수
					있습니다.
				</h1>
				<Link
					to={{ pathname: "/member", state: { tab: 4 } }}
					className="mt-8 p-3 border border-gray-200 cursor-pointer"
				>
					탈퇴 및 환불 요청하러 가기
				</Link>
			</div>
		</div>
	);
};

export default DeleteFB;

import { createMuiTheme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { koKR } from "@material-ui/core/locale";

const theme = createMuiTheme(
	{
		typography: {
			fontFamily: "Noto Sans KR, sans-serif",
			/* 폰트 바꾸려면 App.css 파일에서도 수정해줘야 함 */
		},
		palette: {
			primary: {
				// main: purple[400],
				main: "#AB39FF",
			},
			secondary: {
				main: green[500],
			},
			// default: {
			// 	main: "#F2a2F2",
			// },
		},
	},
	koKR
);

export default theme;

import { Button, withStyles } from '@material-ui/core';
import { cdnImg } from '../../../config/etc';

export const Fd2StoreDown = ({ size }) => {
  const btGoogle = cdnImg + '구글스토어.png';
  const btApp = cdnImg + '앱스토어.png';
  const btOne = cdnImg + '원스토어.png';
  return (
    <div className="flex items-center space-x-2">
      <DownButton
        onClick={() => window.open('https://fd2ios.onelink.me/gD81/d82620dc')}
      >
        <img src={btApp} alt="appstore" width={size} />
      </DownButton>
      <DownButton
        onClick={() => window.open('https://fd2gp.onelink.me/Q3Hq/18c294b2')}
      >
        <img src={btGoogle} alt="googleplay" width={size} />
      </DownButton>
      {/* 원스토어 링크 입력 해야 함  */}
      <DownButton onClick={() => window.open('https://onesto.re/0000756383')}>
        <img src={btOne} alt="onestore" width={size} />
      </DownButton>
    </div>
  );
};
const DownButton = withStyles({
  root: {
    padding: 0,
    margin: 0,
  },
})(Button);

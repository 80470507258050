import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useLoginContext } from "../../contexts/LoginContext";
import { useCookies } from "react-cookie";

const LoginRedirect = () => {
	const history = useHistory();
	const { user } = useLoginContext();
	const [cookies] = useCookies();

	useEffect(() => {
		if (user.id) {
			if (cookies.from) {
				history.replace(cookies.from);
			} else {
				history.replace("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.id]);
	return <div></div>;
};

export default LoginRedirect;

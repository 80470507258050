import React from "react";
import LoginBox from "./auth/LoginBox";
import SNSBox from "./auth/SNSBox";
import LoginRedirect from "./components/LoginRedirect";
import LoginDivider from "./components/LoginDivider";
import GoBack from "./components/GoBack";

const Login = () => {
	return (
		<div className="m-auto max-w-lg p-4 mt-4">
			<LoginRedirect />
			<GoBack />
			<h1 className="font-bold text-xl text-purple-600">로그인</h1>
			<LoginBox />
			<LoginDivider />
			<SNSBox isLogin={true} />
		</div>
	);
};

export default Login;

import React, { useEffect, useState } from 'react';
import { RadioGroup, Radio, FormControlLabel, Button } from '@material-ui/core';
import { Form } from 'react-final-form';
import CheckAdapter from '../../../pages/components/CheckAdapter';
import createDecorator from 'final-form-focus';
import { requiredValidator } from '../../../utils/validator';
import { useCookies } from 'react-cookie';
import { useEventContext } from '../../../contexts/EventContext';

import ReactPixel from 'react-facebook-pixel';
import {
  checkDuplicatedPhone,
  getEventSetting,
  sendSMS,
  setPreviewEvent,
  setPreviewRegist,
} from '../../../service/Events';

import { useSnackbar } from 'notistack';
import PopUp from '../components/PopUp';
import { useMediaQuery } from 'react-responsive';
import Analytics from '../../../service/Analytics';
import { kakaoPixelId } from '../../../config/firebase';
import { cdnImg } from '../../../config/etc';
import { BtScroll } from '../FDMain';
import { usePopContext } from '../../../contexts/PopContext';
import { withStyles } from '@material-ui/styles';
import { readData } from '../../../service/Content';

// const logo = cdnImg + "logo.png";
// const background = cdnImg + "bg_preregister.jpg";
const logo_new = cdnImg + 'logo_new.png';
const sub_preregist_new = cdnImg + 'sub_preregist_new.png';
const background = cdnImg + 'bg_preregist_new.jpg';
const backgroundMo = cdnImg + 'mo/2/bg.jpg';
const btPreregist = cdnImg + 'bt_preregist_small.png';
const btSMS = cdnImg + 'bt_sms.png';
const gold = cdnImg + 'gold.png';
const diamond = cdnImg + 'diamond.png';
const drink = cdnImg + 'drink10.png';
const drink5 = cdnImg + 'drink5.png';
const textReward = cdnImg + 'text_pre_reward.png';
const btClose = cdnImg + 'bt_close.png';
const bgPrivacy = cdnImg + 'bg_privacy.jpg';

// const bgReward = cdnImg + "bg_pre_reward.png";
// const btGoogle = cdnImg + "bt_google.png";
// const btIOS = cdnImg + "bt_ios.png";
// const btOnestore = cdnImg + "bt_onestore.png";
const bgReward = cdnImg + 'bg_reward_pre.png';
const btGoogle = cdnImg + 'bt_playstore.png';
const btIOS = cdnImg + 'bt_appstore.png';
const btOnestore = cdnImg + 'bt_1store.png';

const bgReward_mo = cdnImg + 'bg_reward_pre_mo.png';
const btGoogle_mo = cdnImg + 'bt_playstore_mo.png';
const btIOS_mo = cdnImg + 'bt_appstore_mo.png';
const btOnestore_mo = cdnImg + 'bt_onsestore_mo.png';

const line_plus = cdnImg + 'line_plus.png';
const line_plus_mo = cdnImg + 'line_plus_mo.png';
const float_second = cdnImg + 'float_second.png';
const txt_reward_first = cdnImg + 'txt_reward_first.png';
const txt_reward_first_mo = cdnImg + 'txt_reward_first_mo.png';
const txt_reward_second = cdnImg + 'txt_reward_second.png';
const bg_reward_second = cdnImg + 'bg_reward_second.png';

const focusOnError = createDecorator();

const orange = '#f4aa2f';
const backInput = '#333';

export default function FDPreRegister(props) {
  const [cookies, setCookie] = useCookies();
  const { setEvent } = useEventContext();

  const [selectedOS, setSelectedOS] = useState(false);
  const [useAuth, setUseAuth] = useState(true);
  const [useDown, setUseDown] = useState(true);
  const [game, setGame] = useState();

  const [authNumber, setAuthNumber] = useState('111111');
  const [confirmSMS, setConfirmSMS] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneOk, setPhoneOk] = useState(false);
  const [isPhoneEntered, setIsPhoneEntered] = useState();

  const [agree_private, setAgree_private] = useState(false);
  const [agree_fdInfo, setAgree_fdInfo] = useState(false);
  const [agree_marketing, setAgree_marketing] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { setPopup } = usePopContext();

  const [isDuplicated, setIsDuplicated] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPopupClosed, setIsPopupClosed] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isBig = useMediaQuery({ query: '(min-height: 1110px)' });

  useEffect(() => {
    getEventSetting()
      .then((res) => {
        setUseAuth(res.data.useAuth);
        setUseDown(res.data.useDown);
      })
      .catch((err) => {
        console.error(err);
      });

    readData('game', '6152e4ce8dd99f97d436631c')
      .then((res) => {
        setGame(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const handleChange = (event) => {
    setSelectedOS(event.target.value);
  };

  const normalizePhone = (val) => {
    if (
      val.replace(/[- ]/g, '').length <= 8 &&
      val.replace(/[- ]/g, '').length >= 7 &&
      !isNaN(val.replace(/[- ]/g, ''))
    ) {
      const phoneTrim = val.replace(/[- ]/g, '');
      let phoneFinal;
      if (phoneTrim.length > 7) {
        phoneFinal =
          phoneTrim.substring(0, 4) + '-' + phoneTrim.substring(4, 8);
      } else {
        phoneFinal =
          phoneTrim.substring(0, 3) + '-' + phoneTrim.substring(3, 8);
      }
      setPhone(phoneFinal);
      setPhoneOk(true);
      checkDuplicatedPhone('010-' + phoneFinal).then((res) => {
        setIsDuplicated(res.data);
      });
    } else {
      setPhone(val);
      setPhoneOk(false);
      setIsDuplicated(false);
    }
  };
  const alert = (text, variant) => {
    if (isMobile) {
      enqueueSnackbar(text, { variant });
    } else {
      setPopup({
        text,
      });
    }
  };
  const onAuthSMS = () => {
    if (isSend) {
      setIsSend(false);
      setPhone('');
      return;
    }
    let tempNumber = Math.floor(Math.random() * 8999) + 1000;
    // console.log("tempNumber: ", tempNumber);
    setAuthNumber(tempNumber);
    sendSMS(phone, tempNumber).then((res) => {
      if (res.data?.duplicated) {
        alert('이미 신청이 완료된 전화번호 입니다.', 'warning');
      } else if (res.data?.message !== 'success') {
        alert(
          '문자 발송에 문제가 발생했습니다.\n 잠시 후 다시 시도해 주세요.',
          'error'
        );
      } else {
        setIsSend(true);

        alert(
          '인증 문자가 발송되었습니다.\n 인증번호를 확인하여 입력해 주세요.',
          'success'
        );
      }
    });
  };
  const onConfirmSMS = () => {
    // console.log("confirmSMS: ", confirmSMS);
    // console.log("authNumber: ", authNumber);
    if (Number(confirmSMS) === authNumber) {
      // console.log("인증번호 확인");
      setIsConfirmed(true);
      alert('인증이 완료되었습니다. ', 'success');
    } else {
      // console.log("틀렸어! 인증 다시 해!");
      alert(
        '인증번호가 맞지 않습니다.\n 정확한 인증 번호를 입력해 주세요.',
        'error'
      );
    }
  };
  const onStore = (store) => {
    setCookie(store, 'true');
    console.log('"store_" + store: ', 'store_' + store);
    Analytics.logEvent('store_' + store, {
      utm_source: cookies.utm_source,
      utm_medium: cookies.utm_medium,
    });
    switch (store) {
      case 'google':
        window.open(game?.play_store_url, '_store');
        break;
      case 'ios':
        window.open(game?.app_store_url, '_store');
        break;
      case 'onestore':
        window.open(game?.one_store_url, '_store');
        break;
      default:
        break;
    }
  };
  const onRegister = (values) => {
    // console.info("values", values);
    // console.log("agree_private: ", agree_private);
    // console.log("agree_fdInfo: ", agree_fdInfo);
    // console.log("agree_marketing: ", agree_marketing);
    // console.log("selectedOS: ", selectedOS);
    // console.log("phone: ", "010-" + phone);
    // console.log("refer: ", cookies.referral ?? "");
    let errMsg;
    // if (!agree_marketing)
    // 	errMsg =
    // 		"경품 전송 및 이벤트 알림을 위한 게임 관련 마케팅 정보 수신 동의에 체크해 주세요.";
    if (!agree_fdInfo)
      errMsg =
        '프리스타일2 레볼루션 플라잉덩크 게임 정보 수신에 동의해주셔야\n 사전예약 참여가 가능합니다.';
    if (!agree_private)
      errMsg = '개인정보 수집에 동의해주셔야\n 사전예약 참여가 가능합니다.';

    if (!isConfirmed && useAuth) errMsg = '인증번호를 확인해 주세요.';
    if (
      phone.replace(/[- ]/g, '').length > 8 ||
      phone.replace(/[- ]/g, '').length < 7 ||
      isNaN(phone.replace(/[- ]/g, ''))
    )
      errMsg = '올바른 전화번호를 입력해주세요.';
    if (!phone) errMsg = '전화번호를 입력해주세요.';
    if (!selectedOS) errMsg = 'Android 또는 iOS를 선택해 주세요.';

    if (errMsg) {
      alert(errMsg, 'error');
      return;
    }

    let data = {
      os: selectedOS,
      phone: '010-' + phone,
      marketing: agree_marketing,
      refer: cookies.referral,
      utm_source: cookies.utm_source,
      utm_medium: cookies.utm_medium,
      adkey: cookies.adkey,
      shared: cookies.share,
    };
    setEvent(data);
    setPreviewRegist(data)
      .then((res) => {
        setIsCompleted(true);
        // enqueueSnackbar("사전 예약 신청이 완료되었습니다.", {
        // 	variant: "success",
        // });
        setCookie('registered', 'true');

        ReactPixel.track('CompleteRegistration', data.phone);
        Analytics.logEvent('preRegist', {
          phone: data.phone,
          refer: data.refer,
        });
        window.gtag_report_conversion();
        if (window.kakaoPixel) {
          window.kakaoPixel(kakaoPixelId).pageView();
          window.kakaoPixel(kakaoPixelId).completeRegistration('사전예약완료');
        }
        if (window.TnkSession) {
          window.TnkSession.actionCompleted();
        }
        if (window.AFCTV) {
          window.AFCTV.onAction(
            (clickId) => console.log('afctv clickId:', clickId),
            (message) => console.log('afctv error:', message)
          );
        }
      })
      .catch((e) => {
        console.log('e: ', e);
        enqueueSnackbar(
          '서버에 문제가 발생했습니다. 잠시 후 다시 시도해 주세요.',
          {
            variant: 'error',
          }
        );
      });

    if (data.refer) {
      setPreviewEvent({ uid: data.refer, referPhones: data.phone });
    }
  };
  const sizeRewardMo = 60;
  const sizeReward = 80;
  return (
    <div
      className="preregister "
      style={{
        backgroundImage: `url(${isMobile ? backgroundMo : background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundAttachment: 'fixed',
        paddingTop: isMobile ? 60 : 0,
        height: !isBig ? '100%' : window.innerHeight,
      }}
    >
      <div
        className=" h-full m-auto flex flex-col items-center justify-center text-white px-2 sm:px-4 pt-4 pb-4 sm:py-16"
        style={
          {
            // height: window.innerHeight,
          }
        }
      >
        {isCompleted && !isPopupClosed && (
          <PopUp
            onClose={() => setIsPopupClosed(true)}
            setActivePage={props.setActivePage}
          />
        )}
        <img
          src={logo_new}
          alt="프리스타일2:레볼루션 플라잉덩크"
          width={isMobile ? 120 : 150}
        />
        <img
          src={sub_preregist_new}
          alt="프리스타일2:레볼루션 플라잉덩크"
          width={isMobile ? 300 : 390}
          style={{ marginTop: 10 }}
        />
        <div className="text-xs sm:text-sm my-2">
          사전예약 기간 2021.09.16 ~ 추후 별도 공지 시 까지
        </div>

        <div
          className={(isMobile ? 'p-4' : 'p-2') + ' relative'}
          style={{
            backgroundColor: '#000000aa',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {!isMobile && (
            <img
              className="absolute -top-64 -right-64"
              src={float_second}
              alt="2일차 보상"
              width={250}
            />
          )}
          <div
            className="flex flex-col items-center border-8 border-white "
            style={{
              backgroundImage: `url(${isMobile ? bgReward_mo : bgReward})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              marginRight: isMobile ? 0 : 14,
              minWidth: isMobile ? 0 : 370,
            }}
          >
            <div className="w-full flex flex-col items-center py-2 px-4 ">
              <img src={textReward} alt="사전예약 보상" width={260} />
              <div className="rewardImages w-full flex justify-around items-center text-center text-xs font-bold  text-black">
                <div className="flex flex-col items-center flex-1">
                  <img
                    src={diamond}
                    alt="200 다이아"
                    width={isMobile ? sizeRewardMo : sizeReward}
                  />
                  <p>200 다이아</p>
                </div>
                <div className="flex flex-col items-center flex-1">
                  <img
                    src={gold}
                    alt="20,000 골드"
                    width={isMobile ? sizeRewardMo : sizeReward}
                  />
                  <p>20,000 골드</p>
                </div>
                <div className="flex flex-col items-center flex-1">
                  <img
                    src={drink}
                    alt="에너지 음료 X 10"
                    width={isMobile ? sizeRewardMo : sizeReward}
                  />
                  <p>에너지 음료 X 10</p>
                </div>
              </div>
              <img
                src={isMobile ? line_plus_mo : line_plus}
                alt="구분선"
                width={isMobile ? '350' : 160}
                className="my-3"
              />
              <img
                src={isMobile ? txt_reward_first_mo : txt_reward_first}
                alt="스토어 접속 보상"
                width={260}
                className="mb-2"
              />
              <div className="rewardImages w-full flex justify-around items-center text-center text-xs font-bold text-black">
                <div className="flex flex-col items-center flex-1">
                  <img
                    src={diamond}
                    alt="100 다이아"
                    width={isMobile ? sizeRewardMo : sizeReward}
                  />
                  <p>100 다이아</p>
                </div>
                <div className="flex flex-col items-center flex-1">
                  <img
                    src={gold}
                    alt="10,000 골드"
                    width={isMobile ? sizeRewardMo : sizeReward}
                  />
                  <p>10,000 골드</p>
                </div>
                <div className="flex flex-col items-center flex-1">
                  <img
                    src={drink5}
                    alt="에너지 음료 X 10"
                    width={isMobile ? sizeRewardMo : sizeReward}
                  />
                  <p>에너지 음료 X 5</p>
                </div>
              </div>
            </div>
            {isMobile && (
              <div
                className="w-full flex justify-end mt-4 p-4 border-4"
                style={{
                  background: `url(${bg_reward_second})`,
                  backgroundSize: 'cover',
                  borderColor: '#394259',
                }}
              >
                <img src={txt_reward_second} alt="" width={100} />
              </div>
            )}
          </div>

          <Form
            onSubmit={onRegister}
            decorators={[focusOnError]}
            render={({
              handleSubmit,
              submitting,
              pristine,
              errors,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center"
              >
                <div className="selectOS my-2">
                  <RadioGroup
                    style={{
                      flexDirection: 'row',
                    }}
                    aria-label="os"
                    color="primary"
                    name="os"
                    value={selectedOS}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      style={{ marginRight: 50 }}
                      value="android"
                      color="primary"
                      control={<Radio />}
                      label="Android"
                    />
                    <FormControlLabel
                      value="iOS"
                      color="primary"
                      control={<Radio />}
                      label="iOS"
                    />
                  </RadioGroup>
                </div>
                <div className="flex items-start ">
                  <div>
                    <div
                      className="inputPhone flex items-center border  px-4 py-0 text-xl font-bold"
                      style={{
                        backgroundColor: backInput,
                        borderColor: orange,
                        borderWidth: 2,
                      }}
                    >
                      <span className="" style={{ width: 54, color: orange }}>
                        010 -
                      </span>
                      <input
                        className="font-bold "
                        style={{
                          backgroundColor: backInput,
                          // border: 0,
                          width: (isMobile ? 120 : 160) + (useAuth ? 0 : 100),
                          height: 36,
                          textAlign: 'center',
                          color: orange,
                          // lineHeight: "normal",
                        }}
                        name="phone"
                        value={phone}
                        maxLength={9}
                        onChange={(val) => normalizePhone(val.target.value)}
                        onPointerLeave={() => setIsPhoneEntered(true)}
                        placeholder="(-)없이 번호 숫자만 입력"
                        validators={[requiredValidator]}
                        disabled={isConfirmed || isCompleted}
                      />
                    </div>
                    {isPhoneEntered && phone && !phoneOk && (
                      <span className="text-red-600 text-xs">
                        올바른 전화번호를 입력해 주세요.
                      </span>
                    )}
                    {isDuplicated && (
                      <span className="text-red-600 text-xs">
                        이미 등록된 전화번호 입니다.
                      </span>
                    )}
                  </div>
                  {useAuth && (
                    <Button
                      style={{ padding: 0, marginLeft: 3 }}
                      onClick={onAuthSMS}
                      disabled={
                        isDuplicated ||
                        isConfirmed ||
                        !phone ||
                        (isPhoneEntered && phone && !phoneOk)
                      }
                    >
                      <ButtonText>
                        {isConfirmed
                          ? '인증 완료'
                          : isSend
                          ? '인증번호 재발송'
                          : '인증번호 발송'}
                      </ButtonText>
                    </Button>
                  )}
                </div>
                {isSend && !isConfirmed && (
                  <div className="flex mt-1">
                    <div>
                      <input
                        className="px-4 py-2 font-bold"
                        style={{
                          backgroundColor: backInput,
                          borderColor: orange,
                          borderWidth: 2,
                          width: isMobile ? 210 : 250,
                          height: 40,
                          textAlign: 'center',
                          fontSize: isMobile ? 14 : 20,
                          color: orange,
                        }}
                        name="confirmSMS"
                        value={confirmSMS}
                        onChange={(val) => setConfirmSMS(val.target.value)}
                        maxLength={4}
                        placeholder="인증번호를 입력해 주세요."
                        validators={[requiredValidator]}
                      />
                      {/* {isPhoneEntered &&
									phone &&
									(phone.replace(/[- ]/g, "").length > 8 ||
										phone.replace(/[- ]/g, "").length < 7 ||
										isNaN(phone.replace(/[- ]/g, ""))) && (
										<span className="text-red-600">
											올바른 휴대폰 번호 형식을 입력해 주세요.
										</span>
									)} */}
                    </div>
                    <Button
                      style={{ padding: 0, marginLeft: 3 }}
                      onClick={onConfirmSMS}
                    >
                      <ButtonText>인증번호 확인</ButtonText>
                    </Button>
                  </div>
                )}

                <div className="checkAgreement text-xxs w-full mt-3 mb-2">
                  <CheckAdapter
                    name="agree_all"
                    label={<b>내용을 확인하였으며, 모두 동의합니다.</b>}
                    checked={agree_private && agree_fdInfo && agree_marketing}
                    onClick={(e) => {
                      console.log(submitting, Object.keys(errors), errors);
                      setAgree_private(e.target.checked);
                      setAgree_fdInfo(e.target.checked);
                      setAgree_marketing(e.target.checked);
                    }}
                    type="fd2"
                  />
                  <div className="flex items-center">
                    <CheckAdapter
                      name="agree_private"
                      checked={agree_private}
                      onClick={(e) => {
                        setAgree_private(e.target.checked);
                      }}
                      label={
                        <span className="">
                          [필수] 개인정보 수집 및 이용 동의{' '}
                        </span>
                      }
                      type="fd2"
                    />
                    <span
                      className="ml-5 cursor-pointer"
                      onClick={() => setOpenPrivacy(true)}
                    >
                      {'자세히 보기 >>'}
                    </span>
                  </div>
                  <CheckAdapter
                    name="agree_fdInfo"
                    checked={agree_fdInfo}
                    onClick={(e) => {
                      setAgree_fdInfo(e.target.checked);
                    }}
                    label={
                      <span className="">
                        [필수] 프리스타일2 레볼루션 플라잉덩크(플덩2) 게임 정보
                        수신 동의
                      </span>
                    }
                    type="fd2"
                  />
                  <CheckAdapter
                    name="agree_marketing"
                    checked={agree_marketing}
                    onClick={(e) => {
                      setAgree_marketing(e.target.checked);
                    }}
                    label={
                      <span className="">
                        [선택] 창유 게임 관련 마케팅 정보 수신 동의
                      </span>
                    }
                    type="fd2"
                  />
                </div>
                <Button
                  type="submit"
                  // variant="contained"
                  disabled={
                    isDuplicated ||
                    submitting ||
                    Object.keys(errors).length !== 0 ||
                    errors.length > 0 ||
                    isCompleted
                    // !agree_private ||
                    // !agree_fdInfo ||
                    // !phone ||
                    // !selectedOS ||
                    // !isConfirmed ||
                    // isCompleted ||
                    // phone.replace(/[- ]/g, "").length > 8 ||
                    // phone.replace(/[- ]/g, "").length < 7 ||
                    // isNaN(phone.replace(/[- ]/g, ""))
                  }
                >
                  <img
                    src={btPreregist}
                    alt="사전예약 신청"
                    width={300}
                    style={{
                      opacity:
                        submitting ||
                        Object.keys(errors).length !== 0 ||
                        errors.length > 0 ||
                        !agree_private ||
                        !agree_fdInfo ||
                        !phone ||
                        !selectedOS ||
                        isDuplicated ||
                        (!isConfirmed && useAuth) ||
                        isCompleted ||
                        phone.replace(/[- ]/g, '').length > 8 ||
                        phone.replace(/[- ]/g, '').length < 7 ||
                        isNaN(phone.replace(/[- ]/g, ''))
                          ? 0.5
                          : 1,
                    }}
                  />
                  {/* 사전예약 신청 */}
                </Button>
                <div className="notice text-xxs sm:text-xs w-full ">
                  <h4>
                    ※ 사전 예약의 경우 동일한 휴대 전화 번호로 중복 신청할 수
                    없습니다.
                  </h4>
                  <h4>
                    ※ 사전 예약 보상의 경우 내부 사정에 의해 변경될 수 있습니다.
                  </h4>
                </div>

                {useDown && (
                  <div className="w-full mt-4 space-x-1 flex justify-between">
                    {game?.play_store_url && (
                      <DownButton
                        onClick={() => onStore('google')}
                        fullWidth={true}
                      >
                        <img
                          src={isMobile ? btGoogle_mo : btGoogle}
                          alt="playStore"
                          width="115"
                        />
                      </DownButton>
                    )}
                    {game?.app_store_url && (
                      <DownButton
                        onClick={() => onStore('ios')}
                        fullWidth={true}
                      >
                        <img
                          src={isMobile ? btIOS_mo : btIOS}
                          alt="appStore"
                          width="115"
                        />
                      </DownButton>
                    )}
                    {game?.one_store_url && (
                      <DownButton
                        onClick={() => onStore('onestore')}
                        fullWidth={true}
                      >
                        <img
                          src={isMobile ? btOnestore_mo : btOnestore}
                          alt="onestore"
                          width="115"
                        />
                      </DownButton>
                    )}
                  </div>
                )}
              </form>
            )}
          />
        </div>
        <BtScroll setActivePage={props.setActivePage} />
      </div>
      {openPrivacy && (
        <div
          className="absolute p-4 top-0 left-0 w-full h-full flex flex-col items-center justify-center"
          style={{ backgroundColor: '#000000dd' }}
        >
          <div
            className="relative m-auto p-4 sm:p-16 text-xxs sm:text-sm bg-black text-white border-4"
            style={{
              borderColor: '#f4aa2f',
              backgroundImage: `url(${bgPrivacy})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="absolute top-2 -right-2 sm:right-0 z-50 whitespace-pre-line">
              <Button onClick={() => setOpenPrivacy(false)}>
                <img src={btClose} alt="닫기" width={isMobile ? 25 : 33} />
              </Button>
            </div>
            <b style={{ color: '#f4aa2f' }}>[개인정보 수집·이용 동의 안내]</b>
            <pre>{privacy1}</pre>
            <b style={{ color: '#f4aa2f' }}>
              [게임 정보·이벤트 수신 동의 안내]
            </b>
            <pre>{privacy2}</pre>
            <b style={{ color: '#f4aa2f' }}>[개인 정보 제 3자 제공 동의]</b>
            <pre>{privacy3}</pre>
          </div>
        </div>
      )}
    </div>
  );
}

const ButtonText = ({ children }) => {
  return (
    <div
      className="authNumber flex items-center justify-center font-bold text-white"
      style={{
        backgroundImage: `url(${btSMS})`,
        backgroundSize: 'cover',
        width: 108,
        height: 42,
        marginTop: -1,
        textShadow: `-1.5px -1.5px 0 #000,
                      0   -1.5px 0 #000,
                      1.5px -1.5px 0 #000,
                      1.5px  0   0 #000,
                      1.5px  1.5px 0 #000,
                      0    1.5px 0 #000,
                      -1.5px  1.5px 0 #000,
                      -1.5px  0   0 #000`,
      }}
    >
      {children}
    </div>
  );
};

const privacy1 = `
창유는 게임 및 이벤트 정보 안내를 위해 개인정보 수집 및 이용하고 있습니다.
귀하는 개인정보 수집 및 이용에 동의하지 않을 수 있으며,
동의를 거부할 경우 이벤트에 참여할 수 없습니다.

개인 정보 수집 및 이용 목적 : 사전 예약 및 출시 정보 관련 문자 발송 등
개인 정보 수집 항목 : 휴대 전화 번호
개인정보 보유 및 이용 기간 : 정식 출시 이후 1년 이내 파기


`;

const privacy2 = `
창유에서 진행하는 게임, 이벤트 등의 내용을
SMS / LMS / 카카오 알림톡으로 안내해드립니다.
동의를 거부할 경우 이벤트에 참여할 수 없습니다.


`;
const privacy3 = `
창유는 게임 출시 및 업데이트, 각종 이벤트 광고 알림 수신을 위해
아래와 같이 개인정보를 수집 및 이용하고자 합니다.

수집 및 이용 항목 : 휴대 전화 번호
위탁업체 : ㈜더플레이어

귀하는 제 3자에게 본인의 개인정보를 제공하는 것에 동의하지 않을 수 있습니다.
단, 제 3자에게 개인정보 제공을 동의하시지 않을 경우 이벤트에 참여하실 수 없습니다.

수집 된 개인정보는 업무제휴 및 공동 마케팅 등을 위해 사용될 수 있습니다.
`;

// const NoPadButton = withStyles({
// 	root: {
// 		padding: 0,
// 	},
// })(Button);
const DownButton = withStyles({
  root: {
    padding: 0,
    margin: 0,
  },
})(Button);

import React, { useRef, useState } from "react";
import { IconButton, withStyles } from "@material-ui/core";
// import VolumeUp from "@material-ui/icons/VolumeUp";
// import VolumeOff from "@material-ui/icons/VolumeOff";
// import btOn from "../../../assets/fd2/bt_music_on.png";
// import btOff from "../../../assets/fd2/bt_music_off.png";
// import moMusicOn from "../../../assets/fd2/MO-PNG/페이지 고정/상단메뉴/뮤직-on.png";
// import moMusicOff from "../../../assets/fd2/MO-PNG/페이지 고정/상단메뉴/뮤직-off.png";
import { cdn, cdnImg } from "../../../config/etc";
// import { useCookies } from "react-cookie";

const bgmList = [
	"Ruler of Court",
	"Return Of The Kings",
	"LetsGetItOn",
	"Lets Get Rollin",
	"BringemOut",

	// "AFRO (lyrics)",
	// "BGM_BONUSSTAGE_CAROL",
	// // "Buzzer Beater (En lyrics)",
	// "BuzzerBeaterRemixByELRO",
	// "GLB_CHALLENGE_BGM",
	// "Move Rock Go",
	// // "Return Of The Kings SCENE",
	// "RUN",
	// "SuperStar",
	// "SuperstarIsBack",
	// "The Styles (Instrumental)",
	// "The Styles",
	// "We Back (Instrumental)",
	// "We Back",
	// "We Like To Party (Instrumental)",
	// "We Like To Party",
	// "WeBeBallin",

	// "Buzzer Beater (En lyrics) (mp3cut.net)",
	// "BringemOut (mp3cut.net)",
	// "BGM_BONUSSTAGE_CAROL (mp3cut.net)",
	// "AFRO (lyrics) (mp3cut.net)",
];
const btOn = cdnImg + "bt_music_on.png";
const btOff = cdnImg + "bt_music_off.png";
const moMusicOn = cdnImg + "mo/common/top/뮤직-on.png";
const moMusicOff = cdnImg + "mo/common/top/뮤직-off.png";

export default function FDBGM({ type }) {
	const audio = useRef();
	const [muted, setMuted] = useState(true);
	const [bgmTitle, setBgmTitle] = useState(
		bgmList[Math.floor(Math.random() * bgmList.length)]
	);

	const controlAudio = (isMute) => {
		if (isMute) {
			setMuted(!muted);
		}
		audio.current.load();
		if (muted || !isMute) {
			setBgmTitle(bgmList[Math.floor(Math.random() * bgmList.length)]);
			audio.current.play();
		} else {
			audio.current.pause();
		}
	};

	return (
		<div
			className={
				type === "mo"
					? ""
					: "fixed bottom-16 z-30 right-4 text-xs flex flex-col items-end justify-end"
			}
		>
			<audio
				id="audio"
				ref={audio}
				// autoPlay={true}
				loop={false}
				onEnded={() => controlAudio(false)}
				muted={muted}
				// controls
			>
				<source src={cdn + `bgm/${bgmTitle}.mp3`} type="audio/mpeg" />
			</audio>
			<MyIconButton
				variant="contained"
				onClick={() => controlAudio(true)}
				style={{ color: "white" }}
			>
				{muted ? (
					<img
						src={type === "mo" ? moMusicOn : btOn}
						alt="music button"
						width={type === "mo" ? 27 : 60}
					/>
				) : (
					<img
						src={type === "mo" ? moMusicOff : btOff}
						alt="music button"
						width={type === "mo" ? 27 : 60}
					/>
				)}
			</MyIconButton>
			<p
				className="hidden sm:block -mt-2"
				style={{ paddingRight: 16, height: 15 }}
			>
				{muted ? " " : bgmTitle}
			</p>
		</div>
	);
}

export const MyIconButton = withStyles((theme) => ({
	root: {
		"&:focus": { outline: 0 },
	},
}))(IconButton);

import React, { useState } from "react";
import { Form } from "react-final-form";
import { forgotPassword } from "../service/Auth";
import createDecorator from "final-form-focus";
import { motion } from "framer-motion";
import InputAdapter from "./components/InputAdapter";
import {
	composeValidators,
	emailRegistered,
	emailValidator,
	requiredValidator,
} from "../utils/validator";
import { Button, withStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import GoBack from "./components/GoBack";
import { aniContainer, aniItem } from "../config/animation";

const focusOnError = createDecorator();

export const ButtonK = withStyles((theme) => ({
	root: {
		// borderColor: "#ffff00",
	},
}))(Button);
export const ButtonN = withStyles((theme) => ({
	root: {
		// borderColor: "#006b3e33",
	},
}))(Button);
export const ButtonF = withStyles((theme) => ({
	root: {
		// borderColor: "#051f9433",
	},
}))(Button);
export const ButtonG = withStyles((theme) => ({
	root: {
		// borderColor: "#ff000033",
	},
}))(Button);

const ForgotPassword = () => {
	const [complete, setComplete] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = (values) => {
		console.log("values: ", values);
		forgotPassword({ email: values.email })
			.then((res) => setComplete(true))
			.catch((err) => {
				if (err.response.status === 404) {
					enqueueSnackbar("가입하지 않은 이메일입니다.", { variant: "error" });
				} else {
					enqueueSnackbar(
						"인증 메일 발송에 문제가 발생했습니다. 다시 시도하거나 고객센터에 문의해 주세요.",
						{ variant: "error" }
					);
				}
			});
	};
	return (
		<div className="relative mxrounded-xl p-4 mt-4 w-full sm:max-w-lg mx-auto">
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors }) => (
					<motion.form
						variants={aniContainer}
						initial="hidden"
						animate="visible"
						onSubmit={handleSubmit}
						className="flex flex-col "
					>
						<motion.div variants={aniItem}>
							<GoBack />
						</motion.div>
						<motion.h1
							variants={aniItem}
							className="font-bold text-xl text-purple-600"
						>
							비밀번호 찾기
						</motion.h1>
						{!complete ? (
							<>
								<motion.h3 variants={aniItem} className="text-sm my-5">
									비밀번호를 재설정하기 위해 가입할 때 사용한 이메일 주소를
									입력해주세요.
								</motion.h3>
								<div className=" space-y-2">
									<motion.div variants={aniItem}>
										<InputAdapter
											validator={composeValidators(
												requiredValidator,
												emailValidator,
												emailRegistered
											)}
											spinner="true"
											name="email"
											type="email"
											title="이메일"
										/>
									</motion.div>

									<motion.div variants={aniItem}>
										<Button
											type="submit"
											color="primary"
											variant="contained"
											disabled={
												submitting ||
												Object.keys(errors).length !== 0 ||
												pristine ||
												errors.length > 0
											}
											fullWidth={true}
										>
											재설정 링크 받기
										</Button>
									</motion.div>
								</div>
								<motion.div variants={aniItem} className="text-xxs mt-4 mb-2">
									* 간편가입(카카오톡, 네이버, 구글, 페이스북) 비밀번호 찾기는
									각 SNS서비스에서 가능합니다.
								</motion.div>
								<motion.div
									variants={aniItem}
									className="text-xxs flex space-x-2"
								>
									<ButtonK
										onClick={() =>
											window.open(
												"https://accounts.kakao.com/weblogin/find_password?continue=https%3A%2F%2Flogins.daum.net%2Faccounts%2Fksso.do%3Frescue%3Dtrue%26url%3Dhttps%253A%252F%252Fwww.daum.net%252F"
											)
										}
										variant="outlined"
										className="w-1/4 "
									>
										<span className="text-xs">카카오톡</span>
									</ButtonK>
									<ButtonN
										onClick={() =>
											window.open(
												"https://nid.naver.com/user2/help/pwInquiry.nhn?menu=pwinquiry"
											)
										}
										variant="outlined"
										className="w-1/4"
									>
										<span className="text-xs">네이버</span>
									</ButtonN>
									<ButtonF
										onClick={() =>
											window.open(
												"https://www.facebook.com/login/identify/?ctx=recover&ars=facebook_login&from_login_screen=0"
											)
										}
										variant="outlined"
										className="w-1/4"
									>
										<span className="text-xs">페이스북</span>
									</ButtonF>
									<ButtonG
										onClick={() =>
											window.open(
												"https://accounts.google.com/ServiceLogin/signinchooser?hl=ko&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
											)
										}
										variant="outlined"
										className="w-1/4"
									>
										<span className="text-xs">구글</span>
									</ButtonG>
								</motion.div>
							</>
						) : (
							<>
								<motion.h3
									variants={aniItem}
									className="text-sm my-5 font-bold text-purple-600"
								>
									이메일 전송이 완료되었습니다.
								</motion.h3>
								<motion.h3 variants={aniItem} className="text-sm my-2">
									이메일에서 비밀번호 재설정하기 버튼을 클릭하면 새로운
									비밀번호를 설정하여 사용하실 수 있습니다.
								</motion.h3>
							</>
						)}
					</motion.form>
				)}
			/>
		</div>
	);
};

export default ForgotPassword;

import React, { useState } from "react";
import { useLoginContext } from "../../contexts/LoginContext";
import { Button, Divider, makeStyles } from "@material-ui/core";
import { editUser } from "../../service/Auth";
import { Form } from "react-final-form";
import InputAdapter from "../components/InputAdapter";
import {
	composeValidators,
	nickAvailable,
	requiredValidator,
	specialCharValidator,
} from "../../utils/validator";
import createDecorator from "final-form-focus";
import Dropper from "../components/Dropper";
import { uploadFile } from "../../service/Content";
import DoneOutlinedIcon from "@material-ui/icons/DoneOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { motion } from "framer-motion";
import { aniContainer, aniItem } from "../../config/animation";
import CheckAdapter from "../components/CheckAdapter";
import { useSnackbar } from "notistack";
import GoBack from "../components/GoBack";

const useStyles = makeStyles((theme) => ({
	icon: {
		width: "1rem",
		height: "1rem",
	},
}));

const NickModify = (props) => {
	const { user, setUser } = useLoginContext();
	const [agree_event, setAgree_event] = useState(user.agree_event);
	const [agree_5year, setAgree_5year] = useState(user.agree_5year);
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	function onSubmit(values) {
		editUser(user.id, values).then((res) => {
			enqueueSnackbar("닉네임 변경이 완료되었습니다.", {
				variant: "success",
			});
			res.data.path = user.path;
			setUser(res.data);
		});
	}
	function handleUploadChange(e) {
		const file = e[0];
		let formData = new FormData();
		formData.set("file", file);
		uploadFile(formData).then((response) => {
			editUser(user.id, {
				avatar: response.data,
				$push: { avatars: response.data },
			}).then((res) => {
				res.data.path = user.path;
				setUser(res.data);
			});
		});
	}
	function selectAvatar(avatar) {
		editUser(user.id, {
			avatar,
		}).then((res) => {
			res.data.user.path = user.path;
			setUser(res.data);
		});
	}
	function deleteAvatar(avatar) {
		editUser(user.id, {
			$pull: { avatars: avatar },
		}).then((res) => {
			res.data.user.path = user.path;
			setUser(res.data);
		});
	}
	const focusOnError = createDecorator();
	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className=" p-4 sm:mt-4"
		>
			<motion.div variants={aniItem} className="sm:hidden">
				<GoBack isMember={true} setTab={props.setTab} />
			</motion.div>
			<motion.h1
				variants={aniItem}
				className="font-bold text-xl text-purple-600 mb-6"
			>
				기본 정보
			</motion.h1>
			<motion.h2 variants={aniItem} className="font-bold mb-2">
				아바타 변경
			</motion.h2>
			<motion.div variants={aniItem} className="mb-2">
				<Divider />
			</motion.div>
			<motion.div variants={aniItem} className="flex mb-6 space-x-3">
				<Dropper
					onDropAccepted={(e) => handleUploadChange(e)}
					width="64px"
					height="64px"
					src={user.avatar}
				/>
				{user.avatars?.map((avatar, idx) => {
					return (
						avatar !== user.avatar &&
						idx < 5 && (
							<div key={avatar + idx} className="">
								<img
									src={avatar}
									className={"w-16 h-16 border-4 object-cover "}
									alt="아바타"
									onClick={(e) => selectAvatar(avatar)}
								/>
								<div
									key={avatar}
									className="flex justify-center items-center mt-1"
								>
									<DoneOutlinedIcon
										onClick={(e) => selectAvatar(avatar)}
										className={classes.icon + " mr-3"}
									/>
									<DeleteForeverOutlinedIcon
										onClick={(e) => deleteAvatar(avatar)}
										className={classes.icon}
									/>
								</div>
							</div>
						)
					);
				})}
			</motion.div>
			<motion.h2 variants={aniItem} className="font-bold mb-2">
				닉네임 변경
			</motion.h2>
			<motion.div variants={aniItem} className=" mb-4">
				<Divider />
			</motion.div>
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors, values }) => (
					<form
						onSubmit={handleSubmit}
						className="flex flex-col space-y-2  mb-6"
					>
						<motion.div variants={aniItem}>
							<InputAdapter
								validator={composeValidators(
									requiredValidator,
									specialCharValidator,
									nickAvailable
								)}
								spinner="true"
								name="nickname"
								type="text"
								title="닉네임"
								initialValue={user.nickname}
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={
									submitting ||
									Object.keys(errors).length !== 0 ||
									pristine ||
									errors.length > 0
								}
								fullWidth={true}
							>
								닉네임 변경
							</Button>
						</motion.div>
					</form>
				)}
			/>
			<motion.h2 variants={aniItem} className="font-bold mb-2">
				알림 변경
			</motion.h2>
			<motion.div variants={aniItem} className="">
				<Divider />
			</motion.div>
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors, values }) => (
					<form
						onSubmit={handleSubmit}
						className="flex flex-col space-y-2  mb-4"
					>
						<motion.div variants={aniItem}>
							<CheckAdapter
								name="agree_event"
								checked={agree_event}
								onClick={(e) => {
									setAgree_event(e.target.checked);
								}}
								label={
									<span className="text-sm">
										<b className="text-purple-600">(선택)</b>이벤트 등 프로모션
										안내 메일을 수신합니다.
									</span>
								}
								// name="agree_private"
							/>
						</motion.div>
						<motion.div variants={aniItem}>
							<CheckAdapter
								name="agree_5year"
								checked={agree_5year}
								onClick={(e) => {
									setAgree_5year(e.target.checked);
								}}
								label={
									<span className="text-sm">
										<b className="text-purple-600">(선택)</b>개인정보 유효기간을
										5년으로 선택합니다.
									</span>
								}
							/>
							<p className="pl-7 text-xs mb-4 text-gray-500 -mt-2">
								선택하지 않으면 1년으로 설정됩니다.
							</p>
						</motion.div>
						<motion.div variants={aniItem}>
							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={
									submitting ||
									Object.keys(errors).length !== 0 ||
									pristine ||
									errors.length > 0
								}
								fullWidth={true}
							>
								알림 변경
							</Button>
						</motion.div>
					</form>
				)}
			/>
		</motion.div>
	);
};

export default NickModify;

import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { Link } from "@material-ui/core";
import { readDatas } from "../../service/Content";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Analytics from "../../service/Analytics";

function CustomArrowPrev(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick} style={{ fontSize: 0 }}>
			<ArrowBackIos color="primary" fontSize="large" />
		</div>
	);
}
function CustomArrowNext(props) {
	const { className, onClick } = props;
	return (
		<div className={className} onClick={onClick}>
			<ArrowForwardIos color="primary" fontSize="large" />
		</div>
	);
}
const settings = {
	prevArrow: <CustomArrowPrev />,
	nextArrow: <CustomArrowNext />,
	className: "center",
	infinite: true,
	autoplay: true,
	autoplaySpeed: 3000,
	slidesToShow: 1,
	swipeToSlide: true,
	adaptiveHeight: true,
	arrows: true,
	centerMode: true,
	centerPadding: "0rem",
	dots: true,
	easing: "ease",
	// pauseOnDotsHover: true,
};
export default function MainSlider() {
	const [banners, setBanners] = useState();
	const history = useHistory();
	const linkBanner = (banner) => {
		Analytics.logEvent("bannerClick", {
			banner: banner.title,
			game: banner.game_id,
		});
		if (banner.is_outlink) {
			let tempUrl = banner.link_url;
			if (tempUrl.substring(0, 4) !== "http") {
				tempUrl = "http://" + tempUrl;
			}
			window.open(tempUrl);
		} else {
			history.push(banner.link_url);
		}
	};
	useEffect(() => {
		const localBanners = localStorage.getItem("banners");
		if (localBanners) setBanners(JSON.parse(localBanners));
		let condition = {
			where: {
				is_active: true,
				startDate: { $lte: moment().format("YYYY-MM-DD") },
				endDate: { $gte: moment().subtract("1", "d").format("YYYY-MM-DD") },
			},
		};
		readDatas("banner", condition)
			.then((bannerRes) => {
				let bannersData = bannerRes.data.data;
				setBanners(bannersData);
				localStorage.setItem("banners", JSON.stringify(bannersData));
			})
			.catch((error) => {
				console.log(error);
			});
		// return () => {};
	}, []);
	return (
		<>
			{banners?.length > 0 && (
				<>
					<div className="hidden sm:block">
						<Slider {...settings}>
							{banners.map((banner, idx) => {
								if (banner.main_img) {
									return (
										<div key={idx}>
											<Link
												onClick={() => linkBanner(banner)}
												className="flex justify-center bg-purple-900"
											>
												<div
													className="flex-1"
													style={{
														backgroundColor: banner.main_left,
													}}
												></div>
												<img
													className="flex-2 z-10 max-h-90"
													src={banner.main_img}
													alt={banner.title}
												/>
												<div
													className="flex-1"
													style={{
														backgroundColor: banner.main_right,
													}}
												></div>
											</Link>
										</div>
									);
								} else {
									return null;
								}
							})}
						</Slider>
					</div>
					<div className="mobile sm:hidden">
						<Slider {...settings}>
							{banners.map((banner, idx) => {
								if (banner.mobile_img) {
									return (
										<div key={idx}>
											<Link
												onClick={() => linkBanner(banner)}
												className="flex justify-center bg-purple-900"
											>
												<img
													className="w-full z-10 max-h-90"
													src={banner.mobile_img}
													alt={banner.title}
												/>
											</Link>
										</div>
									);
								} else {
									return null;
								}
							})}
						</Slider>
					</div>
				</>
			)}
		</>
	);
}

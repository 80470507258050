import React from "react";
import Divider from "@material-ui/core/Divider";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
// import { Switch } from "@material-ui/core";
import UserInfo from "../pages/auth/UserInfo";
import MobileGames from "../pages/components/MobileGames";
import { useLoginContext } from "../contexts/LoginContext";
import { useHistory } from "react-router-dom";

const SideMenu = () => {
	const { user } = useLoginContext();
	const history = useHistory();
	// const [isDark, setIsDark] = useState(false);
	// const toggleDark = (event) => {
	// 	setIsDark(event.target.checked);
	// 	if (event.target.checked) {
	// 		document.querySelector("html").classList.add("dark");
	// 	} else {
	// 		document.querySelector("html").classList.remove("dark");
	// 	}
	// };
	return (
		<div className="h-full bg-purple-200 flex flex-col justify-between">
			<div className="p-4">
				{user.id && (
					<>
						<UserInfo />
						<Divider />
					</>
				)}
				<MobileGames />
			</div>
			{!user.id && (
				<div
					className="bg-black py-4 text-xl text-white text-center"
					onClick={() => history.push("/login")}
				>
					로그인
				</div>
			)}
		</div>
		//  <List>
		// 		{["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
		// 			<ListItem button key={text}>
		// 				<ListItemIcon>
		// 					{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
		// 				</ListItemIcon>
		// 				<ListItemText primary={text} />
		// 			</ListItem>
		// 		))}
		// 	</List>
		// 	<Divider />
		// 	<List>
		// 		{["All mail", "Trash", "Spam"].map((text, index) => (
		// 			<ListItem button key={text}>
		// 				<ListItemIcon>
		// 					{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
		// 				</ListItemIcon>
		// 				<ListItemText primary={text} />
		// 			</ListItem>
		// 		))}
		// 		<ListItem>
		// 			<Switch
		// 				checked={isDark}
		// 				onChange={toggleDark}
		// 				name="checkedA"
		// 				color="primary"
		// 			/>
		// 		</ListItem>
		// 	</List>
		// </div>
	);
};

export default SideMenu;

/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import FDMenu from './sections/FDMenu';
import HeadVideo from './sections/HeadVideo';
import FDPreRegister from './sections/FDPreRegister';
import CharacterPosition from './sections/CharacterPosition';
import PositionTest from './sections/PositionTest';
import FD2GameInfo from './sections/FD2GameInfo';
import FDSNSChallenge from './sections/FDSNSChallenge';
import FDBGM from './components/FDBGM';
import Meta from '../../pages/components/Meta';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useLoginContext } from '../../contexts/LoginContext';
import firebase from 'firebase/app';
import 'firebase/database';
import { useEventContext } from '../../contexts/EventContext';
import { getEvent } from '../../service/Events';
import FDEventRefer from './sections/FDEventRefer';
import FDEventLike from './sections/FDEventLike';
import FDEventCafe from './sections/FDEventCafe';
import { Button } from '@material-ui/core';

import { useMediaQuery } from 'react-responsive';
import FDSNS from './components/FDSNS';
import { cdnImg } from '../../config/etc';
import moment from 'moment';
import PopUp from './components/FDPop';
import { usePopContext } from '../../contexts/PopContext';
import { isAndroid, isIOS } from 'react-device-detect';
import { readData } from '../../service/Content';
import Kuroko from './sections/Kuroko';
import { Fd2StoreDown } from './components/FDDown';

const FDMenuHeight = 60;

const btScroll = cdnImg + 'bt_scroll.png';
const bgSNSShare = cdnImg + 'bg_sns_share.png';
const btFloating = cdnImg + 'bt_floating.png';
const logoCyou = cdnImg + 'logo_cyou_w.png';
const logoJoycity = cdnImg + 'pc/common/조이시티-로고.png';
const btUpOn = cdnImg + '0_위_활성.png';
const btUp = cdnImg + '0_위_비활.png';
const btDownOn = cdnImg + '0_아래_활성.png';
const btDown = cdnImg + '0_아래_비활.png';

export default function FDMain() {
  const location = useLocation();
  const history = useHistory();
  const refer = qs.parse(location.search).refer;
  const utm_source = qs.parse(location.search).utm_source;
  const utm_medium = qs.parse(location.search).utm_medium;
  const position = qs.parse(location.search).position;
  const positionShare = qs.parse(location.search).positionShare;
  const pageLink = qs.parse(location.search).p;
  const adkey = qs.parse(location.search).adkey;

  const { user, setUser } = useLoginContext();
  const { event, setEvent } = useEventContext();
  const { popup } = usePopContext();

  const [cookies, setCookie] = useCookies();
  const [activePage, setActivePage] = useState(0);
  const [touchY, setTouchY] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width:600px)' });

  const onTouchStart = (e) => {
    setTouchY(e?.touches?.[0]?.clientY);
  };
  const onTouchMove = (e) => {
    const touchMissOffset = 30;

    if (Math.abs(touchY - e?.touches?.[0]?.clientY) > touchMissOffset) {
      clearTimeout(wheelTimer);
      wheelTimer = setTimeout(() => {
        changePage(touchY - e?.touches?.[0]?.clientY > 0);
      }, 220);
    }
  };

  const [downScroll, setDownScroll] = useState(true);
  const [upScroll, setUpScroll] = useState(0);
  const [canScroll, setCanScroll] = useState(true);
  const [isDown, setIsDown] = useState(false);
  const [isUp, setIsUp] = useState(false);
  let tranTimer;
  let timer;
  const goNextPage = (amount) => {
    setCookie('isRevisit', 'true');
    setIsDown(true);
    setUpScroll(true);
    setDownScroll(false);
    clearTimeout(tranTimer);
    tranTimer = setTimeout(() => {
      setActivePage((prev) => {
        setIsDown(false);
        if (prev < menuData.length - 1) {
          setTimeout(() => {
            window.scrollTo({ top: 0 });
          }, 10);
          return prev + (amount ?? 1);
        } else {
          return prev;
        }
      });
    }, 400);
  };
  const goPrevPage = () => {
    setCookie('isRevisit', 'true');
    setIsUp(true);
    setUpScroll(true);
    setDownScroll(false);
    clearTimeout(tranTimer);
    tranTimer = setTimeout(() => {
      setIsUp(false);
      setActivePage((prev) => {
        if (prev > 0) {
          setTimeout(() => {
            window.scrollTo({ top: 0 });
          }, 10);
          return prev - 1;
        } else {
          return prev;
        }
      });
    }, 300);
  };
  const menuDataRaw = [
    {
      name: '메인',
      comp: <HeadVideo setActivePage={goNextPage} />,
    },
    {
      name: '사전 예약',
      comp: <FDPreRegister setActivePage={goNextPage} />,
    },
    {
      name: '제휴 이벤트',
      comp: <Kuroko setActivePage={goNextPage} goPage={setActivePage} />,
    },
    {
      name: '포지션 테스트',
      comp: <PositionTest setActivePage={goNextPage} />,
    },
    {
      name: 'SNS 챌린지',
      comp: <FDSNSChallenge setActivePage={goNextPage} />,
    },

    {
      name: '프로모션',
      main: true,
      comp: <FDEventRefer setActivePage={goNextPage} />,
    },
    {
      name: '프로모션',
      sub: true,
      comp: <FDEventLike setActivePage={goNextPage} />,
    },
    {
      sub: true,
      name: '프로모션',
      comp: <FDEventCafe setActivePage={goNextPage} />,
    },
    {
      name: '포지션 소개',
      comp: <CharacterPosition setActivePage={goNextPage} />,
    },
    { name: '게임 소개', comp: <FD2GameInfo /> },
  ];
  const [menuData, setMenuData] = useState(menuDataRaw);
  useEffect(() => {
    // const today = moment();
    const today = moment();
    const closeEventsDate = moment('2021-11-23');
    const closeSNSDate = moment('2021-11-29');
    const deleteEventsDate = moment('2021-12-23');
    let dateState = 0;
    if (closeEventsDate.diff(today) <= 0) {
      menuDataRaw.splice(1, 1);
      setMenuData(menuDataRaw);
      dateState = 1;
    }
    if (closeSNSDate.diff(today) <= 0) dateState = 2;
    if (deleteEventsDate.diff(today) <= 0) {
      menuDataRaw.splice(3, 1);
      menuDataRaw.splice(3, 1);
      menuDataRaw.splice(3, 1);
      menuDataRaw.splice(3, 1);
      menuDataRaw.splice(1, 1);
      setMenuData(menuDataRaw);
      dateState = 3;
    }
    // console.log('dateState: ', dateState);
    setEvent({ dateState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changePage = useCallback(
    (delta) => {
      const currentPos = window.pageYOffset;
      const nextPos = document.body.scrollHeight - window.innerHeight;

      console.log('delta && currentPos nextPos: ', delta, currentPos, nextPos);
      if (delta && currentPos + 5 > nextPos) {
        if (!downScroll && nextPos !== 0) {
          clearTimeout(timer);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          timer = setTimeout(() => {
            setDownScroll(true);
            setUpScroll(false);
            console.log('setDownScroll: ', true);
          }, 200);
          return;
        }
        goNextPage();
      } else if (!delta && currentPos === 0) {
        if (!upScroll) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            setUpScroll(true);
            setDownScroll(false);
            console.log('setUpScroll: ', true);
          }, 200);
          return;
        }

        goPrevPage();
      } else {
        setUpScroll(false);
        setDownScroll(false);
        console.log('setScroll: ', false);
      }
    },
    [activePage, downScroll, menuData.length, upScroll]
  );

  let wheelTimer;
  const wheelChange = useCallback(
    (e) => {
      // e.preventDefault();
      e.stopPropagation();
      var delta;
      if (!e.wheelDeltaY) {
        delta = e.detail * -1;
      } else {
        delta = e.wheelDeltaY;
      }
      if (canScroll) {
        clearTimeout(wheelTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        wheelTimer = setTimeout(() => {
          changePage(delta < 0);
        }, 220);
      }
    },
    [canScroll, changePage]
    // [activePage, downScroll, upScroll]
  );
  useEffect(() => {}, []);
  useEffect(() => {
    if (user.id)
      getEvent(user.id)
        .then((res) => {
          setEvent(res.data);
        })
        .catch((err) => {
          console.error('no event data', err);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  useEffect(() => {
    if (firebase.apps.length !== 0) {
      firebase
        .database()
        .ref('cafe')
        .on('value', (res) => {
          setEvent({ cafe: res.val() });
        });
      firebase
        .database()
        .ref('likes')
        .on('value', (res) => {
          setEvent({ like: res.val() });
        });
    }
    return () => {
      console.log('이벤트 페이지 이탈');
      firebase.database().ref('likes').off();
      firebase.database().ref('cafe').off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebase.apps.length]);

  useEffect(() => {
    setCookie('activeSection', activePage);
  }, [activePage, setCookie]);

  useEffect(() => {
    let prevSection = cookies.activeSection;
    if (pageLink) {
      setActivePage(Number(pageLink) - 1);
    } else if (positionShare) {
      setActivePage(2);
      setCookie('share', 'positionTest');
      setCookie('positionShare', '');
    } else if (prevSection) {
      if (!isNaN(Number(prevSection))) setActivePage(Number(prevSection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refer) setCookie('referral', refer);
    if (utm_source) setCookie('utm_source', utm_source);
    if (utm_medium) setCookie('utm_medium', utm_medium);
    if (!utm_source || ['A', 'B', 'C', 'D', 'E'].includes(utm_medium)) {
      readData('game', '6152e4ce8dd99f97d436631c')
        .then((res) => {
          if (isAndroid && res.data.play_store_url) {
            window.location.href =
              'https://play.app.goo.gl/?link=' + res.data.play_store_url;
          }
          if (isIOS && res.data.app_store_url) {
            window.location.href = res.data.app_store_url;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (adkey) setCookie('adkey', adkey);
  }, [refer, utm_source, utm_medium, adkey, setCookie]);

  const wheelEvt =
    'onwheel' in document.createElement('div')
      ? 'wheel' //     Modern browsers support "wheel"
      : document.onmousewheel !== undefined
      ? 'mousewheel' // Webkit and IE support at least "mousewheel"
      : 'DOMMouseScroll';

  useEffect(() => {
    // document.body.style.zoom = "80%";
    const delay = 15;
    if (window.addEventListener) {
      window.addEventListener(wheelEvt, _.debounce(wheelChange, delay), false);
      window.onmousewheel = document.onmousewheel = wheelChange;
    } else if (window.attachEvent) {
      window.attachEvent(wheelEvt, _.debounce(wheelChange, delay));
      window.onmousewheel = document.onmousewheel = wheelChange;
    }
    return () => {
      if (window.addEventListener) {
        window.removeEventListener(wheelEvt, _.debounce(wheelChange, delay));
      } else if (window.attachEvent) {
        window.detachEvent(wheelEvt, _.debounce(wheelChange, delay));
      }
    };
  }, [wheelChange, wheelEvt]);

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      className="fd2 relative bg-black"
    >
      <Meta
        data={{
          title: '프리스타일2 레볼루션 플라잉덩크 사전예약',
          description:
            '새롭고 더 강력해진 프리스타일2 레볼루션 플라잉덩크(플덩2) 지금 바로 사전예약하세요!',
          image: cdnImg + 'flyingdunk2_share.jpg',
          width: '1098',
          height: '781',
        }}
      />
      {!isMobile && (
        <div
          className="hidden sm:flex z-20 fixed right-0 h-full w-32 text-right  flex-col items-end justify-center pr-4 -space-y-1"
          style={{
            width: 170,
            // height: 500,
            backgroundImage: `url(${bgSNSShare}),url(${bgSNSShare})`,
          }}
        >
          <FDSNS type="pc" />
          <div
            className="relative flex flex-col items-center "
            style={{ padding: 10, paddingTop: 25 }}
          >
            <button
              onClick={() => {
                goPrevPage();
              }}
              className="m-0 p-0"
              disabled={activePage === 0}
              style={{ opacity: activePage === 0 ? 0.5 : 1 }}
            >
              <img src={isUp ? btUpOn : btUp} alt="up" width={50} />
            </button>
            <button
              onClick={() => {
                goNextPage();
              }}
              className="m-0 p-0"
              disabled={activePage === menuData.length - 1}
              style={{ opacity: activePage === menuData.length - 1 ? 0.5 : 1 }}
            >
              <img src={isDown ? btDownOn : btDown} alt="up" width={50} />
            </button>
            {!cookies.isRevisit && (
              <motion.div
                initial={{ top: '3rem' }}
                animate={{ top: '6rem' }}
                transition={{
                  duration: 0.8,
                  repeat: Infinity,
                  repeatType: 'mirror',
                }}
                className="absolute top-16 right-20"
              >
                <div className="balloon_03">
                  <span>이전 페이지, 다음 페이지 이동 버튼</span>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <div
          className="fixed bottom-24 right-0 -mr-3 z-30 flex flex-col items-center "
          style={{ padding: 10, paddingTop: 25 }}
        >
          <button
            onClick={() => {
              goPrevPage();
            }}
            className="m-0 p-0"
            disabled={activePage === 0}
            style={{ opacity: activePage === 0 ? 0.5 : 1 }}
          >
            <img src={isUp ? btUpOn : btUp} alt="up" width={50} />
          </button>
          <button
            onClick={() => {
              goNextPage();
            }}
            className="m-0 p-0"
            disabled={activePage === menuData.length - 1}
            style={{ opacity: activePage === menuData.length - 1 ? 0.5 : 1 }}
          >
            <img src={isDown ? btDownOn : btDown} alt="up" width={50} />
          </button>
        </div>
      )}
      <FDMenu
        height={FDMenuHeight}
        activePage={activePage}
        setActivePage={setActivePage}
        menuData={menuData}
      />

      {menuData.map((menu, idx) => (
        <div key={idx} className="relative">
          {idx === activePage && (
            <motion.div
              initial={{ scale: 0.98, opacity: 0.5 }}
              animate={{ scale: 1, opacity: 1, top: 0 }}
              transition={{ duration: 0.9 }}
            >
              {menu.comp}
            </motion.div>
          )}
        </div>
      ))}

      {!isMobile && <FDBGM />}

      {/* 플로팅 */}
      {event.dateState < 1 &&
        !isMobile &&
        ![0, 1].includes(activePage) &&
        cookies.registered !== 'true' && (
          <motion.div
            initial={{ marginTop: 10 }}
            animate={{ marginTop: -10 }}
            transition={{
              duration: 0.5,
              repeat: Infinity,
              repeatType: 'mirror',
            }}
            className="fixed top-20 left-6 z-30"
          >
            <Button
              onClick={() => {
                setActivePage(1);
              }}
            >
              <img src={btFloating} alt="사전예약" style={{ width: 127 }} />
            </Button>
          </motion.div>
        )}

      {/* 푸터 */}
      {!isMobile && (
        <div
          className={
            'z-20 fixed px-4 flex text-center bottom-0 w-full' +
            (event.dateState > 0 ? ' justify-between' : ' justify-center')
          }
          style={{
            height: 60,
            backgroundColor: '#1c1a16',
            color: '#fff',
            borderTop: '1px solid #444',
          }}
        >
          <div className="flex justify-center items-center ">
            <img
              src={logoCyou}
              alt="cyou logo"
              width={70}
              onClick={() => history.push('/')}
            />
            <img
              src={logoJoycity}
              alt="joycity logo"
              width={90}
              className="ml-8"
            />
            <div className="ml-8 text-xxs">
              Copyrightⓒchangyou.com Limited.All right reserved.
            </div>
          </div>
          {event.dateState > 0 && <Fd2StoreDown size={100} />}
        </div>
      )}

      <PopUp popup={popup} />
    </div>
  );
}

export const BtScroll = ({ setActivePage }) => {
  return (
    <div className="my-4 mb-12 text-center">
      <Button
        onClick={() => {
          setActivePage();
        }}
      >
        <img src={btScroll} alt="scroll" style={{ width: 47 }} />
      </Button>
    </div>
  );
};

export const simpleMemoize = (fn) => {
	let lastArg;
	let lastResult;
	return (arg) => {
		if (arg !== lastArg) {
			lastArg = arg;
			lastResult = fn(arg);
		}
		return lastResult;
	};
};

export const isEmptyObj = (obj) =>
	Object.keys(obj).length === 0 && obj.constructor === Object;

import { Button, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLoginContext } from "../../contexts/LoginContext";
import DoneIcon from "@material-ui/icons/Done";
import logo_k from "../../assets/sns_icon1.png";
import logo_n from "../../assets/sns_icon2.png";
import logo_g from "../../assets/sns_icon3.png";
import logo_f from "../../assets/sns_icon4.png";
import { SNS } from "../../config/snsKeys";
import Spinner from "../components/Spinner";
import { motion } from "framer-motion";
import { getCode } from "../../service/SnsAuth";

const CssButton = withStyles({
	root: {
		"& .MuiButton-startIcon": {
			position: "absolute",
			left: "0.6rem",
		},
		"& .MuiButton-endIcon": {
			position: "absolute",
			right: "0.6rem",
		},
		borderColor: "#f2f2f2",
		color: "#555",
		// fontSize: ".8rem",
	},
})(Button);

const SnsButton = ({ isLogin, stateStr, path }) => {
	const { user } = useLoginContext();
	const [name, setName] = useState();
	const [logo, setLogo] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const onClick = () => {
		getCode(path, stateStr);
		setIsLoading(path);
	};

	useEffect(() => {
		switch (path) {
			case SNS.KAKAO:
				setName("카카오톡으");
				setLogo(logo_k);
				break;
			case SNS.NAVER:
				setName("네이버");
				setLogo(logo_n);
				break;
			case SNS.GOOGLE:
				setName("구글");
				setLogo(logo_g);
				break;
			case SNS.FACEBOOK:
				setName("페이스북으");
				setLogo(logo_f);
				break;
			default:
				break;
		}
	}, [path]);

	return (
		<>
			<CssButton
				onClick={onClick}
				startIcon={<img className="h-4 rounded" src={logo} alt={name} />}
				endIcon={
					user.path === path && (
						<motion.div
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{
								type: "spring",
								stiffness: 260,
								damping: 20,
								delay: 1.5,
							}}
						>
							{" "}
							<DoneIcon color="primary" />
						</motion.div>
					)
				}
				variant="outlined"
				size="small"
				color="primary"
				fullWidth
			>
				{name}
				{isLogin ? "로 로그인" : stateStr ? "로 연결" : "로 회원가입"}
				{isLoading === path && (
					<div className="absolute left-0 right-0 top-0 bottom-0 flex justify-center item-center ">
						<Spinner />
					</div>
				)}
			</CssButton>
		</>
	);
};

export default SnsButton;

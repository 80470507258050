import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLoginContext } from "../../contexts/LoginContext";
import { getCode, putSnsDisConnect } from "../../service/SnsAuth";
import { SNS } from "../../config/snsKeys";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { aniContainer, aniItem } from "../../config/animation";
import sns_icon1 from "../../assets/sns_icon1.png";
import sns_icon2 from "../../assets/sns_icon2.png";
import sns_icon3 from "../../assets/sns_icon4.png";
import sns_icon4 from "../../assets/sns_icon3.png";
import GoBack from "../components/GoBack";

const snsPaths = [
	{ name: "카카오톡", id: SNS.KAKAO, img: sns_icon1 },
	{ name: "네이버", id: SNS.NAVER, img: sns_icon2 },
	{ name: "페이스북", id: SNS.FACEBOOK, img: sns_icon3 },
	{ name: "구글", id: SNS.GOOGLE, img: sns_icon4 },
];
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const ConnectButton = withStyles((theme) => ({
	root: {
		width: "100%",
		height: 50,
		fontWeight: theme.typography.fontWeightBold,
		fontSize: "13px",
		backgroundColor: "#fff",
	},
	label: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
}))(Button);

const ConnectButtonSm = withStyles((theme) => ({
	root: {
		textTransform: "none",
		width: 110,
		height: 110,
		fontWeight: theme.typography.fontWeightBold,
		fontSize: "13px",
		backgroundColor: "#fff",
		transition: "1s all ease",
		"&:hover": {
			transform: "scale(1.05)",
			backgroundColor: "#9900ff05",
			fontWeight: "bold",
			opacity: 1,
		},
	},
	label: {
		flexDirection: "column",
	},
	startIcon: {
		margin: 0,
		transition: "1s all ease",
	},
	endIcon: { margin: "10px 0 0" },
}))(Button);

const SmallChip = withStyles((theme) => ({
	root: {
		padding: 0,
		cursor: "pointer",
	},
	sizeSmall: {
		height: "18px",
	},
	label: {
		fontSize: 10,
		fontWeight: theme.typography.fontWeightLight,
	},
}))(Chip);

const MediumChip = withStyles((theme) => ({
	root: {
		padding: 0,
		cursor: "pointer",
	},
	sizeSmall: {
		height: "22px",
	},
	label: {
		fontSize: 12,
		fontWeight: theme.typography.fontWeightLight,
	},
}))(Chip);

export default function ConnectedSNS(props) {
	const { user, setUser } = useLoginContext();
	const location = useLocation();
	const [openConfirm, setOpenConfirm] = useState(false);
	const [path, setPath] = useState();
	const { enqueueSnackbar } = useSnackbar();

	const closeDialog = () => {
		setOpenConfirm(false);
	};

	const onDelete = () => {
		setOpenConfirm(false);
		let tempUser = Object.assign({}, user);
		tempUser.paths.splice(tempUser.paths.indexOf(path), 1);
		tempUser.emails?.splice(
			tempUser.emails.indexOf(tempUser[path + "_email"]),
			1
		);
		putSnsDisConnect({
			path,
			paths: tempUser.paths,
			emails: tempUser.emails,
			connect_id: user.id,
		})
			.then((result) => {
				setUser(result.data);
				enqueueSnackbar(
					snsPaths.filter((p) => p.id === path)[0]?.name +
						" 연결이 해제되었습니다.",
					{ variant: "success" }
				);
			})
			.catch((e) => console.log(e));
	};

	const onClick = (_path) => {
		setPath(_path);
		let tempUser = Object.assign({}, user);
		if (tempUser.paths?.includes(_path)) {
			setOpenConfirm(true);
		} else {
			getCode(_path, "connect-" + _path);
		}
	};
	useEffect(() => {
		if (location.state?.complete) {
			enqueueSnackbar(
				snsPaths.filter((p) => p.id === location.state?.complete)[0]?.name +
					" 연결이 완료되었습니다.",
				{ variant: "success" }
			);
		} else if (location.state?.complete === false) {
			enqueueSnackbar("연결이 실패했습니다.", { variant: "error" });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state]);
	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className=" p-4 sm:mt-4"
		>
			<motion.div variants={aniItem} className="sm:hidden">
				<GoBack isMember={true} setTab={props.setTab} />
			</motion.div>
			<motion.h1
				variants={aniItem}
				className="font-bold text-xl text-purple-600 mb-6"
			>
				간편가입 연동
			</motion.h1>
			<div className="hidden sm:flex">
				{snsPaths.map((_path, idx) => (
					<motion.div
						variants={aniItem}
						key={_path.id}
						className="mr-2 mb-2 w-full sm:w-auto"
					>
						<ConnectButtonSm
							startIcon={
								<img
									src={_path.img}
									alt={_path.name}
									className="w-8 sm:mb-2 rounded-md"
								/>
							}
							onClick={() => onClick(_path.id)}
							variant="contained"
							disabled={
								user.email === user[_path.id + "_email"] &&
								user.email !== user.email_id
							}
							endIcon={
								<SmallChip
									label={
										user.email === user[_path.id + "_email"] &&
										user.email !== user.email_id
											? "기본"
											: user.paths?.includes(_path.id)
											? " 해제"
											: " 연결"
									}
									color={user.paths?.includes(_path.id) ? "default" : "primary"}
									size="small"
									className="cursor-pointer"
								/>
							}
						>
							{_path.name} 계정
						</ConnectButtonSm>
					</motion.div>
				))}
			</div>
			<div className="block sm:hidden">
				{snsPaths.map((_path, idx) => (
					<motion.div
						variants={aniItem}
						key={_path.id}
						className="mr-2 mb-2 w-full sm:w-auto"
					>
						<ConnectButton
							startIcon={
								<img
									src={_path.img}
									alt={_path.name}
									className="w-6 sm:mb-2 rounded-md"
								/>
							}
							onClick={() => onClick(_path.id)}
							variant="contained"
							disabled={
								user.email === user[_path.id + "_email"] &&
								user.email !== user.email_id
							}
							endIcon={
								<MediumChip
									label={user.paths?.includes(_path.id) ? " 해제" : " 연결"}
									color={user.paths?.includes(_path.id) ? "default" : "primary"}
									size="small"
									className="cursor-pointer"
								/>
							}
						>
							{_path.name} 계정
						</ConnectButton>
					</motion.div>
				))}
			</div>
			<span className="text-xxs">
				* 이미 가입된 계정을 연동할 경우, 기존 캐시 포인트는 본 계정으로
				이전됩니다.
				<br />* 무료 포인트의 경우 두 계정 중 더 많은 포인트로 유지됩니다.
			</span>
			<Dialog
				open={openConfirm}
				TransitionComponent={Transition}
				keepMounted
				onClose={closeDialog}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					로그인 연결 해제
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{snsPaths.filter((p) => p.id === path)[0]?.name} 로그인 연결을
						해제하시겠습니까?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDelete} color="primary">
						예
					</Button>
					<Button onClick={closeDialog} color="primary">
						아니오
					</Button>
				</DialogActions>
			</Dialog>
		</motion.div>
	);
}

import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import Meta from "../pages/components/Meta";

const Page404 = () => {
	const history = useHistory();
	return (
		<div className="absolute w-full top-2/4 left-2/4 text-center transform -translate-x-2/4 -translate-y-full space-y-4">
			<Meta
				data={{
					title: "404 없는 페이지입니다.",
				}}
			/>
			<h1 className="text-9xl">404</h1>
			<h2 className="text-3xl">찾을 수 없는 페이지 입니다.</h2>
			<h3 className="text-lg">주소를 다시 한번 확인해 주세요.</h3>
			<Button
				label="홈으로"
				onClick={() => history.push("/")}
				variant="contained"
				color="primary"
			>
				홈으로
			</Button>
		</div>
	);
};

export default Page404;

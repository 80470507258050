import { withStyles } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

const Checkbox2 = withStyles({
	root: {
		paddingTop: 2,
		paddingBottom: 2,
		color: "#fa0",
		"&.MuiCheckbox-colorPrimary": { color: "#fff" },
		"&.MuiCheckbox-colorPrimary.Mui-checked": { color: "#fa0" },
	},
})(Checkbox);

const CheckLabel = (props) => {
	return (
		<div className="flex items-center -mx-2.5 -space-x-1">
			{props.checktype === "fd2" ? (
				<Checkbox2 color="primary" id={props.name} {...props} />
			) : (
				<Checkbox
					color="primary"
					id={props.name}
					// name={props.name}
					// onChange={props.onChange}
					// onClick={props.onClick}
					// checked={props.checked}
					// value={props.value}
					// ref={props.ref}
					// defaultValue={props.defaultValue}
					{...props}
				/>
			)}
			<label className="cursor-pointer" htmlFor={props.name}>
				{props.label}
			</label>
		</div>
	);
};

export default CheckLabel;

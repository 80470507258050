import React from "react";
import Meta from "../../../pages/components/Meta";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { cdnImg } from "../../../config/etc";
import { useMediaQuery } from "react-responsive";
const btGo = cdnImg + "position_test_go.png";
const back_q = cdnImg + "back_q.png";

export default function SmallForward() {
	const history = useHistory();
	const position = "sf";

	const image = cdnImg + `position_big_${position}.jpg`;
	const imageMo = cdnImg + `position_${position}.jpg`;
	const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
	return (
		<div
			className="flex flex-col items-center justify-center h-screen w-full bg-no-repeat bg-cover px-3"
			style={{
				backgroundImage: `url(${back_q})`,
			}}
		>
			<Meta
				data={{
					title:
						"[프리스타일2 레볼루션 플라잉덩크] 나에게 어울리는 농구 포지션은? : 스몰포워드",
					description:
						"침착하게 한방을 노리는 성격의 당신, 직접적인 공격보다는 빠른 속공 실력이 필요한 스몰포워드가 딱이네요!",
					image,
					width: "1200",
					height: "628",
				}}
			/>
			<img
				src={isMobile ? imageMo : image}
				alt={position}
				className="mb-8"
				width={800}
			/>
			<Button
				onClick={() =>
					history.replace(`/flyingdunk2?positionShare=${position}`)
				}
			>
				<img src={btGo} alt="포지션 테스트 하러 가기" />
			</Button>
		</div>
	);
}

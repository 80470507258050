import React from "react";
import { Field } from "react-final-form";
import CheckLabel from "./CheckLabel";

const CheckAdapter = (props) => {
	return (
		<Field name={props.name} validate={props.validator} type="checkbox">
			{({ input, meta, ...rest }) => {
				return (
					<>
						<CheckLabel
							{...input}
							{...rest}
							value={props.checked}
							checked={props.checked}
							onClick={props.onClick}
							label={props.label}
							checktype={props.type}
						/>
						{meta.error}
					</>
				);
			}}
		</Field>
	);
};

export default CheckAdapter;

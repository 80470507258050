import React, { useEffect } from 'react';

export default function Redirect(props) {
  useEffect(() => {
    const urlData = redirectUrls.find(
      (url) => url.pathname === props.location.pathname
    );
    window.location = urlData.link;
  }, [props.location.pathname]);
  return (
    <div className="flex justify-center items-center w-full h-full mt-24">
      페이지 이동 중 입니다.
    </div>
  );
}

const redirectUrls = [
  {
    pathname: '/cypher',
    link: 'https://sso214.github.io/sesisoft/FreeStyle/pc/index.html',
  },
  {
    pathname: '/naver',
    link: 'https://naver.com',
  },
];

import { Button, withStyles } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { useHistory } from "react-router-dom";
import { aniContainer, aniItem } from "../../config/animation";
import Analytics from "../../service/Analytics";

const MButton = withStyles((theme) => ({
	root: {
		backgroundColor: "#383838",
		color: "white",
		padding: "0 .8rem 0",
		fontSize: "12px",
		"&:hover": { backgroundColor: theme.palette.primary.main },
	},
	label: {
		justifyContent: "space-between",
	},
}))(Button);

export default function MobileGames({ games }) {
	const gameData = games ?? JSON.parse(localStorage.getItem("games"));
	const history = useHistory();
	const goGameHome = (game) => {
		Analytics.logEvent("gameBannerClick", { game: game.title });
		if (game.home_url) {
			window.open(game.home_url);
		} else {
			history.push("/game/" + game.title.replace(/ /g, "-"), game);
		}
	};
	return (
		<motion.div
			variants={aniContainer}
			initial="hidden"
			animate="visible"
			className="mt-3 mb-3"
		>
			<motion.div
				initial={{ transform: "translateX(100px)", opacity: 0 }}
				animate={{ transform: "translateX(0px)", opacity: 1 }}
				transition={{
					type: "spring",
					stiffness: 260,
					damping: 20,
					delay: 0.3,
				}}
				className="mb-2 mont text-sm"
			>
				Mobile Games
			</motion.div>
			{gameData?.map((game, idx) => (
				<motion.div key={game.id} variants={aniItem} className="mb-2">
					<MButton
						fullWidth
						variant="contained"
						onClick={() => goGameHome(game)}
						startIcon={
							<motion.img
								initial={{
									opacity: 0,
									filter: "grayscale(100%)",
									translateX: 100,
								}}
								animate={{
									opacity: 1,
									filter: "grayscale(0%)",
									translateX: 0,
								}}
								transition={{
									type: "ease",
									duration: 0.5,
									delay: idx * 0.3,
								}}
								src={game.banner_img}
								alt={game.title}
								className="h-8"
							/>
						}
					>
						{game.title}
					</MButton>
				</motion.div>
			))}
		</motion.div>
	);
}

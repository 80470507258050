import React from 'react';

import FDBGM, { MyIconButton } from './FDBGM';
import { useMediaQuery } from 'react-responsive';
import { cdnImg } from '../../../config/etc';

const iconCafe = cdnImg + 'icon_cafe.png';
const iconCyou = cdnImg + 'icon_cyou.png';
const iconKakao = cdnImg + 'icon_kakao.png';
// const iconFB = cdnImg + "icon_FB.png";
const iconInsta = cdnImg + 'icon_insta.png';
const moCafe = cdnImg + 'mo/common/top/카페-아이콘.png';
const moCyou = cdnImg + 'mo/common/top/창유-아이콘.png';
const moKakao = cdnImg + 'mo/common/top/카톡채널-아이콘.png';
// const moFB = cdnImg + "mo/common/top/페이스북-아이콘.png";
const moInsta = cdnImg + 'mo/common/top/인스타-아이콘.png';
const iconGame = cdnImg + 'pc/3/pop/네이버게임-아이콘.png';

export default function FDSNS({ type }) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return (
    <>
      {snsData.map((icon, idx) => {
        if (isMobile && icon.name === 'gameCenter') return null;
        return (
          // <Button
          // 	onClick={() => window.open(icon.link)}
          // 	style={{ padding: 0 }}
          // 	size="compact"
          // >
          <MyIconButton
            key={idx}
            variant="contained"
            // onClick={controlAudio}
            style={{ color: 'white' }}
            onClick={() => window.open(icon.link)}
          >
            <img
              src={type === 'pc' ? icon.pc : icon.mo}
              alt={icon.name}
              width={type === 'pc' ? 45 : 27}
            />
          </MyIconButton>
          // </Button>
        );
      })}
      {type !== 'pc' && <FDBGM type="mo" />}
    </>
  );
}

const snsData = [
  {
    name: 'cafe',
    link: 'https://cafe.naver.com/flyingdunk2',
    pc: iconCafe,
    mo: moCafe,
  },
  {
    name: 'insta',
    link: 'https://www.instagram.com/freestyle2_revolution/',
    pc: iconInsta,
    mo: moInsta,
  },
  {
    name: 'kakao',
    link: 'https://pf.kakao.com/_xbksxcs',
    pc: iconKakao,
    mo: moKakao,
  },
  {
    name: 'gameCenter',
    link: 'https://game.naver.com/lounge/Free_Style2_Revolution_Flying_Dunk/home',
    // link: 'https://game.naver.com/lounge/Free_Style2_Revolution_Flying_Dunk/official/359599',
    pc: iconGame,
    mo: moCafe,
  },
  { name: 'cyou', link: 'https://www.cyougame.kr/', pc: iconCyou, mo: moCyou },
  // {
  // 	name: "facebook",
  // 	link: " https://www.facebook.com/FS2M2",
  // 	pc: iconFB,
  // 	mo: moFB,
  // },
];

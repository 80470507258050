import React from "react";
import { Form } from "react-final-form";
import { resetPassword } from "../service/Auth";
import createDecorator from "final-form-focus";
import { motion } from "framer-motion";
import InputAdapter from "./components/InputAdapter";
import {
	composeValidators,
	prevPasswordCheckValidator,
	newPasswordCheckValidator,
	passwordValidator,
	requiredValidator,
} from "../utils/validator";
import { Button } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { aniContainer, aniItem } from "../config/animation";
import GoBack from "./components/GoBack";
import { useLoginContext } from "../contexts/LoginContext";

const focusOnError = createDecorator();

const ResetPassword = (props) => {
	const history = useHistory();
	const { user } = useLoginContext();
	const { enqueueSnackbar } = useSnackbar();
	const resetToken = props.match?.params.resetToken;
	const onSubmit = ({ password, confirmPassword, newPassword }) => {
		return resetPassword(
			{ email: user.email_id, password, confirmPassword, newPassword },
			resetToken
		)
			.then((res) => {
				enqueueSnackbar("비밀번호 설정이 완료되었습니다.", {
					variant: "success",
				});
				history.replace("/login");
			})
			.catch((err) => {
				if (err.response.status === 403) {
					enqueueSnackbar("기존 비밀번호가 맞지 않습니다.", {
						variant: "error",
					});
					return err.response.data.error;
				} else if (err.response.status === 404) {
					enqueueSnackbar("없는 사용자 입니다.", {
						variant: "error",
					});
				} else {
					enqueueSnackbar("비밀번호 설정에 문제가 생겼습니다.", {
						variant: "error",
					});
				}
			});
	};
	return (
		<div className="relative mxrounded-xl p-4 sm:mt-4 w-full sm:max-w-lg mx-auto">
			<Form
				onSubmit={onSubmit}
				decorators={[focusOnError]}
				render={({ handleSubmit, submitting, pristine, errors }) => (
					<motion.form
						variants={aniContainer}
						initial="hidden"
						animate="visible"
						onSubmit={handleSubmit}
						className="flex flex-col"
					>
						<motion.div variants={aniItem} className="sm:hidden">
							<GoBack isMember={true} setTab={props.setTab} />
						</motion.div>
						<motion.h1
							variants={aniItem}
							className="font-bold text-xl text-purple-600"
						>
							비밀번호 재설정
						</motion.h1>
						{!user.email_id && !resetToken ? (
							<>
								<motion.h3 variants={aniItem} className="text-sm my-5">
									간편 가입으로 가입한 경우엔 비밀번호 변경을 하실 수 없습니다.{" "}
									<br />각 SNS 서비스에서 변경해 주세요.
								</motion.h3>
								<motion.div
									variants={aniItem}
									className="text-xxs flex space-x-2"
								>
									<Button
										onClick={() =>
											window.open(
												"https://accounts.kakao.com/weblogin/find_password?continue=https%3A%2F%2Flogins.daum.net%2Faccounts%2Fksso.do%3Frescue%3Dtrue%26url%3Dhttps%253A%252F%252Fwww.daum.net%252F"
											)
										}
										variant="outlined"
										className="w-1/4 "
									>
										<span className="text-xs">카카오톡</span>
									</Button>
									<Button
										onClick={() =>
											window.open(
												"https://nid.naver.com/user2/help/pwInquiry.nhn?menu=pwinquiry"
											)
										}
										variant="outlined"
										className="w-1/4"
									>
										<span className="text-xs">네이버</span>
									</Button>
									<Button
										onClick={() =>
											window.open(
												"https://www.facebook.com/login/identify/?ctx=recover&ars=facebook_login&from_login_screen=0"
											)
										}
										variant="outlined"
										className="w-1/4"
									>
										<span className="text-xs">페이스북</span>
									</Button>
									<Button
										onClick={() =>
											window.open(
												"https://accounts.google.com/ServiceLogin/signinchooser?hl=ko&flowName=GlifWebSignIn&flowEntry=ServiceLogin"
											)
										}
										variant="outlined"
										className="w-1/4"
									>
										<span className="text-xs">구글</span>
									</Button>
								</motion.div>
							</>
						) : (
							<>
								<motion.h3 variants={aniItem} className="text-sm my-5">
									{!resetToken && "기존 비밀번호와 "} 새롭게 사용할 비밀번호를
									입력해 주세요.
								</motion.h3>
								<div className=" space-y-2">
									{!resetToken && (
										<motion.div variants={aniItem}>
											<InputAdapter
												validator={composeValidators(
													requiredValidator,
													passwordValidator
												)}
												name="password"
												type="password"
												title="기존 비밀번호"
												onChange={() => null}
											/>
										</motion.div>
									)}
									<motion.div variants={aniItem}>
										<InputAdapter
											validator={composeValidators(
												requiredValidator,
												passwordValidator,
												prevPasswordCheckValidator
											)}
											name="newPassword"
											type="password"
											title="새 비밀번호"
										/>
									</motion.div>
									<motion.div variants={aniItem}>
										<InputAdapter
											validator={composeValidators(
												requiredValidator,
												passwordValidator,
												newPasswordCheckValidator
											)}
											name="confirmPassword"
											type="password"
											title="새 비밀번호 확인"
										/>
									</motion.div>
									<motion.div variants={aniItem}>
										<Button
											type="submit"
											color="primary"
											variant="contained"
											disabled={
												submitting ||
												Object.keys(errors).length !== 0 ||
												pristine ||
												errors.length > 0
											}
											fullWidth={true}
										>
											비밀번호 재설정
										</Button>
									</motion.div>
									<motion.div variants={aniItem} className="text-xxs">
										* 비밀번호는 8자 이상 영문,숫자,특문을 조합해주세요.
									</motion.div>
								</div>
							</>
						)}
					</motion.form>
				)}
			/>
		</div>
	);
};

export default ResetPassword;

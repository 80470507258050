const firebaseConfig = {
	apiKey: "AIzaSyConimzbRP3-WqiJnz3RUdPZ7YUEgKGVUw",
	authDomain: "cyouportal-809be.firebaseapp.com",
	databaseURL:
		"https://cyouportal-809be-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "cyouportal-809be",
	storageBucket: "cyouportal-809be.appspot.com",
	messagingSenderId: "325325591704",
	appId: "1:325325591704:web:9648dcad5a4f9dbf702ced",
	measurementId: "G-0W6SXFFMHF",
};
export default firebaseConfig;

export const gaTraceId = "AW-323901971";
export const kakaoPixelId = "1359328802311589542";

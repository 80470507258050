/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import PositionTestQuestion from './PositionTestQuestion';
import PositionTestResult, { resultData } from './PositionTestResult';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import { useLoginContext } from '../../../contexts/LoginContext';
import ShareButtons from '../components/ShareButtons';
import { useMediaQuery } from 'react-responsive';
import { useSnackbar } from 'notistack';
// import { useHistory } from "react-router";
import { setPreviewEvent } from '../../../service/Events';
import Analytics from '../../../service/Analytics';
import { useEventContext } from '../../../contexts/EventContext';
import { cdnImg } from '../../../config/etc';
import { BtScroll } from '../FDMain';
import { LoginPop, RewardPop } from './FDMenu';
import { usePopContext } from '../../../contexts/PopContext';

const confirm = cdnImg + 'url_confirm.png';
const urlBg = cdnImg + 'url_bg.png';
const btGo = cdnImg + 'position_test_go.png';
const title = cdnImg + 'position_test_title.png';
const title2 = cdnImg + 'position_test_title2.png';
const bt_close = cdnImg + 'bt_close.png';
const bgTest = cdnImg + 'bg_test.jpg';
// const bgLogin = cdnImg + "pc/3/pop/창유로그인-on-box.png";
const bgComplete = cdnImg + 'pc/3/pop/창유로그인-Yes-box.png';
const bgCompleteMo = cdnImg + 'mo/3/pop/창유로그인-Yes-box.png';
const textComplete = cdnImg + 'pc/3/pop/창유로그인-Yes-텍스트.png';
const btConfirm = cdnImg + 'pc/3/pop/창유로그인-Yes-확인버튼.png';
// const btCancel = cdnImg + "pc/3/pop/창유로그인-no-취소버튼.png";
// const btLogin = cdnImg + "pc/3/pop/창유로그인-no-로그인하기버튼.png";
const rewardDia = cdnImg + 'pc/3/다이아.png';
const rewardGold = cdnImg + 'pc/3/골드.png';
const rewardSkill = cdnImg + 'pc/3/스킬훈련서.png';
const rewardPlayCard = cdnImg + 'pc/3/플카.png';
const reward30 = cdnImg + 'pc/3/경품-30명추첨.png';
const rewardAll = cdnImg + 'pc/3/경품-전원지급.png';
const titleMo = cdnImg + 'mo/3/타이틀.png';
const bgReward1 = cdnImg + 'bg_reward_test1.jpg';
const bgReward2 = cdnImg + 'bg_reward_test2.jpg';
const btGift = cdnImg + 'bt_gift.png';
const bgText = cdnImg + 'text_back.png';

export default function PositionTest(props) {
  const [status, setStatus] = useState('main');
  const [isLogin, setIsLogin] = useState(false);
  const [url, setUrl] = useState('');
  // const history = useHistory();
  const [isComplete, setIsComplete] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [result, setResult] = useState(cookies.position ?? '');

  const { user } = useLoginContext();
  const { event, setEvent } = useEventContext();

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isBig = useMediaQuery({ query: '(min-height: 1300px)' });
  const { enqueueSnackbar } = useSnackbar();
  const { setPopup } = usePopContext();
  const [isReward, setIsReward] = useState();
  const [loginText, setLoginText] = useState();

  const onResult = (totalWeight) => {
    console.log('totalWeight: ', totalWeight);
    let position;
    let prevWeight = -1;
    _.forEach(totalWeight, (weight, id) => {
      if (
        weight > prevWeight ||
        (weight === prevWeight && Math.random() > 0.5)
      ) {
        position = id;
        prevWeight = weight;
      }
    });

    setResult(position);
    setCookie('position', position);
    setStatus('result');
  };
  const alert = (text, variant) => {
    if (isMobile) {
      enqueueSnackbar(text, { variant });
    } else {
      setPopup({
        text,
      });
    }
  };
  const onReward = () => {
    if (user.id) {
      setIsReward(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setLoginText('보상함 확인을 위해\n먼저 로그인해 주세요');
      setIsLogin(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const onConfirm = () => {
    // if (cookies.registered !== "true") {
    // 	alert("먼저 사전 예약을 완료해 주세요.", "error");
    // 	return;
    // }
    if (!result) {
      alert('공유하기 전에 먼저 포지션 테스트에 참여해 주세요.', 'error');
      return;
    }
    console.log(result);
    if (!user.id) {
      console.log('먼저 로그인 해 주세요.');
      setIsLogin(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    if (!url) {
      alert('공유한 곳의 URL을 입력해 주세요.', 'error');
      return;
    }

    const expUrl =
      /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;

    if (!expUrl.test(url)) {
      alert('올바른 형식의 URL을 입력해 주세요.', 'error');
      return;
    }
    console.log('정상', expUrl.test(url));
    if (user.id) {
      const data = {
        shareUrl: url,
        testResult: result,
        uid: user.id,
        nickname: user.nickname,
      };
      if (cookies.refer) data.refer = cookies.refer;
      if (cookies.utm_source) data.utm_source = cookies.utm_source;
      if (cookies.utm_medium) data.utm_medium = cookies.utm_medium;
      if (cookies.share) data.shared = cookies.share;
      setPreviewEvent(data).then((res) => {
        setEvent(res.data);
      });
      setIsComplete(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      Analytics.logEvent('positionTest', {
        userId: user.id,
        position: result,
      });
    }
  };

  return (
    <div
      // className="p-4 h-full max-w-2xl m-auto flex flex-col items-center justify-center"
      style={{
        backgroundColor: '#000',
        backgroundImage: `url(${bgTest})`,
        borderColor: '#f4aa2f',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        height: isBig ? window.innerHeight : '100%',
      }}
    >
      <div
        className="h-full m-auto flex flex-col items-center justify-start text-white pb-4"
        style={{
          height: isMobile && status !== 'main' ? 'calc(100vh - 55px)' : '100%',
          // paddingLeft: isMobile && status !== "main" ? 0 : 15,
          // paddingRight: isMobile && status !== "main" ? 0 : 15,
        }}
      >
        {event.dateState < 3 && !(isMobile && status !== 'main') && (
          <>
            <div className=" flex flex-col items-center mt-20 ">
              <img
                src={isMobile ? titleMo : event.dateState < 1 ? title : title2}
                alt="포지션 Test 공유 이벤트"
                width={1080}
              />

              {event.dateState < 1 && (
                <div className="-mt-20 sm:-mt-16 flex flex-wrap justify-center">
                  <div
                    className="relative border-2 border-yellow-500 flex items-center justify-center px-0 sm:px-2 pt-5 pb-3 bg-cover text-xs mb-6 sm:mb-0"
                    style={{ backgroundImage: `url(${bgReward1})` }}
                  >
                    <div className="absolute -top-4 w-full flex justify-center">
                      <img src={rewardAll} alt="전원지급" width={110} />
                    </div>
                    <div
                      className="flex flex-col items-center mx-1"
                      style={{ minWidth: 100 }}
                    >
                      <img src={rewardDia} alt="300다이아" width={90} />
                      <p className="pt-2">300다이아</p>
                    </div>
                    <div
                      className="flex flex-col items-center mx-1"
                      style={{ minWidth: 100 }}
                    >
                      <img src={rewardGold} alt="40,000골드" width={77} />
                      <p className="pt-2">40,000골드</p>
                    </div>
                    <div
                      className="flex flex-col items-center mx-1"
                      style={{ minWidth: 100 }}
                    >
                      <img src={rewardSkill} alt="스킬훈련서20" width={102} />
                      <p className="pt-2">스킬훈련서20</p>
                    </div>
                  </div>
                  <div
                    className="relative border-2 border-yellow-500 flex items-center justify-center sm:px-2 pt-5 pb-3 bg-cover text-xs"
                    style={{ backgroundImage: `url(${bgReward2})` }}
                  >
                    <div className="absolute -top-4 w-full flex justify-center">
                      <img src={reward30} alt="전원지급" width={110} />
                    </div>
                    <div
                      className="flex flex-col items-center mx-1"
                      style={{ minWidth: 100 }}
                    >
                      <img src={rewardDia} alt="1,500다이아" width={90} />
                      <p className="pt-2">1,500다이아</p>
                    </div>
                    <div
                      className="flex flex-col items-center mx-1"
                      style={{ minWidth: 100 }}
                    >
                      <img src={rewardGold} alt="50,000골드" width={77} />
                      <p className="pt-2">50,000골드</p>
                    </div>
                    <div
                      className="flex flex-col items-center mx-1"
                      style={{ minWidth: 100 }}
                    >
                      <img src={rewardPlayCard} alt="500 플카" width={65} />
                      <p className="pt-2">500 플카</p>
                    </div>
                  </div>
                </div>
              )}
              {event.dateState < 1 && (
                <div
                  className="mt-4 flex flex-col items-center text-center p-8 text-sm"
                  style={{
                    background:
                      'linear-gradient(to right, #00000000, 30%, #000000ff 70%,#00000000)',
                  }}
                >
                  <p className="text-xs sm:text-sm">
                    1.<span style={{ color: '#f4aa2f' }}>포지션 테스트</span>를
                    한다.
                    <br />▼<br /> 2.테스트 결과를{' '}
                    <span style={{ color: '#f4aa2f' }}>
                      SNS 공유버튼을 눌러 '전체 공개'로 포스팅
                    </span>
                    한다.
                    <br />▼<br /> 3.포스팅한 곳의{' '}
                    <span style={{ color: '#f4aa2f' }}>
                      URL을 입력 후 확인 버튼
                    </span>
                    을 누른다.
                  </p>
                  {/* {result && ( */}
                  <div className=" mt-6">
                    {status === 'main' && (
                      <ShareButtons
                        id="kakaoButtonMain"
                        message={
                          '[프리스타일2 레볼루션 플라잉덩크] 나에게 어울리는 농구 포지션은? - ' +
                          resultData[result]?.name
                        }
                        media={`${cdnImg}position_${
                          result !== 'c' ? result : 'center'
                        }.jpg`}
                        pathname={resultData[result]?.url}
                        result={result}
                      />
                    )}
                  </div>
                  {/* } */}
                </div>
              )}
              {event.dateState > 0 && (
                <div
                  className="eventCoverBox -mt-24 sm:-mt-28 flex flex-col  justify-between text-center "
                  style={{
                    background: `linear-gradient(#000c 0%,#000a 100%), center / 1000px no-repeat url(${bgTest})`,
                  }}
                >
                  <div className="p-4 sm:p-10 ">
                    <h1>
                      <b>포지션테스트 이벤트 종료!</b>
                    </h1>
                    <h1>감사합니다!</h1>
                    <h2
                      className="stroke inline-block m-5 p-8 self-auto border-4 border-white text-yellow-300"
                      style={{
                        background: `center / cover no-repeat url(${bgText})`,
                      }}
                    >
                      2021.9.16~사전예약 종료
                    </h2>
                    <h2 className="flex items-center justify-center">
                      왼쪽 상단의
                      <Button onClick={onReward} style={{ padding: 0 }}>
                        <h2>
                          <b> &nbsp;보상함 아이콘</b>
                        </h2>
                        <img
                          src={btGift}
                          alt="보상함"
                          width={isMobile ? 30 : 50}
                        />
                      </Button>
                      을 눌러
                    </h2>
                    <h2>로그인 후, 보상을 확인해주세요.</h2>
                  </div>
                  <span className="bottomBox px-10 py-3 sm:text-xl">
                    포지션 테스트는 계속 참여 가능합니다.
                  </span>
                </div>
              )}
              <Button
                onClick={() => {
                  // if (cookies.registered !== "true") {
                  // 	alert("먼저 사전 예약을 완료해 주세요.", "error");
                  // 	return;
                  // }
                  setStatus('question');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <img src={btGo} alt="포지션테스트 GO!" width={360} />
              </Button>

              {/* <p className="text-xs pt-2">
								* 본 이벤트는 사전예약을 완료해야 참여하실 수 있습니다.
							</p> */}

              {event.dateState < 1 && (
                <div
                  className="flex items-center my-8 mt-4 mb-12 sm:mb-4"
                  style={{
                    width: isMobile ? 320 : 550,
                    height: isMobile ? 70 : 100,
                    paddingLeft: isMobile ? 0 : 50,
                    background: `url(${urlBg})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top',
                    flexDirection: isMobile ? 'column' : 'row',
                  }}
                >
                  <input
                    style={{
                      border: '5px solid black',
                      padding: isMobile ? 4 : 7,
                      marginTop: isMobile ? 12 : 0,
                      marginBottom: isMobile ? 12 : 0,

                      width: isMobile ? 230 : 300,
                      fontSize: isMobile ? 13 : 16,
                      color: '#000',
                    }}
                    placeholder="공유한 곳의 URL을 입력해 주세요."
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />

                  <Button onClick={onConfirm}>
                    <img src={confirm} alt="확인" width={107} />
                  </Button>
                </div>
              )}
              <BtScroll setActivePage={props.setActivePage} />
              <div className="pt-12 h-4 w-4"></div>
            </div>
          </>
        )}

        {((event.dateState > 2 && status === 'main') ||
          status === 'question') && (
          <PositionTestQuestion
            onResult={onResult}
            onClose={() => setStatus('main')}
          />
        )}

        {status === 'result' && (
          <PositionTestResult
            result={result}
            onRestart={() => setStatus('question')}
            onClose={() => setStatus('main')}
            onShare={() => {
              setStatus('main');
              setTimeout(() => {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth',
                });
              }, 50);
            }}
          />
        )}
        {isLogin && (
          <LoginPop setIsLogin={setIsLogin}>
            {
              '크루님의 플덩2 보상함에\n아이템을 발송하기 위해\n창유 로그인이 필요합니다.'
            }
          </LoginPop>
        )}
        {isComplete && (
          <div
            className="absolute z-50 flex h-full w-full"
            style={{ backgroundColor: '#000000dd' }}
          >
            <div
              className="m-auto w-192 text-white font-bold p-2 flex flex-col items-center bg-cover sm:pl-40 pb-6"
              style={{
                backgroundImage: `url(${isMobile ? bgCompleteMo : bgComplete})`,
                backgroundPosition: 'center',
                marginTop: isMobile ? 0 : 'auto',
                border: isMobile ? '0' : '4px solid #f4aa2f',
                width: isMobile ? '100%' : 410,
                height: isMobile ? window.innerHeight : 'auto',
              }}
            >
              <div className="flex justify-end items-start w-full">
                <Button onClick={() => setIsComplete(false)} className="">
                  <img
                    src={bt_close}
                    alt="close"
                    width={33}
                    className="-mr-4"
                  />
                </Button>
              </div>
              <img
                className="mr-6 mb-2 mt-6"
                src={textComplete}
                alt="공유가 완료되었습니다."
                width={250}
              />
              <pre className="text-center text-xl font-normal mb-4">
                {'보상함에서 관련 내용\n  확인이 가능합니다.'}
              </pre>
              <p className="text-xs text-center font-normal ">
                * 보상은 정식오픈 후 별도로 지급됩니다.
              </p>
              <Button>
                <img
                  src={btConfirm}
                  alt="확인"
                  width={200}
                  onClick={() => setIsComplete(false)}
                />
              </Button>
            </div>
          </div>
        )}
        {isLogin && <LoginPop setIsLogin={setIsLogin}>{loginText}</LoginPop>}
        {isReward && <RewardPop setIsReward={setIsReward} />}
      </div>
    </div>
  );
}

import { Divider, Link } from "@material-ui/core";
import React, { useRef } from "react";
import _ from "../../utils/@lodash";
import play_store from "../../assets/play_store.png";
import one_store from "../../assets/one_store.png";
import app_store from "../../assets/app_store.png";
import go_arrow from "../../assets/go_arrow.png";
import { useHistory } from "react-router-dom";
import NoticeList from "./NoticeList";
import { motion } from "framer-motion";
import Analytics from "../../service/Analytics";

var isMobile;

const ZCard = ({ game }) => {
	const history = useHistory();
	const card = useRef();
	const cardImg = useRef();
	const logo = useRef();
	const title = useRef();
	const body = useRef();
	const sns = useRef();

	const config = {
		id: "card" + game.id,
		rotation: 45,
		throttle: 100,
		transition: {
			duration: 0.5,
			timingMode: "ease",
		},
		transform: {
			cardTranslateZ: 10,
			cardImgTranslateZ: 30,
			// logoTranslateZ: 20,
			// titleTranslateZ: 0,
			// bodyTextTranslateZ: 0,
			buttonTranslateZ: 35,
		},
	};

	// const isHover = (e) => e.parentElement.querySelector(":hover") === e;

	var thisLeft = card.current?.getBoundingClientRect().left;
	var thisTop = card.current?.getBoundingClientRect().top;
	const onMouseMove = _.throttle(function (e) {
		isMobile = window.innerWidth < 640;
		if (card.current && !isMobile) {
			var xAxis =
				(card.current?.offsetWidth / 2 - (e.clientX - thisLeft)) /
				(config?.rotation || 15);
			var yAxis =
				(card.current?.offsetHeight / 2 - (e.clientY - thisTop)) /
				(config?.rotation || 15);
			var tr = config.transform?.cardTranslateZ;
			// if (!isHover(sns.current)) {
			card.current.style.transform = `rotateX(${yAxis}deg) rotateY(${-xAxis}deg) translateZ(${
				tr || 0
			}px)`;
		}
	}, config.throttle || 150);

	const onMouseEnter = function () {
		if (card.current && !isMobile) {
			thisLeft = card.current.getBoundingClientRect().left;
			thisTop = card.current.getBoundingClientRect().top;
			// title.current.style.transition = `all 0.5s ease`;
			let tr = config?.transform?.titleTranslateZ;
			if (tr) title.current.style.transform = `translateZ(${tr}px)`;
			tr = config?.transform?.bodyTextTranslateZ;
			if (tr) body.current.style.transform = `translateZ(${tr}px)`;
			tr = config?.transform?.buttonTranslateZ;
			if (tr) sns.current.style.transform = `translateZ(${tr}px)`;
			tr = config?.transform?.logoTranslateZ;
			if (tr) logo.current.style.transform = `translateZ(${tr}px)`;
			tr = config?.transform?.cardImgTranslateZ;
			if (tr)
				cardImg.current.style.transform = `translateY(${7}px) translateZ(${tr}px)`;
		}
	};

	const onMouseLeave = _.debounce(function () {
		if (card.current) {
			card.current.style.transform =
				"rotateY(0deg) rotateX(0deg) translateZ(0px)";
			title.current.style.transform = "translateZ(0px)";
			body.current.style.transform = "translateZ(0px)";
			sns.current.style.transform = "translateZ(0px)";
			logo.current.style.transform = "translateZ(0px)";
			cardImg.current.style.transform = "translateZ(0) rotateZ(0deg)";
		}
	}, config.throttle || 150);

	const goGameHome = () => {
		Analytics.logEvent("gameClick", { game: game.title });
		if (game.home_url) {
			window.open(game.home_url);
		} else {
			history.push("/game/" + game.title.replace(/ /g, "-"), game);
		}
	};
	const clickStore = (store) => {
		Analytics.logEvent("clickStore", { game: game.title, store });
	};
	const clickCafe = () => {
		Analytics.logEvent("clickCafe", { game: game.title });
	};
	const snsGo = { scale: 1.2, rotate: [0, -10, 0] };
	return (
		<div>
			<div
				ref={card}
				onMouseMove={onMouseMove}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className="card ani_card transition-500 sm:border-gray-300 sm:border sm:shadow-md sm:hover:shadow-2xl rounded mb-4 sm:mb-0"
			>
				<div
					className="back hidden sm:block "
					style={{ background: `url(${game.card_img})` }}
				>
					<motion.img
						ref={cardImg}
						src={game.card_char}
						alt={game.title}
						initial={{ transform: "scale(0.8)", opacity: 0 }}
						animate={{ transform: "scale(1)", opacity: 1 }}
						transition={{
							delay: 0.7,
						}}
						className="card_img transition-500 cursor-pointer rounded-t w-full sm:max-h-56 object-cover"
						onClick={goGameHome}
						style={{ mask: "url(.back) luminance" }}
					/>
				</div>
				<div className="content sm:p-3 flex flex-col justify-center items-center">
					<div
						className="flex items-start sm:items-center self-start cursor-pointer w-full truncate"
						onClick={goGameHome}
					>
						<img
							ref={logo}
							className="logo w-24 h-24 sm:w-14 sm:h-14 transition-500 rounded"
							src={game.icon_img}
							alt={game.title}
						/>
						<div className="pl-3">
							<h2
								ref={title}
								className="title transition-500 font-bold text-lg sm:text-md lg:text-lg -mt-1 mb-2 sm:m-0 "
							>
								{game.title}
							</h2>
							<div
								ref={body}
								className={"body transition-500 text-sm sm:text-xs xl:text-sm "}
							>
								{game.slogan.split("\n").map((line, idx) => (
									<p key={idx} className="">
										{line}
									</p>
								))}
							</div>
						</div>
					</div>
					<Divider
						className="hidden sm:block w-3/6 my-4"
						style={{ margin: "1rem" }}
					/>
					<div
						ref={sns}
						className=" transition-500 flex items-center justify-between sm:justify-around w-full sm:w-3/4 mt-2 mb-1 sm:my-0 space-x-1"
					>
						<Link
							variant="inherit"
							color="inherit"
							href={"https://cafe.naver.com/" + game.cafe_name}
							onClick={() => clickCafe()}
							target="_blank"
							rel="noreferrer"
							className="flex-1 sm:hidden sm:w-full h-11 transition-500 border-gray-300 border hover:bg-green-600 hover:text-white items-center flex justify-center"
							style={{ textDecoration: "none" }}
						>
							<span>공식카페 바로가기</span>
						</Link>
						{game.play_store_url && (
							<motion.div
								whileHover={snsGo}
								className="p-2 border-gray-300 border sm:p-0 sm:border-0 w-11 h-11"
							>
								<Link
									href={game.play_store_url}
									onClick={() => clickStore("playStore")}
									target="_blank"
									className="w-11"
								>
									<img alt="play_store" src={play_store} />
								</Link>
							</motion.div>
						)}
						{game.app_store_url && (
							<motion.div
								whileHover={snsGo}
								className="p-2 border-gray-300 border sm:p-0 sm:border-0 w-11 h-11"
							>
								<Link
									href={game.app_store_url}
									onClick={() => clickStore("appStore")}
									target="_blank"
									className="w-11 "
								>
									<img src={app_store} alt="app_store " />
								</Link>
							</motion.div>
						)}
						{game.one_store_url && (
							<motion.div
								whileHover={snsGo}
								className="p-2 border-gray-300 border sm:p-0 sm:border-0 w-11 h-11"
							>
								<Link
									href={game.one_store_url}
									onClick={() => clickStore("oneStore")}
									target="_blank"
								>
									<img src={one_store} alt="one_store " />
								</Link>
							</motion.div>
						)}
					</div>
					<Divider
						className="hidden sm:block w-3/6 my-4"
						style={{ margin: "1rem" }}
					/>

					{game.list && <NoticeList game={game} />}
				</div>
			</div>
			<div className="hidden sm:flex flex-wrap mt-4 text-sm">
				<Link
					variant="inherit"
					color="inherit"
					onClick={goGameHome}
					className="flex justify-between w-full py-2 px-4 mt-4 transition-500 border-gray-300 border shadow-md hover:shadow-2xl rounded cursor-pointer hover:bg-purple-600 hover:text-white go_link"
					style={{ marginBottom: "0.5rem", textDecoration: "none" }}
				>
					<span>홈페이지 바로가기</span>
					<img
						src={go_arrow}
						alt="go_arrow"
						className="w-14 object-contain go_arrow transition-500"
					/>
				</Link>
				<Link
					variant="inherit"
					color="inherit"
					href={"https://cafe.naver.com/" + game.cafe_name}
					target="_blank"
					rel="noreferrer"
					className="flex justify-between sm:w-full py-2 px-4 mt-4 transition-500 border-gray-300 border shadow-md hover:shadow-2xl rounded cursor-pointer hover:bg-green-600 hover:text-white go_link"
					style={{ marginBottom: "0.5rem", textDecoration: "none" }}
				>
					<span>공식카페 바로가기</span>
					<img
						src={go_arrow}
						alt="go_arrow"
						className=" w-14 object-contain go_arrow transition-500"
					/>
				</Link>
			</div>
		</div>
	);
};

export default ZCard;

import axios from "axios";
import { apiServer } from "../config/servers";

export const createData = (path, data) =>
	axios.post(apiServer + "/v1/" + path, data);

export const readDatas = (path, data) =>
	axios.get(apiServer + "/v1/" + path, { params: data || {} });

export const readData = (path, id) =>
	axios.get(apiServer + "/v1/" + path + "/" + id);

export const updateData = (path, id, data) =>
	axios.put(apiServer + "/v1/" + path + "/" + id, data);

export const deleteData = (path, id) =>
	axios.put(apiServer + "/v1/" + path + "/" + id);

export const uploadFile = (formData) =>
	axios.post(apiServer + "/v1/upload", formData);

import React from "react";
import SnsButton from "./SnsButton";
import { SNS } from "../../config/snsKeys";
import { motion } from "framer-motion";

const SNSBox = ({ isLogin = true }) => {
	const container = {
		visible: {
			transition: {
				delayChildren: 0.2,
				staggerChildren: 0.2,
			},
		},
	};
	const item = {
		hidden: { x: 40, opacity: 0 },
		visible: {
			x: 0,
			opacity: 1,
		},
	};
	return (
		<motion.div
			variants={container}
			initial="hidden"
			animate="visible"
			className="snsBox py-4 w-full space-y-2"
		>
			<motion.div variants={item} whileHover={{ scale: 1.03 }}>
				<SnsButton path={SNS.KAKAO} isLogin={isLogin} />
			</motion.div>
			<motion.div variants={item} whileHover={{ scale: 1.03 }}>
				<SnsButton path={SNS.NAVER} isLogin={isLogin} />
			</motion.div>
			<motion.div variants={item} whileHover={{ scale: 1.03 }}>
				<SnsButton path={SNS.FACEBOOK} isLogin={isLogin} />
			</motion.div>
			<motion.div variants={item} whileHover={{ scale: 1.03 }}>
				<SnsButton path={SNS.GOOGLE} isLogin={isLogin} />
			</motion.div>
		</motion.div>
	);
};

export default SNSBox;

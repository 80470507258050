import React from "react";
import { useHistory } from "react-router-dom";

const GoBack = ({ isMember, setTab }) => {
	const history = useHistory();
	const goBack = () => {
		if (isMember) {
			sessionStorage.setItem("memberTab", 5);
			setTab(5);
		} else {
			history.goBack();
		}
	};
	return (
		<div onClick={goBack} className="font-bold mb-2 cursor-pointer">
			← 뒤로
		</div>
	);
};

export default GoBack;
